@charset "UTF-8";
/* Cognireset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  /* COG: this is known to cause problems in CQ4 and SharePoint */ }

/* remember to define focus styles! */
:focus {
  outline: 0; }

body {
  line-height: 1;
  color: black;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  position: relative;
  overflow: auto; }

ol, ul {
  list-style: none; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ""; }

blockquote, q {
  quotes: "" ""; }

/* COG: make sup and sup working again */
sub {
  vertical-align: sub;
  font-size: 0.9em; }

sup {
  vertical-align: super;
  font-size: 0.9em; }

.scEnabledChrome {
  opacity: 1 !important; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNY.eot) format("eot"), url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format("truetype"), url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff) format("woff"), url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2"); }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.lg-outer.fb-comments .lg-img-wrap, .lg-outer.fb-comments .lg-video-cont {
  padding-right: 400px !important; }

.lg-outer.fb-comments .fb-comments {
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 420px;
  z-index: 99999;
  background: #fff url("../img/loading.gif") no-repeat scroll center center; }
  .lg-outer.fb-comments .fb-comments.fb_iframe_widget {
    background-image: none; }
    .lg-outer.fb-comments .fb-comments.fb_iframe_widget.fb_iframe_widget_loader {
      background: #fff url("../img/loading.gif") no-repeat scroll center center; }

.lg-outer.fb-comments .lg-toolbar {
  right: 420px;
  width: auto; }

.lg-outer.fb-comments .lg-actions .lg-next {
  right: 420px; }

.lg-outer.fb-comments .lg-item {
  background-image: none; }
  .lg-outer.fb-comments .lg-item.lg-complete .lg-img-wrap, .lg-outer.fb-comments .lg-item.lg-complete .lg-video-cont {
    background-image: none; }

.lg-outer.fb-comments .lg-img-wrap, .lg-outer.fb-comments .lg-video-cont {
  background: url("../img/loading.gif") no-repeat scroll transparent;
  background-position: center center;
  background-position: calc((100% - 400px) / 2) center; }

.lg-outer.fb-comments .lg-sub-html {
  padding: 0;
  position: static; }

.lg-outer.fb-comments .lg-video-cont {
  max-width: 1250px !important; }

.lg-css3.lg-zoom-in .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  .lg-css3.lg-zoom-in .lg-item.lg-next-slide {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3); }
  .lg-css3.lg-zoom-in .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-in .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-big .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-in-big .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
    transform: scale3d(0.7, 0.7, 0.7); }
  .lg-css3.lg-zoom-out .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
    transform: scale3d(0.7, 0.7, 0.7); }
  .lg-css3.lg-zoom-out .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-out .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-big .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-out-big .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-in .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-out-in .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-out .lg-item {
  opacity: 0; }
  .lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2); }
  .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0); }
  .lg-css3.lg-zoom-in-out .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-soft-zoom .lg-item {
  opacity: 0; }
  .lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  .lg-css3.lg-soft-zoom .lg-item.lg-next-slide {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  .lg-css3.lg-soft-zoom .lg-item.lg-current {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1; }
  .lg-css3.lg-soft-zoom .lg-item.lg-prev-slide, .lg-css3.lg-soft-zoom .lg-item.lg-next-slide, .lg-css3.lg-soft-zoom .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-scale-up .lg-item {
  opacity: 0; }
  .lg-css3.lg-scale-up .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0); }
  .lg-css3.lg-scale-up .lg-item.lg-next-slide {
    -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0);
    transform: scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0); }
  .lg-css3.lg-scale-up .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-scale-up .lg-item.lg-prev-slide, .lg-css3.lg-scale-up .lg-item.lg-next-slide, .lg-css3.lg-scale-up .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide-circular .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }
  .lg-css3.lg-slide-circular .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular .lg-item.lg-next-slide, .lg-css3.lg-slide-circular .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-up .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }
  .lg-css3.lg-slide-circular-up .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }
  .lg-css3.lg-slide-circular-up .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-down .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }
  .lg-css3.lg-slide-circular-down .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }
  .lg-css3.lg-slide-circular-down .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(0, -100%, 0); }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(0, 100%, 0); }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-left .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-down .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide {
    -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
    transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-vertical .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  .lg-css3.lg-slide-vertical .lg-item.lg-next-slide {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  .lg-css3.lg-slide-vertical .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-vertical .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-vertical-growth .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
    -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0); }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide {
    -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
    transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0); }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
    -moz-transform: skew(10deg, 0deg);
    -o-transform: skew(10deg, 0deg);
    -ms-transform: skew(10deg, 0deg);
    -webkit-transform: skew(10deg, 0deg);
    transform: skew(10deg, 0deg); }
  .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide {
    -moz-transform: skew(10deg, 0deg);
    -o-transform: skew(10deg, 0deg);
    -ms-transform: skew(10deg, 0deg);
    -webkit-transform: skew(10deg, 0deg);
    transform: skew(10deg, 0deg); }
  .lg-css3.lg-slide-skew-only .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(-10deg, 0deg);
    -o-transform: skew(-10deg, 0deg);
    -ms-transform: skew(-10deg, 0deg);
    -webkit-transform: skew(-10deg, 0deg);
    transform: skew(-10deg, 0deg); }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide {
    -moz-transform: skew(-10deg, 0deg);
    -o-transform: skew(-10deg, 0deg);
    -ms-transform: skew(-10deg, 0deg);
    -webkit-transform: skew(-10deg, 0deg);
    transform: skew(-10deg, 0deg); }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, 10deg);
    -o-transform: skew(0deg, 10deg);
    -ms-transform: skew(0deg, 10deg);
    -webkit-transform: skew(0deg, 10deg);
    transform: skew(0deg, 10deg); }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, 10deg);
    -o-transform: skew(0deg, 10deg);
    -ms-transform: skew(0deg, 10deg);
    -webkit-transform: skew(0deg, 10deg);
    transform: skew(0deg, 10deg); }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, -10deg);
    -o-transform: skew(0deg, -10deg);
    -ms-transform: skew(0deg, -10deg);
    -webkit-transform: skew(0deg, -10deg);
    transform: skew(0deg, -10deg); }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, -10deg);
    -o-transform: skew(0deg, -10deg);
    -ms-transform: skew(0deg, -10deg);
    -webkit-transform: skew(0deg, -10deg);
    transform: skew(0deg, -10deg); }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg);
    -o-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
    opacity: 1; }
  .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
    -moz-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px);
    transform: skew(20deg, 0deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew .lg-item.lg-next-slide {
    -moz-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px);
    transform: skew(20deg, 0deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew .lg-item.lg-next-slide, .lg-css3.lg-slide-skew .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px);
    transform: skew(-20deg, 0deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide {
    -moz-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px);
    transform: skew(-20deg, 0deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px);
    transform: skew(0deg, 60deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px);
    transform: skew(0deg, 60deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    -o-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    -ms-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    -webkit-transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px);
    transform: skew(0deg, -60deg) translate3d(-100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    -o-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    -ms-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    -webkit-transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px);
    transform: skew(0deg, -60deg) translate3d(100%, 0%, 0px); }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
    -moz-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    -o-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(60deg, 0deg) translate3d(0, -100%, 0px);
    transform: skew(60deg, 0deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide {
    -moz-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    -o-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(60deg, 0deg) translate3d(0, 100%, 0px);
    transform: skew(60deg, 0deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    -o-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px);
    transform: skew(-60deg, 0deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide {
    -moz-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    -o-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px);
    transform: skew(-60deg, 0deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    -o-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(0deg, 20deg) translate3d(0, -100%, 0px);
    transform: skew(0deg, 20deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    -o-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(0deg, 20deg) translate3d(0, 100%, 0px);
    transform: skew(0deg, 20deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
    -moz-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    -o-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    -ms-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    -webkit-transform: skew(0deg, -20deg) translate3d(0, -100%, 0px);
    transform: skew(0deg, -20deg) translate3d(0, -100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide {
    -moz-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    -o-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    -ms-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    -webkit-transform: skew(0deg, -20deg) translate3d(0, 100%, 0px);
    transform: skew(0deg, -20deg) translate3d(0, 100%, 0px); }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
    -moz-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -o-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -ms-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    -webkit-transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    transform: skew(0deg, 0deg) translate3d(0%, 0%, 0px);
    opacity: 1; }
  .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop .lg-item {
  opacity: 0; }
  .lg-css3.lg-lollipop .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-lollipop .lg-item.lg-next-slide {
    -moz-transform: translate3d(0, 0, 0) scale(0.5);
    -o-transform: translate3d(0, 0, 0) scale(0.5);
    -ms-transform: translate3d(0, 0, 0) scale(0.5);
    -webkit-transform: translate3d(0, 0, 0) scale(0.5);
    transform: translate3d(0, 0, 0) scale(0.5); }
  .lg-css3.lg-lollipop .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-lollipop .lg-item.lg-prev-slide, .lg-css3.lg-lollipop .lg-item.lg-next-slide, .lg-css3.lg-lollipop .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
    -moz-transform: translate3d(0, 0, 0) scale(0.5);
    -o-transform: translate3d(0, 0, 0) scale(0.5);
    -ms-transform: translate3d(0, 0, 0) scale(0.5);
    -webkit-transform: translate3d(0, 0, 0) scale(0.5);
    transform: translate3d(0, 0, 0) scale(0.5); }
  .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-lollipop-rev .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate .lg-item {
  opacity: 0; }
  .lg-css3.lg-rotate .lg-item.lg-prev-slide {
    -moz-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); }
  .lg-css3.lg-rotate .lg-item.lg-next-slide {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  .lg-css3.lg-rotate .lg-item.lg-current {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1; }
  .lg-css3.lg-rotate .lg-item.lg-prev-slide, .lg-css3.lg-rotate .lg-item.lg-next-slide, .lg-css3.lg-rotate .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate-rev .lg-item {
  opacity: 0; }
  .lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); }
  .lg-css3.lg-rotate-rev .lg-item.lg-next-slide {
    -moz-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); }
  .lg-css3.lg-rotate-rev .lg-item.lg-current {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1; }
  .lg-css3.lg-rotate-rev .lg-item.lg-prev-slide, .lg-css3.lg-rotate-rev .lg-item.lg-next-slide, .lg-css3.lg-rotate-rev .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-tube .lg-item {
  opacity: 0; }
  .lg-css3.lg-tube .lg-item.lg-prev-slide {
    -moz-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    -o-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    -ms-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    -webkit-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
    transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0); }
  .lg-css3.lg-tube .lg-item.lg-next-slide {
    -moz-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    -o-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    -ms-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    -webkit-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
    transform: scale3d(1, 0, 1) translate3d(100%, 0, 0); }
  .lg-css3.lg-tube .lg-item.lg-current {
    -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-tube .lg-item.lg-prev-slide, .lg-css3.lg-tube .lg-item.lg-next-slide, .lg-css3.lg-tube .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.eot?n1z373");
  src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal; }

.lg-icon {
  font-family: 'lg';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: none; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar .lg-download:after {
    content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      -o-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }
  .lg-outer .lg-toogle-thumb:after {
    content: "\e1ff"; }
  .lg-outer .lg-toogle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e901"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e904"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e902"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e903"; }

.lg-group:after {
  content: "";
  display: table;
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("../img/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

.nice-select {
  display: inline-block;
  font-size: 1rem;
  width: auto;
  padding-top: 0.8rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 2px solid #A9A9A9;
  border-radius: 3px;
  font-family: "Open Sans", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #000;
  line-height: 1;
  border-radius: 0;
  height: 3.35rem;
  float: none; }
  .nice-select:active, .nice-select.open, .nice-select.open, .nice-select:focus, .nice-select:hover {
    border-color: #A9A9A9; }
  .nice-select > .current {
    display: inline-block;
    line-height: 1.5; }
  .nice-select:after {
    border-bottom: 2px solid #A9A9A9;
    border-right: 2px solid #A9A9A9; }
  .nice-select > .list {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 0 0 2px #A9A9A9;
    margin-top: 2px; }
    .nice-select > .list .option {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      line-height: 1;
      text-indent: 0; }
  .resource-center .nice-select {
    min-width: 13.8rem; }

::selection {
  background: #edb833; }

html {
  height: 100%;
  box-sizing: border-box;
  background-color: #24405b; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  -webkit-text-size-adjust: 100%; }

.bg-color-2 {
  background-color: #24405b;
  color: #FFFFFF; }
  .bg-color-2 a:not(.like-button) {
    color: #013e5d; }

.text-color-3 {
  color: #de3f19; }
  .text-color-3 * {
    color: #de3f19; }

.text-color-1 {
  color: #013e5d; }
  .text-color-1 * {
    color: #013e5d; }

button[class^="icon-"] {
  border: none;
  padding: 0;
  background-color: transparent; }
  button[class^="icon-"]:hover {
    background-color: transparent; }

.hide-module {
  display: none; }

@media only screen and (max-width: 767px) {
  .hide-on-xs {
    display: none; } }

.extra-top-padding {
  padding-top: 4rem !important; }
  @media only screen and (max-width: 767px) {
    .extra-top-padding {
      padding-top: 3rem !important; } }

.extra-bottom-padding {
  padding-bottom: 4rem !important; }
  @media only screen and (max-width: 767px) {
    .extra-bottom-padding {
      padding-bottom: 3rem !important; } }

@media only screen and (max-width: 767px) {
  .fullwidth-onmobile .component-content {
    max-width: 100% !important; } }

.img-maxwidth img {
  max-width: 100%; }

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

select:focus,
input:focus,
textarea:focus {
  -webkit-text-size-adjust: 100%; }

.lg-backdrop.in {
  opacity: 0.9; }

@media (hover: none) {
  .lg-on .lg-object {
    transform: none !important; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#wrapper #header .component.language-selector-spot .popup--choose-language {
  display: none; }

.component.breadcrumb .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.breadcrumb .component-content ol {
    list-style: none; }
    .component.breadcrumb .component-content ol li {
      text-indent: 0;
      padding-left: 0; }
    .component.breadcrumb .component-content ol li, .component.breadcrumb .component-content ol div {
      display: inline-block;
      white-space: nowrap; }
      .component.breadcrumb .component-content ol li:last-child .separator, .component.breadcrumb .component-content ol div:last-child .separator {
        display: none; }

.component.center-list-spot .desktop-list {
  display: none; }
  @media only screen and (min-width: 1200px) {
    .component.center-list-spot .desktop-list {
      display: block; } }

@media only screen and (min-width: 1200px) {
  .component.center-list-spot .mobile-list {
    display: none; } }

.component.center-list-spot .mobile-list {
  background-color: #333;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .component.center-list-spot .mobile-list .mobile-list-container {
    position: relative;
    width: 82rem;
    max-width: 80%;
    margin: 0 auto;
    /*&::after { TODO
          @include select-arrow();
          color: $corporate;
          pointer-events: none;
        }*/ }

.component.center-list-spot .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.center-list-spot .component-content .back-to-top {
    float: right;
    font-size: 1rem;
    color: #333; }
    .component.center-list-spot .component-content .back-to-top:before {
      font-family: "DGS-WebProgram";
      content: "";
      vertical-align: middle;
      margin-right: .3em;
      line-height: 1.1; }
    .component.center-list-spot .component-content .back-to-top[href="#content"] {
      display: none; }
  .component.center-list-spot .component-content .alphabet-pager {
    margin-top: 3rem;
    background-color: #333;
    display: none; }
    @media only screen and (min-width: 1200px) {
      .component.center-list-spot .component-content .alphabet-pager {
        display: block; } }
    .component.center-list-spot .component-content .alphabet-pager .alphabet-letter {
      display: inline-block;
      padding: .7em; }
    .component.center-list-spot .component-content .alphabet-pager a.alphabet-letter {
      color: #fff; }
    .component.center-list-spot .component-content .alphabet-pager span.alphabet-letter {
      color: #A9A9A9; }
  .component.center-list-spot .component-content .center-list-row {
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 0; }
    .component.center-list-spot .component-content .center-list-row .center-list-region {
      font-size: 2rem;
      font-family: "Muli", Arial, sans-serif;
      line-height: 1.2;
      border-bottom: 2px solid #333;
      padding-bottom: .6em;
      margin-bottom: .8em;
      font-size: 2rem; }
      @media only screen and (min-width: 1200px) {
        .component.center-list-spot .component-content .center-list-row .center-list-region {
          font-size: 2.5rem; } }
      .component.center-list-spot .component-content .center-list-row .center-list-region.bold {
        font-weight: bold;
        font-family: "Muli", Arial, sans-serif; }
    .component.center-list-spot .component-content .center-list-row .center-list-item {
      text-indent: 0;
      padding-left: 0;
      font-size: 1rem;
      display: inline-block;
      padding-bottom: 2rem;
      vertical-align: top;
      width: 33.333333%; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .component.center-list-spot .component-content .center-list-row .center-list-item {
          width: 50%; } }
      @media only screen and (min-width: 1200px) {
        .component.center-list-spot .component-content .center-list-row .center-list-item {
          width: 25%; } }
      .component.center-list-spot .component-content .center-list-row .center-list-item .center-name {
        color: #333;
        line-height: 1.4; }
      .component.center-list-spot .component-content .center-list-row .center-list-item address * {
        line-height: 1.4; }

.component.center-locator {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative; }
  .component.center-locator .map-nav-container {
    height: 7.5rem;
    background: #D3D3D3;
    width: 100%; }
    .component.center-locator .map-nav-container .map-nav-content {
      position: relative;
      width: 82rem;
      max-width: 80%;
      margin: 0 auto;
      width: auto;
      position: static;
      text-align: center; }
      .component.center-locator .map-nav-container .map-nav-content .map-top-nav {
        padding-top: 2.5rem; }
        .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search {
          position: relative;
          background-color: #D3D3D3; }
          @media only screen and (min-width: 1200px) {
            .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search .geo-locator {
              display: none;
              cursor: pointer;
              position: absolute;
              left: 50%;
              top: 0.5rem;
              margin-left: 7rem; }
              .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search .geo-locator:before {
                font-family: "DGS-WebProgram";
                content: "";
                color: rgba(169, 169, 169, 0.5);
                font-size: 1.6rem; } }
          .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search input[type=text] {
            width: 90%; }
            @media only screen and (max-width: 767px) {
              .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search input[type=text] {
                width: 60vw;
                height: 3.8rem;
                float: left;
                background-color: #fff; } }
            @media only screen and (min-width: 768px) and (max-width: 991px) {
              .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search input[type=text] {
                width: 60vw;
                height: 3.8rem;
                float: left;
                background-color: #fff; } }
            @media only screen and (min-width: 992px) and (max-width: 1199px) {
              .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search input[type=text] {
                width: 60vw;
                height: 3.8rem;
                float: left;
                background-color: #fff; } }
            @media only screen and (min-width: 1200px) {
              .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search input[type=text] {
                width: 37rem;
                height: 3.4rem;
                background-color: #fff; } }
          .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button {
            display: inline-block;
            font-size: 1rem;
            width: auto;
            padding-top: 0.8rem;
            padding-bottom: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            border: 1px solid #A9A9A9;
            border-radius: 3px;
            font-family: "Open Sans", "Arial", sans-serif;
            font-weight: normal;
            font-style: normal;
            color: #A9A9A9;
            line-height: 1;
            padding-left: 2rem;
            padding-right: 2rem;
            text-decoration: none;
            background-color: transparent;
            text-align: center;
            transition: all 0.2s;
            -webkit-appearance: none;
            position: absolute;
            right: -1.5rem;
            height: 3.8rem;
            width: 20vw;
            padding-left: 1.5rem; }
            .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button:not(.disabled) {
              cursor: pointer; }
            .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button a {
              color: #A9A9A9;
              text-decoration: none;
              background-color: transparent; }
            .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button:not(.disabled):hover {
              color: #fff;
              background-color: #A9A9A9;
              text-shadow: none; }
              .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button:not(.disabled):hover a {
                text-decoration: none;
                color: #fff;
                text-shadow: none; }
            @media only screen and (min-width: 1200px) {
              .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button {
                position: static;
                height: 3.4rem;
                width: 18rem; } }
            .component.center-locator .map-nav-container .map-nav-content .map-top-nav .search button:hover {
              opacity: 1; }
        .component.center-locator .map-nav-container .map-nav-content .map-top-nav .no-results-message,
        .component.center-locator .map-nav-container .map-nav-content .map-top-nav .technical-error-message {
          padding-top: .7em;
          color: #CC0000;
          position: absolute;
          display: none; }
  .component.center-locator .zoom.control {
    position: absolute;
    right: 0.7rem;
    top: 9.8rem;
    z-index: 1;
    user-select: none;
    text-align: right; }
    .component.center-locator .zoom.control > div:not(.marker-filter) {
      width: 2.8rem;
      height: 2.8rem;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
      margin: 0;
      display: inline-block;
      transition: opacity 0.2s;
      margin-left: .2rem;
      user-select: none;
      float: right; }
      .component.center-locator .zoom.control > div:not(.marker-filter):after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 1em;
        height: 1em;
        line-height: 1;
        font-family: "DGS-WebProgram";
        color: #000;
        font-size: 2.3em;
        text-align: center; }
      .component.center-locator .zoom.control > div:not(.marker-filter):hover {
        background-color: rgba(255, 255, 255, 0.5); }
      .component.center-locator .zoom.control > div:not(.marker-filter).zoom-in:after {
        content: ""; }
      .component.center-locator .zoom.control > div:not(.marker-filter).zoom-out:after {
        content: ""; }
      .component.center-locator .zoom.control > div:not(.marker-filter).locate {
        clear: both;
        margin-top: .2rem;
        display: none; }
        .component.center-locator .zoom.control > div:not(.marker-filter).locate:after {
          content: ""; }
  .component.center-locator .marker-filter {
    position: relative;
    background-color: #00CC00;
    display: none;
    white-space: nowrap; }
    @media only screen and (max-width: 991px) {
      .component.center-locator .marker-filter .marker-filter-wrapper {
        position: relative;
        width: 82rem;
        max-width: 80%;
        margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 2rem; } }
    .component.center-locator .marker-filter .filter-center,
    .component.center-locator .marker-filter .filter-partner {
      width: 48%;
      height: 2.8rem;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      cursor: pointer;
      transition: opacity 0.2s;
      user-select: none;
      padding-left: .5rem;
      display: inline-block;
      box-sizing: border-box;
      white-space: normal; }
      .component.center-locator .marker-filter .filter-center:hover,
      .component.center-locator .marker-filter .filter-partner:hover {
        background-color: rgba(255, 255, 255, 0.5); }
      .component.center-locator .marker-filter .filter-center::before,
      .component.center-locator .marker-filter .filter-partner::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 5px;
        height: 100%;
        display: inline-block;
        content: " "; }
      .component.center-locator .marker-filter .filter-center .filter-label,
      .component.center-locator .marker-filter .filter-partner .filter-label {
        height: 2.8rem;
        display: table-cell;
        vertical-align: middle;
        padding-left: 2.3rem;
        color: #000; }
      .component.center-locator .marker-filter .filter-center > img,
      .component.center-locator .marker-filter .filter-partner > img {
        position: absolute;
        top: .6rem;
        left: .9rem;
        height: 1.6rem; }
      .component.center-locator .marker-filter .filter-center.off .filter-checkbox,
      .component.center-locator .marker-filter .filter-partner.off .filter-checkbox {
        background: url("/design/build/retail/assets/unchecked.png") no-repeat; }
    .component.center-locator .marker-filter .filter-center {
      margin-right: 4%; }
      .component.center-locator .marker-filter .filter-center:before {
        background-color: #000; }
    .component.center-locator .marker-filter .filter-partner:before {
      background-color: #333; }
    .component.center-locator .marker-filter .filter-checkbox {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: .9rem;
      background: url("/design/build/retail/assets/checked.png") no-repeat;
      width: 19px;
      height: 23px; }
  .component.center-locator .mobile-infowindow {
    background-color: #000;
    overflow: hidden; }
    .component.center-locator .mobile-infowindow.partner,
    .component.center-locator .mobile-infowindow.partner .center-name {
      background-color: #333; }
      .component.center-locator .mobile-infowindow.partner .single-center,
      .component.center-locator .mobile-infowindow.partner .center-name .single-center {
        padding-bottom: 2rem; }
      .component.center-locator .mobile-infowindow.partner .center-Details,
      .component.center-locator .mobile-infowindow.partner .center-Consultation,
      .component.center-locator .mobile-infowindow.partner .center-name .center-Details,
      .component.center-locator .mobile-infowindow.partner .center-name .center-Consultation {
        display: none; }
    .component.center-locator .mobile-infowindow:not(.partner) .partner-details {
      display: none; }
    .component.center-locator .mobile-infowindow .single-center {
      padding-top: 2rem;
      padding-bottom: 2rem;
      position: relative;
      width: 82rem;
      max-width: 80%;
      margin: 0 auto;
      margin-top: -100%;
      transition: margin-top .5s; }
    .component.center-locator .mobile-infowindow.open .single-center {
      margin-top: 0px; }
  .component.center-locator .single-center .center-name {
    font-size: 1.5rem;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 600;
    font-style: normal;
    color: #A9A9A9;
    padding: 0.5rem 0 1.2rem 0; }
  .component.center-locator .single-center .center-address {
    font-size: 1rem;
    line-height: 1.3;
    display: inline-block;
    white-space: normal;
    color: #A9A9A9; }
    .component.center-locator .single-center .center-address:before {
      font-family: "DGS-WebProgram";
      display: inline-block;
      content: "";
      color: #A9A9A9;
      font-size: 1.4rem;
      width: 1.9em;
      height: 1.9em;
      border: 1px solid #ffffff;
      border-radius: 50%;
      text-align: center;
      line-height: 1.9;
      margin-right: 0.35em;
      vertical-align: middle; }
    .component.center-locator .single-center .center-address > div:first-child {
      display: inline-block;
      vertical-align: middle;
      width: 75%; }
    .component.center-locator .single-center .center-address::before {
      margin-right: 1rem;
      display: block;
      float: left; }
  .component.center-locator .single-center .center-phone {
    font-size: 2rem;
    display: block; }
    .component.center-locator .single-center .center-phone a {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #A9A9A9; }
      @media all and (-ms-high-contrast: none) {
        .component.center-locator .single-center .center-phone a {
          font-weight: bold; } }
    .component.center-locator .single-center .center-phone:before {
      font-family: "DGS-WebProgram";
      display: inline-block;
      content: "";
      color: #A9A9A9;
      font-size: 1.4rem;
      width: 1.9em;
      height: 1.9em;
      border: 1px solid #ffffff;
      border-radius: 50%;
      text-align: center;
      line-height: 1.9;
      margin-right: 0.35em;
      vertical-align: middle; }
    .component.center-locator .single-center .center-phone > div:first-child {
      display: inline-block;
      vertical-align: middle;
      width: 75%; }
    .component.center-locator .single-center .center-phone::before {
      margin-right: 1rem;
      margin-top: -0.2rem; }
  .component.center-locator .single-center .center-directions {
    padding-left: 3.8rem;
    margin: 1rem 0 0.5rem 0;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1rem;
    color: #A9A9A9;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s;
    position: relative; }
    .component.center-locator .single-center .center-directions:hover {
      opacity: 0.7; }
    .component.center-locator .single-center .center-directions:after {
      font-family: "DGS-WebProgram";
      color: #A9A9A9;
      content: "";
      display: inline-block;
      font-size: 1em;
      position: absolute;
      margin-left: 0.2rem;
      top: .3rem; }
  .component.center-locator .single-center .center-distance {
    padding-left: 3.8rem;
    margin: .6rem 0;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: .9rem;
    color: #A9A9A9; }
  .component.center-locator .single-center .center-Details {
    position: relative;
    padding-left: 3.8rem;
    margin: 0.3rem 0 1rem 0; }
    .component.center-locator .single-center .center-Details a {
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 1rem;
      color: #A9A9A9; }
  .component.center-locator .single-center div.center-Consultation {
    display: none; }
  .component.center-locator .single-center .partner-details {
    padding-left: 3.8rem;
    margin: .6rem 0;
    font-family: "Open Sans", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: .9rem;
    color: #A9A9A9; }
  .component.center-locator #map-canvas {
    height: 20rem; }
    .component.center-locator #map-canvas .gm-style.partner > div:first-child > div:nth-child(4) > div > div > div:first-child > div:last-child,
    .component.center-locator #map-canvas .gm-style.partner > div:first-child > div:nth-child(4) > div > div > div:first-child > div:nth-of-type(3) > div > div {
      background-color: #333 !important; }
    .component.center-locator #map-canvas .gm-style.partner .single-center {
      padding-bottom: 2rem;
      max-width: 20rem; }
    .component.center-locator #map-canvas .gm-style.partner .center-Details,
    .component.center-locator #map-canvas .gm-style.partner .center-Consultation {
      display: none; }
    .component.center-locator #map-canvas .gm-style.partner .center-name {
      background-color: #333; }
    .component.center-locator #map-canvas .gm-style:not(.partner) .partner-details {
      display: none; }
    .component.center-locator #map-canvas .gm-style > div:first-child {
      /*
                & > div:nth-child(4) {
                    & > div { // this is a pop-over
                        & > div {
                            margin-top: -1.6rem;
                            & > .gm-style-iw {
                                text-align: center;
                                & > div {
                                    text-align: left;
                                    overflow: hidden !important;
                                    & > div {
                                        overflow: hidden !important;
                                        min-width: 19rem;
                                    }
                                }
                            }
                            & > div:first-child {
                                & > div:last-child {
                                    //infoWindow
                                    background: $color-2 !important;
                                    border: 0;
                                    border-radius: 0 !important;
                                }
                                & > div:nth-of-type(2) {
                                    background-color: transparent !important;
                                    border-radius: 0 !important;
                                    box-shadow: none !important;
                                }
                                & > div:nth-of-type(3) {
                                    display: block !important;
                                    & > div {
                                        &:first-child {
                                            & > div {
                                                transform: skewX(32.6deg) !important;
                                            }
                                        }
                                        &:last-child {
                                            & > div {
                                                transform: skewX(-32.6deg) !important;
                                            }
                                        }
                                        & > div {
                                            background-color: $color-1 !important;
                                            box-shadow: none !important;
                                        }
                                    }
                                }
                            }
                            & > div:last-child {
                                width: 1rem !important;
                                height: 1rem !important;
                                right: 1rem !important;
                                top: 0.5rem !important;
                                opacity: 1 !important;
                                @include link-transition();

                                &:after {
                                    font-family: $icons;
                                    content: $cross;
                                    color: $cl_info_window_font_color;
                                    font-size: 1rem;
                                }

                                &:hover {
                                    @include link-hover-important();
                                }

                                img {
                                    display: none;
                                }

                            }
                        }
                    }
                }
                */ }
      .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) {
        /* New style start - seems div structure from gmap has changed */
        /* New style end */ }
        .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div {
          margin-top: -1.6rem; }
          .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > .gm-style-iw {
            text-align: center; }
            .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > .gm-style-iw > div {
              text-align: left;
              overflow: hidden !important; }
              .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > .gm-style-iw > div > div {
                overflow: hidden !important;
                min-width: 19rem; }
          .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:first-child > div:last-child {
            background-color: #333 !important;
            border: 0;
            border-radius: 0 !important; }
          .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:first-child > div:nth-of-type(2) {
            background-color: transparent !important;
            border-radius: 0 !important;
            box-shadow: none !important; }
          .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:first-child > div:nth-of-type(3) {
            display: block !important; }
            .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:first-child > div:nth-of-type(3) > div:first-child > div {
              transform: skewX(32.6deg) !important; }
            .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:first-child > div:nth-of-type(3) > div:last-child > div {
              transform: skewX(-32.6deg) !important; }
            .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:first-child > div:nth-of-type(3) > div > div {
              background-color: #000 !important;
              box-shadow: none !important; }
          .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:last-child {
            width: 1rem !important;
            height: 1rem !important;
            right: 1rem !important;
            top: 0.5rem !important;
            opacity: 1 !important;
            opacity: 1;
            transition: opacity 0.2s; }
            .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:last-child:after {
              font-family: "DGS-WebProgram";
              content: "close";
              color: #A9A9A9;
              font-size: 1rem; }
            .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:last-child:hover {
              opacity: 0.7 !important; }
            .component.center-locator #map-canvas .gm-style > div:first-child > div:nth-child(3) > div:nth-child(2) > div:nth-child(4) > div > div:last-child img {
              display: none; }

@media only screen and (min-width: 1200px) {
  /*desktop */
  #wrapper .component.center-locator #map-canvas {
    /*this is a minimum height, if there is space a larger one is applied with JS */
    height: 460px; }
  #wrapper .component.center-locator .mobile-infowindow {
    display: none; }
  #wrapper .component.center-locator .marker-filter {
    position: absolute;
    right: 0.7rem;
    top: 14.4rem;
    text-align: left;
    z-index: 1;
    background-color: transparent; }
    #wrapper .component.center-locator .marker-filter .filter-center,
    #wrapper .component.center-locator .marker-filter .filter-partner {
      float: left;
      clear: both;
      width: 12rem; }
    #wrapper .component.center-locator .marker-filter .filter-center {
      margin-bottom: .3rem;
      margin-right: 0; }
    #wrapper .component.center-locator .marker-filter .filter-label {
      height: 2.6rem;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: 700;
      font-style: normal; } }

html[lang=sv-SE] #wrapper .component.center-locator .single-center .center-phone a {
  opacity: 1; }

@media only screen and (min-width: 2000px) {
  #wrapper .component.center-locator .single-center .center-name {
    font-size: 1.2rem; }
  #wrapper .component.center-locator .single-center .center-address {
    font-size: 1rem; }
    #wrapper .component.center-locator .single-center .center-address:before {
      font-size: 1.2rem; }
  #wrapper .component.center-locator .single-center .center-phone {
    font-size: 1.5rem; }
    #wrapper .component.center-locator .single-center .center-phone:before {
      font-size: 1.2rem; }
  #wrapper .component.center-locator .single-center .center-directions {
    font-size: 1rem; }
    #wrapper .component.center-locator .single-center .center-directions:after {
      font-size: 1em; }
  #wrapper .component.center-locator .single-center .center-distance {
    font-size: 0.8rem; }
  #wrapper .component.center-locator .single-center .center-Details a {
    font-size: 1rem; }
  #wrapper .component.center-locator .single-center div.center-Consultation {
    display: none; } }

@media only screen and (max-width: 767px) {
  .component.center-locator .single-center .center-directions:after {
    top: .15rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.center-locator .single-center .center-directions:after {
    top: .15rem; } }

.centre-image {
  max-height: 35em;
  overflow: hidden;
  position: relative;
  margin-top: 1em; }
  .centre-image img {
    width: 100%; }

.component.centre-spot .component-content .centre-content {
  background-color: #fff;
  position: relative; }
  .component.centre-spot .component-content .centre-content .outer-pos-wrapper {
    margin: 0 auto;
    min-height: 100%; }
    .component.centre-spot .component-content .centre-content .outer-pos-wrapper #centre-map .gm-style > .gmnoprint, .component.centre-spot .component-content .centre-content .outer-pos-wrapper #centre-map .gm-style > .gm-style-cc {
      display: none; }
    .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button {
      display: inline-block;
      font-size: 1rem;
      width: auto;
      padding-top: 0.8rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 1px solid #A9A9A9;
      border-radius: 3px;
      font-family: "Open Sans", "Arial", sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #A9A9A9;
      line-height: 1;
      padding-left: 2rem;
      padding-right: 2rem;
      text-decoration: none;
      background-color: transparent;
      text-align: center;
      transition: all 0.2s;
      -webkit-appearance: none;
      position: absolute;
      left: 50%;
      top: 11rem;
      transform: translateX(-50%);
      z-index: 1;
      padding: 0.5rem 1.5rem;
      cursor: pointer; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button:not(.disabled) {
        cursor: pointer; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button a {
        color: #A9A9A9;
        text-decoration: none;
        background-color: transparent; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button:not(.disabled):hover {
        color: #fff;
        background-color: #A9A9A9;
        text-shadow: none; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-cta.button:not(.disabled):hover a {
          text-decoration: none;
          color: #fff;
          text-shadow: none; }
    .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address {
      background-color: #A9A9A9;
      padding: 2em;
      position: relative; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address > h4 {
        padding: 0; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-title {
        margin: 0 0 1.6em 0;
        color: #000; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax {
        margin-bottom: 1em; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address *,
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone *,
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail *,
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours *,
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax * {
          color: #000; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-book {
        display: none; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address:before {
        font-family: "DGS-WebProgram";
        display: inline-block;
        content: "";
        color: #000;
        font-size: 2rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address::before {
        border-color: #000; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address address .address-content {
        max-width: 75%; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone:before,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax:before {
        font-family: "DGS-WebProgram";
        display: inline-block;
        content: "";
        color: #000;
        font-size: 2rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone > div:first-child,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone::before,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax::before {
        border-color: #000; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .phone-number,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .phone-number {
        font-size: 2rem;
        top: 0.3571rem;
        position: relative;
        display: inline-block; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .phone-number:hover,
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .phone-number:hover {
          opacity: 0.7;
          transition: opacity ease-in-out .25s; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .fax-number,
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .fax-number {
        font-size: 1.1rem;
        font-family: "Muli", Arial, sans-serif;
        line-height: 1.2;
        margin-bottom: 0;
        color: #ffffff; }
        @media only screen and (min-width: 1200px) {
          .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-phone .fax-number,
          .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax .fax-number {
            font-size: 1.2rem; } }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax:before {
        font-family: "DGS-WebProgram";
        display: inline-block;
        content: "";
        color: #000;
        font-size: 2rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-fax::before {
        border-color: #000; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail:before {
        font-family: "DGS-WebProgram";
        display: inline-block;
        content: "";
        color: #000;
        font-size: 2rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-mail::before {
        border-color: #000; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours:before {
        font-family: "DGS-WebProgram";
        display: inline-block;
        content: "";
        color: #000;
        font-size: 2rem;
        width: 1.9em;
        height: 1.9em;
        border: 1px solid #ffffff;
        border-radius: 50%;
        text-align: center;
        line-height: 1.9;
        margin-right: 0.35em;
        vertical-align: middle; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours > div:first-child {
        display: inline-block;
        vertical-align: middle;
        width: 75%; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours::before {
        border-color: #000; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list {
        max-width: 80%; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list table td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          padding-top: .3em;
          padding-bottom: .3em; }
          .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list table td:first-child {
            padding-right: 0.4em; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours .hours-list table tr:last-child td {
          border-bottom: none; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address .address-hours li {
        padding-bottom: 0.2em; }
    .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper {
      margin-top: 1rem;
      padding: 2em; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
        font-family: "Muli", Arial, sans-serif;
        font-size: 2.1rem;
        margin: 0 0 0.6em 0;
        line-height: 1.2; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name.bold {
          font-family: "Muli", Arial, sans-serif; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name.light {
          margin: 0 0 0.3em 0; }
        @media only screen and (min-width: 1200px) {
          .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
            font-size: 3.2rem; } }
    .component.centre-spot .component-content .centre-content .outer-pos-wrapper::after {
      clear: both;
      display: table;
      content: "";
      line-height: 0; }

.component.staff {
  opacity: 1;
  position: relative;
  padding: 2rem 0 !important;
  background-color: #eee; }
  .component.staff .staff-title {
    text-align: center; }
  .component.staff .job-title {
    color: #fff; }
  .component.staff .image-spot-elements {
    overflow: auto;
    padding-top: 2em;
    padding-bottom: 2em; }
    .component.staff .image-spot-elements .image-spot-element {
      text-align: center; }
      .component.staff .image-spot-elements .image-spot-element .image-spot-element-header {
        margin-bottom: 0.1em; }
        .component.staff .image-spot-elements .image-spot-element .image-spot-element-header h4 {
          line-height: 1.2; }

.component.center-testimonials .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 4em;
  padding-bottom: 1em; }
  .component.center-testimonials .component-content .testimonial-title {
    text-align: center;
    padding-bottom: 3em; }
  .component.center-testimonials .component-content .testimonial-items .testimonial-item {
    padding-bottom: 3em; }

@media only screen and (min-width: 992px) {
  .component.centre-spot .component-content .centre-content .outer-pos-wrapper #centre-map {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0; }
  .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper {
    display: inline-block;
    width: 50%;
    margin-right: 5%;
    margin-top: 20rem;
    float: right;
    padding: 0; }
    .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
      margin-top: 0.5em; }
  .component.centre-spot .component-content .centre-address {
    display: inline-block;
    width: 43%;
    box-sizing: border-box;
    margin-top: 18.75rem; }
  .staff .component-content > ul > li:first-child:nth-last-child(1) {
    width: 33.333%;
    margin: 0 auto;
    float: none; }
  .staff .component-content > ul > li:first-child:nth-last-child(2),
  .staff .component-content > ul > li:first-child:nth-last-child(2) ~ li {
    width: 33.333%;
    float: left; }
  .staff .component-content > ul > li:first-child:nth-last-child(2) {
    margin-left: 16.66%; }
  .component.center-testimonials .component-content {
    padding-top: 6em;
    padding-bottom: 6em; }
    .component.center-testimonials .component-content .testimonial-title {
      padding-bottom: 4em; }
    .component.center-testimonials .component-content .testimonial-items {
      white-space: nowrap; }
      .component.center-testimonials .component-content .testimonial-items .testimonial-item {
        width: 45%;
        white-space: normal;
        display: inline-block;
        margin-right: 10%;
        vertical-align: top; }
        .component.center-testimonials .component-content .testimonial-items .testimonial-item:nth-child(2n) {
          margin-right: 0; } }

@media only screen and (min-width: 1200px) {
  .component.centre-spot .component-content .centre-content {
    margin-bottom: 6em; }
    .component.centre-spot .component-content .centre-content .outer-pos-wrapper {
      width: 74rem;
      max-width: 90%; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper {
        width: 59%;
        margin-right: 0%;
        margin-top: 21.5rem; }
        .component.centre-spot .component-content .centre-content .outer-pos-wrapper .inner-pos-wrapper .centre-name {
          margin-top: 0; }
      .component.centre-spot .component-content .centre-content .outer-pos-wrapper .centre-address {
        width: 34%;
        margin-top: 14rem; } }

.component.cookie-warning {
  display: none;
  position: fixed;
  top: auto;
  bottom: 0;
  width: 100%;
  z-index: 1030;
  background-color: #333;
  border-top: 1px solid #A9A9A9; }
  .component.cookie-warning .component-content {
    color: #D3D3D3; }
    .component.cookie-warning .component-content .info {
      font-size: .8rem;
      padding: 0.8em 2.5em 0.8em 0.8em; }
    .component.cookie-warning .component-content .submit {
      font-family: "DGS-WebProgram";
      position: absolute;
      top: 0.7em;
      right: 0.7em;
      cursor: pointer;
      font-size: 0.9rem; }
      .component.cookie-warning .component-content .submit::after {
        content: "close";
        color: #D3D3D3; }
  .component.cookie-warning.initialized {
    display: block;
    animation: cookieBarBottom 2s; }

@keyframes cookieBarBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0; } }

.on-page-editor #footer .component.cookie-warning.initialized {
  display: none !important; }

.component.form .component-content {
  position: relative;
  width: 77rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.form .component-content .scfTitleBorder {
    font-family: "Muli", Arial, sans-serif;
    font-size: 3.4em;
    font-weight: normal;
    font-style: normal;
    margin: 0 0 0.4em 0;
    line-height: 1.2;
    color: inherit;
    margin: 0 0 1rem 0; }
    .component.form .component-content .scfTitleBorder.bold {
      font-family: "Muli", Arial, sans-serif; }
  .component.form .component-content .scfSectionBorder {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .component.form .component-content .scfDropListBorder,
  .component.form .component-content .scfEmailBorder,
  .component.form .component-content .scfMultipleLineTextBorder,
  .component.form .component-content .scfSingleLineTextBorder,
  .component.form .component-content .scfPasswordBorder,
  .component.form .component-content .scfNumberBorder,
  .component.form .component-content .scfDatePickerBorder,
  .component.form .component-content .scfDateBorder,
  .component.form .component-content .scfRadioButtonListBorder,
  .component.form .component-content .scfListBoxBorder,
  .component.form .component-content .scfCheckBoxListBorder,
  .component.form .component-content .scfFileUploadBorder,
  .component.form .component-content .scfDateSelectorBorder,
  .component.form .component-content .scfCreditCardBorder,
  .component.form .component-content .scfConfirmPasswordBorder,
  .component.form .component-content .scfCaptchaBorder,
  .component.form .component-content .scfTelephoneBorder,
  .component.form .component-content .scfSmsTelephoneBorder {
    margin-bottom: 1.33333rem;
    margin-top: 0;
    position: relative; }
  .component.form .component-content .scfDropListLabel,
  .component.form .component-content .scfEmailLabel,
  .component.form .component-content .scfMultipleLineTextLabel,
  .component.form .component-content .scfSingleLineTextLabel,
  .component.form .component-content .scfPasswordLabel,
  .component.form .component-content .scfNumberLabel,
  .component.form .component-content .scfDatePickerLabel,
  .component.form .component-content .scfDateLabel,
  .component.form .component-content .scfRadioButtonListLabel,
  .component.form .component-content .scfCheckBoxListLabel,
  .component.form .component-content .scfListBoxLabel,
  .component.form .component-content .scfFileUploadLabel,
  .component.form .component-content .scfDateSelectorLabel,
  .component.form .component-content .scfCreditCardLabel,
  .component.form .component-content .scfConfirmPasswordLabel,
  .component.form .component-content .scfCaptchaLabel,
  .component.form .component-content .scfTelephoneLabel,
  .component.form .component-content .scfSmsTelephoneLabel {
    float: none;
    clear: none;
    width: 100%;
    padding: 0 0 0.5rem 0; }
  .component.form .component-content .scfEmailGeneralPanel,
  .component.form .component-content .scfMultipleLineGeneralPanel,
  .component.form .component-content .scfSingleLineGeneralPanel,
  .component.form .component-content .scfPasswordGeneralPanel,
  .component.form .component-content .scfNumberGeneralPanel,
  .component.form .component-content .scfDatePickerGeneralPanel,
  .component.form .component-content .scfDateGeneralPanel,
  .component.form .component-content .scfRadioButtonListGeneralPanel,
  .component.form .component-content .scfCheckBoxListGeneralPanel,
  .component.form .component-content .scfFileUploadGeneralPanel,
  .component.form .component-content .scfDateSelectorGeneralPanel,
  .component.form .component-content .scfCreditCardGeneralPanel,
  .component.form .component-content .scfConfirmPasswordGeneralPanel,
  .component.form .component-content .scfCaptchaGeneralPanel,
  .component.form .component-content .scfTelephoneGeneralPanel,
  .component.form .component-content .scfSmsTelephoneGeneralPanel {
    float: none;
    clear: none;
    width: 100%; }
  .component.form .component-content .scfValidatorRequired,
  .component.form .component-content .scfRequired {
    float: none;
    position: absolute;
    right: 0;
    top: 0.5rem; }
  .component.form .component-content .scfForm input:not([type=checkbox]):not([type=radio]):not([type=submit]),
  .component.form .component-content .scfForm select,
  .component.form .component-content .scfForm textarea {
    width: 100%; }
  .component.form .component-content .scfSubmitButtonBorder {
    text-align: center; }
  .component.form .component-content .scfListBoxGeneralPanel,
  .component.form .component-content .scfDropListGeneralPanel {
    width: 100%;
    float: none; }
  .component.form .component-content .scfError,
  .component.form .component-content .scfValidationSummary,
  .component.form .component-content .scfValidatorRequired,
  .component.form .component-content .scfValidator {
    color: #CC0000; }
  .component.form .component-content .scfValidationSummary {
    margin-left: 0; }
    .component.form .component-content .scfValidationSummary:not(:empty) {
      margin-bottom: 1rem; }
  .component.form .component-content .scfSectionBorderAsFieldSet {
    padding: 0;
    border: 0;
    margin: 0 0 1rem 0; }
  .component.form .component-content .scfSectionLegend {
    font-size: 2rem;
    font-family: "Muli", Arial, sans-serif;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    margin-bottom: 0.66667rem; }
    @media only screen and (min-width: 1200px) {
      .component.form .component-content .scfSectionLegend {
        font-size: 2.5rem; } }
    .component.form .component-content .scfSectionLegend.bold {
      font-weight: bold;
      font-family: "Muli", Arial, sans-serif; }
  .component.form .component-content .scfHiddenFieldBorder {
    display: none; }
  .component.form .component-content input[type=checkbox] + label,
  .component.form .component-content input[type=radio] + label {
    margin-left: .3rem; }

#wrapper .component.iframe:not(.full-width):not(.slim) .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }

#wrapper .component.iframe iframe {
  width: 100%; }

.component.image img {
  width: 100%;
  height: auto; }

.image-spot .component-content .image-spot-elements .image-spot-element-text, .image-spot .component-content .image-spot-elements .image-spot-element-header {
  max-width: 80%;
  margin: auto; }

.image-spot .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  .image-spot .component-content ul {
    list-style: none; }
  .image-spot .component-content li {
    margin-top: 0;
    line-height: normal; }
  .image-spot .component-content .image-spot-elements {
    text-align: center;
    position: relative; }
    .image-spot .component-content .image-spot-elements .image-spot-element {
      list-style: none;
      margin-bottom: 2rem;
      overflow: hidden;
      text-indent: 0;
      padding-left: 0; }
    .image-spot .component-content .image-spot-elements .image-spot-element-image {
      padding: 0 25%; }
      .image-spot .component-content .image-spot-elements .image-spot-element-image:not(:empty) {
        margin-bottom: 1rem; }
      .image-spot .component-content .image-spot-elements .image-spot-element-image * {
        max-width: 100%;
        height: auto; }
    .image-spot .component-content .image-spot-elements .image-spot-element-link:not(:empty) {
      margin-top: 1rem; }

.image-spot.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 80%;
  margin: 0 auto; }

.image-spot.round-spot .image-spot-element-image img {
  display: inline-block;
  border-radius: 50%; }

@media only screen and (min-width: 992px) {
  .image-spot .component-content .image-spot-elements {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */
    /* two items */
    /* three items */
    /* four items */
    /* five items */ }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10),
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li .image-spot-element-image {
      padding: 0 4rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li .image-spot-element-image {
      padding: 0 2rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li .image-spot-element-image {
      padding: 0 1.5rem; }
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) .image-spot-element-image,
    .image-spot .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li .image-spot-element-image {
      padding: 0 1rem; }
    .image-spot .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 0; }
  .image-spot .component-content .image-spot-element-context {
    display: none; } }

.component.image-spot .component-content .image-spot-element-text ul {
  list-style-type: disc;
  list-style-position: outside; }
  .component.image-spot .component-content .image-spot-element-text ul li {
    padding-left: 0;
    text-indent: 0; }

.component.intro-banner {
  padding-top: 0;
  padding-bottom: 0; }
  .component.intro-banner .component-content {
    position: relative; }
  .component.intro-banner .intro-banner-image {
    width: 100%; }
    .component.intro-banner .intro-banner-image img {
      max-width: 100%;
      height: auto;
      display: block;
      width: 100%; }
  .component.intro-banner .intro-banner-content {
    position: absolute;
    top: 2rem;
    bottom: 2rem;
    left: 0;
    right: 0; }
  .component.intro-banner .outer-pos-wrapper {
    position: relative;
    width: 82rem;
    max-width: 80%;
    margin: 0 auto;
    height: 100%; }
  .component.intro-banner .inner-pos-wrapper {
    width: 100%;
    position: relative;
    display: inline-block; }
    @media only screen and (min-width: 992px) {
      .component.intro-banner .inner-pos-wrapper {
        width: 55%; } }
  .component.intro-banner .intro-banner-link {
    display: none; }
  .component.intro-banner .sticky-downlink {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }
    .component.intro-banner .sticky-downlink a {
      display: inline-block;
      transition: all 0.2s;
      color: inherit; }
      .component.intro-banner .sticky-downlink a:before {
        font-family: "DGS-WebProgram";
        content: "";
        font-size: 4rem;
        line-height: 1;
        color: inherit; }
      .component.intro-banner .sticky-downlink a:hover {
        margin-bottom: -.5rem; }
      .component.intro-banner .sticky-downlink a span {
        display: none; }
  .component.intro-banner.middle .inner-pos-wrapper {
    top: 50%;
    transform: translateY(-50%); }
  .component.intro-banner.bottom .inner-pos-wrapper {
    top: 100%;
    transform: translateY(-100%); }
  .component.intro-banner.right .outer-pos-wrapper {
    text-align: right; }
  .component.intro-banner.center .outer-pos-wrapper {
    text-align: center; }
  .component.intro-banner.content-overflow .intro-banner-content {
    position: static;
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-top: 1rem; } }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.content-overflow .intro-banner-content {
        padding-bottom: 1rem; } }
  .component.intro-banner.content-overflow .inner-pos-wrapper {
    position: static;
    transform: none; }

.component.rich-text .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }

.component.rich-text.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 80%;
  margin: 0 auto; }

.component.rich-text.overlay {
  padding: 0 !important;
  opacity: 0 !important;
  position: relative;
  transition: opacity 0.1s ease-in; }
  .component.rich-text.overlay .component-content {
    transition: all 0.2s ease-in;
    position: absolute;
    top: 0;
    left: -25%;
    right: 100%;
    transform: translateY(-100%);
    padding: 1rem;
    background-color: black;
    color: #ffffff;
    margin: 0;
    width: auto;
    max-width: none; }
    .component.rich-text.overlay .component-content * {
      color: white; }
    .component.rich-text.overlay .component-content h1, .component.rich-text.overlay .component-content h2, .component.rich-text.overlay .component-content h3, .component.rich-text.overlay .component-content h4 {
      font-size: 1rem; }
    .component.rich-text.overlay .component-content p {
      font-size: 0.8rem; }
  .component.rich-text.overlay.active {
    opacity: 1 !important; }
    .component.rich-text.overlay.active .component-content {
      left: 0;
      right: 75%; }

.on-page-editor #wrapper > #content .component.rich-text {
  min-height: 100px; }

.component.rich-text-list .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.rich-text-list .component-content:before, .component.rich-text-list .component-content:after {
    content: '';
    clear: both;
    display: table; }
  .component.rich-text-list .component-content > ul {
    list-style: none; }
    .component.rich-text-list .component-content > ul > li {
      width: 100%;
      text-indent: 0;
      padding-left: 0; }
      .component.rich-text-list .component-content > ul > li + li {
        margin-top: 2rem; }
      .component.rich-text-list .component-content > ul > li ul {
        list-style: none; }
        .component.rich-text-list .component-content > ul > li ul li + li {
          margin-top: .7rem; }
        .component.rich-text-list .component-content > ul > li ul li:not(:last-child) {
          padding-bottom: .7rem;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .component.rich-text-list .component-content > ul > li:not(:last-child) {
        padding-right: 2rem; }

@media only screen and (min-width: 992px) {
  .component.rich-text-list .component-content > ul {
    /* one item */
    /* two items */
    /* three items */
    /* four items */
    /* five items */
    /* six items */
    /* seven items */
    /* eight items */
    /* nine items */
    /* ten items */ }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(1) {
      width: 100%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(2) ~ li {
      width: 50%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(4) ~ li {
      width: 25%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(5) ~ li {
      width: 20%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(6) ~ li {
      width: 16.6666%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(7) ~ li {
      width: 14.2857%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(8) ~ li {
      width: 12.5%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(9) ~ li {
      width: 11.1111%;
      float: left; }
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10),
    .component.rich-text-list .component-content > ul > li:first-child:nth-last-child(10) ~ li {
      width: 10%;
      float: left; }
    .component.rich-text-list .component-content > ul > li + li {
      margin-top: 0; } }

#wrapper .submenu-point-spot {
  padding-top: 0;
  padding-bottom: 0; }
  #wrapper .submenu-point-spot .component-content a {
    position: relative;
    top: -3rem; }

.component.video-spot .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  .component.video-spot .component-content code {
    display: none; }
  .component.video-spot .component-content .video-spot-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    cursor: pointer; }
  .component.video-spot .component-content .video-spot-container,
  .component.video-spot .component-content .video-spot-video,
  .component.video-spot .component-content .video-spot-image,
  .component.video-spot .component-content img {
    display: inline-block; }
  .component.video-spot .component-content .video-spot-play-btn {
    display: none; }
  .component.video-spot .component-content .video-spot-image img {
    max-width: 100%;
    height: auto; }

.component.video-spot.slim .component-content {
  position: relative;
  width: 77rem;
  max-width: 80%;
  margin: 0 auto; }

.video-spot-overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; }
  .video-spot-overlay .video-spot-overlay-close {
    width: 3rem;
    height: 3rem;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer; }
    .video-spot-overlay .video-spot-overlay-close::after {
      content: "close";
      position: absolute;
      top: 0;
      left: 0;
      font-family: "DGS-WebProgram";
      speak: none;
      font-size: 3rem;
      color: #000; }
  .video-spot-overlay .video-spot-overlay-video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

/* stylelint-disable selector-list-comma-newline-after */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

body {
  font-size: 12px;
  font-family: "Muli", sans-serif;
  font-weight: normal;
  line-height: 1.4;
  color: #24405b; }
  @media only screen and (min-width: 768px) {
    body {
      font-size: 16px; } }

strong {
  font-weight: 700; }

h1, .like-h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.33;
  letter-spacing: -0.6px; }
  @media only screen and (min-width: 768px) {
    h1, .like-h1 {
      font-size: 30px; } }

.like-h1-light {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.33;
  letter-spacing: -0.6px;
  font-weight: 300;
  letter-spacing: -1px; }
  @media only screen and (min-width: 768px) {
    .like-h1-light {
      font-size: 30px; } }

h2, .like-h2,
h3 {
  font-size: 22px;
  line-height: 1.35; }
  @media only screen and (min-width: 768px) {
    h2, .like-h2,
    h3 {
      font-size: 26px; } }
  @media only screen and (min-width: 1200px) {
    h2, .like-h2,
    h3 {
      font-size: 30px; } }

h4 {
  font-weight: 600;
  font-size: 17px; }

h5, .like-h5 {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.27; }
  @media only screen and (min-width: 768px) {
    h5, .like-h5 {
      font-size: 22px; } }

a, .like.a {
  color: #3344f6;
  font-weight: bold; }

.like-button {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  border-radius: 19.5px;
  padding: 11px 26px 12px; }
  @media only screen and (min-width: 1200px) {
    .like-button {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .like-button:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .like-button:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 1200px) {
      .ie .like-button:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .ie .like-button:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 768px) {
    .like-button {
      font-size: 16px; } }
  .like-button a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .like-button {
      border-radius: 25px;
      padding: 12px 35px 13px; } }
  @media only screen and (min-width: 1200px) {
    .like-button {
      font-size: 18px; } }

.like-button-large {
  font-size: 22px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  border-radius: 25px;
  padding: 11px 26px 12px; }
  @media only screen and (min-width: 1200px) {
    .like-button-large {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .like-button-large:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .like-button-large:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 1200px) {
      .ie .like-button-large:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .ie .like-button-large:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 768px) {
    .like-button-large {
      font-size: 26px; } }
  @media only screen and (min-width: 1200px) {
    .like-button-large {
      font-size: 30px; } }
  .like-button-large a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .like-button-large {
      border-radius: 23px;
      padding: 12.5px 35px 14.5px; } }

.like-button-small {
  font-size: 11px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  border-radius: 16.5px;
  padding: 7px 21px 8px; }
  @media only screen and (min-width: 1200px) {
    .like-button-small {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .like-button-small:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .like-button-small:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 1200px) {
      .ie .like-button-small:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .ie .like-button-small:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 768px) {
    .like-button-small {
      font-size: 13px; } }
  .like-button-small a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .like-button-small {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }

.like-button-minimal {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif; }
  @media only screen and (min-width: 768px) {
    .like-button-minimal {
      font-size: 16px; } }
  .like-button-minimal a {
    color: #ffffff;
    text-decoration: none; }

.white-buttons .like-button {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  border-radius: 19.5px;
  padding: 11px 26px 12px;
  color: #3344f6;
  background-color: #ffffff; }
  @media only screen and (min-width: 1200px) {
    .white-buttons .like-button {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      color: #3344f6;
      transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .white-buttons .like-button:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      .white-buttons .like-button:hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 1200px) {
      .ie .white-buttons .like-button:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #ffffff;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #ffffff;
        transition: all 0.8s ease-in-out; }
        .ie .white-buttons .like-button:hover a {
          color: #ffffff;
          transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button {
      font-size: 16px; } }
  .white-buttons .like-button a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button {
      border-radius: 25px;
      padding: 12px 35px 13px; } }
  @media only screen and (min-width: 1200px) {
    .white-buttons .like-button {
      font-size: 18px; } }
  .white-buttons .like-button a {
    color: #3344f6; }

.white-buttons .like-button-large {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  border-radius: 25px;
  padding: 11px 26px 12px;
  color: #3344f6;
  background-color: #ffffff; }
  @media only screen and (min-width: 1200px) {
    .white-buttons .like-button-large {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      color: #3344f6;
      transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .white-buttons .like-button-large:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      .white-buttons .like-button-large:hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 1200px) {
      .ie .white-buttons .like-button-large:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #ffffff;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #ffffff;
        transition: all 0.8s ease-in-out; }
        .ie .white-buttons .like-button-large:hover a {
          color: #ffffff;
          transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-large {
      font-size: 16px; } }
  .white-buttons .like-button-large a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-large {
      border-radius: 23px;
      padding: 12.5px 35px 14.5px; } }
  .white-buttons .like-button-large a {
    color: #3344f6; }

.white-buttons .like-button-small {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  border-radius: 16.5px;
  padding: 7px 21px 8px;
  color: #3344f6;
  background-color: #ffffff; }
  @media only screen and (min-width: 1200px) {
    .white-buttons .like-button-small {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      color: #3344f6;
      transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .white-buttons .like-button-small:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      .white-buttons .like-button-small:hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 1200px) {
      .ie .white-buttons .like-button-small:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #ffffff;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #ffffff;
        transition: all 0.8s ease-in-out; }
        .ie .white-buttons .like-button-small:hover a {
          color: #ffffff;
          transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-small {
      font-size: 16px; } }
  .white-buttons .like-button-small a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-small {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }
  .white-buttons .like-button-small a {
    color: #3344f6; }

.white-buttons .like-button-white-minimal {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  color: #3344f6;
  background-color: #ffffff; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-white-minimal {
      font-size: 16px; } }
  .white-buttons .like-button-white-minimal a {
    color: #ffffff;
    text-decoration: none; }
  .white-buttons .like-button-white-minimal a {
    color: #3344f6; }

.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }

.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }

.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }

.white-txt {
  color: #ffffff; }
  .white-txt h1,
  .white-txt .like-h1,
  .white-txt a,
  .white-txt .like-a {
    color: #ffffff; }

p + p {
  margin-top: 1em; }

p + ol {
  margin-top: 1em; }

p + ul {
  margin-top: 1em; }

p + h1 {
  margin-top: 1em; }

p + h2 {
  margin-top: 1em; }

p + h3 {
  margin-top: 1em; }

p + h4 {
  margin-top: 1em; }

p + h5 {
  margin-top: 1em; }

ol + p {
  margin-top: 1em; }

ol + ol {
  margin-top: 1em; }

ol + ul {
  margin-top: 1em; }

ol + h1 {
  margin-top: 1em; }

ol + h2 {
  margin-top: 1em; }

ol + h3 {
  margin-top: 1em; }

ol + h4 {
  margin-top: 1em; }

ol + h5 {
  margin-top: 1em; }

ul + p {
  margin-top: 1em; }

ul + ol {
  margin-top: 1em; }

ul + ul {
  margin-top: 1em; }

ul + h1 {
  margin-top: 1em; }

ul + h2 {
  margin-top: 1em; }

ul + h3 {
  margin-top: 1em; }

ul + h4 {
  margin-top: 1em; }

ul + h5 {
  margin-top: 1em; }

h1 + p {
  margin-top: 1em; }

h1 + ol {
  margin-top: 1em; }

h1 + ul {
  margin-top: 1em; }

h1 + h1 {
  margin-top: 1em; }

h1 + h2 {
  margin-top: 1em; }

h1 + h3 {
  margin-top: 1em; }

h1 + h4 {
  margin-top: 1em; }

h1 + h5 {
  margin-top: 1em; }

h2 + p {
  margin-top: 1em; }

h2 + ol {
  margin-top: 1em; }

h2 + ul {
  margin-top: 1em; }

h2 + h1 {
  margin-top: 1em; }

h2 + h2 {
  margin-top: 1em; }

h2 + h3 {
  margin-top: 1em; }

h2 + h4 {
  margin-top: 1em; }

h2 + h5 {
  margin-top: 1em; }

h3 + p {
  margin-top: 1em; }

h3 + ol {
  margin-top: 1em; }

h3 + ul {
  margin-top: 1em; }

h3 + h1 {
  margin-top: 1em; }

h3 + h2 {
  margin-top: 1em; }

h3 + h3 {
  margin-top: 1em; }

h3 + h4 {
  margin-top: 1em; }

h3 + h5 {
  margin-top: 1em; }

h4 + p {
  margin-top: 1em; }

h4 + ol {
  margin-top: 1em; }

h4 + ul {
  margin-top: 1em; }

h4 + h1 {
  margin-top: 1em; }

h4 + h2 {
  margin-top: 1em; }

h4 + h3 {
  margin-top: 1em; }

h4 + h4 {
  margin-top: 1em; }

h4 + h5 {
  margin-top: 1em; }

h5 + p {
  margin-top: 1em; }

h5 + ol {
  margin-top: 1em; }

h5 + ul {
  margin-top: 1em; }

h5 + h1 {
  margin-top: 1em; }

h5 + h2 {
  margin-top: 1em; }

h5 + h3 {
  margin-top: 1em; }

h5 + h4 {
  margin-top: 1em; }

h5 + h5 {
  margin-top: 1em; }

.content .component {
  margin-bottom: 40px; }
  .content .component:first-child {
    margin-top: 55px; }
  @media only screen and (min-width: 992px) {
    .content .component {
      margin-bottom: 60px; }
      .content .component:first-child {
        margin-top: 0; } }
  .content .component.empty {
    margin: 0; }
    .content .component.empty:first-child {
      margin-top: 0; }

.content .component.two-section-grid > .component-content,
.content .component.three-section-grid > .component-content,
.content .component.four-section-grid > .component-content {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .content .component.two-section-grid > .component-content,
    .content .component.three-section-grid > .component-content,
    .content .component.four-section-grid > .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .content .component.two-section-grid > .component-content.full-width,
      .content .component.three-section-grid > .component-content.full-width,
      .content .component.four-section-grid > .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .component.two-section-grid > .component-content.regular,
      .content .component.three-section-grid > .component-content.regular,
      .content .component.four-section-grid > .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .content .component.two-section-grid > .component-content.slim,
      .content .component.three-section-grid > .component-content.slim,
      .content .component.four-section-grid > .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid > .component-content,
    .content .component.three-section-grid > .component-content,
    .content .component.four-section-grid > .component-content {
      flex-direction: row; } }
  .content .component.two-section-grid > .component-content [class^="section-"] .component,
  .content .component.three-section-grid > .component-content [class^="section-"] .component,
  .content .component.four-section-grid > .component-content [class^="section-"] .component {
    width: 100%;
    margin-bottom: 0;
    box-sizing: border-box;
    min-height: 100%;
    padding: 20px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid > .component-content [class^="section-"] .component,
      .content .component.three-section-grid > .component-content [class^="section-"] .component,
      .content .component.four-section-grid > .component-content [class^="section-"] .component {
        padding: 30px; } }
    .content .component.two-section-grid > .component-content [class^="section-"] .component:first-child,
    .content .component.three-section-grid > .component-content [class^="section-"] .component:first-child,
    .content .component.four-section-grid > .component-content [class^="section-"] .component:first-child {
      margin-top: 0; }
  .content .component.two-section-grid > .component-content [class^="section-"] .component-content,
  .content .component.three-section-grid > .component-content [class^="section-"] .component-content,
  .content .component.four-section-grid > .component-content [class^="section-"] .component-content {
    width: 100%;
    max-width: none;
    margin-left: 0;
    margin-right: 0; }
    .content .component.two-section-grid > .component-content [class^="section-"] .component-content img,
    .content .component.three-section-grid > .component-content [class^="section-"] .component-content img,
    .content .component.four-section-grid > .component-content [class^="section-"] .component-content img {
      max-width: 100%;
      height: auto; }

.content .component.two-section-grid.middle-align-content [class^="section-"],
.content .component.three-section-grid.middle-align-content [class^="section-"],
.content .component.four-section-grid.middle-align-content [class^="section-"] {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .content .component.two-section-grid.middle-align-content [class^="section-"] .component,
  .content .component.three-section-grid.middle-align-content [class^="section-"] .component,
  .content .component.four-section-grid.middle-align-content [class^="section-"] .component {
    min-height: 0; }

.content .component.two-section-grid.with-element-padding > .component-content > [class^="section-"] > .component,
.content .component.three-section-grid.with-element-padding > .component-content > [class^="section-"] > .component,
.content .component.four-section-grid.with-element-padding > .component-content > [class^="section-"] > .component {
  padding: 1em; }

.content .component.two-section-grid.green-blue-gradient-bg,
.content .component.three-section-grid.green-blue-gradient-bg,
.content .component.four-section-grid.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0;
  padding: 20px 0; }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.green-blue-gradient-bg,
    .content .component.three-section-grid.green-blue-gradient-bg,
    .content .component.four-section-grid.green-blue-gradient-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.charcoal-blue-bg,
.content .component.three-section-grid.charcoal-blue-bg,
.content .component.four-section-grid.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0;
  padding: 20px 0; }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.charcoal-blue-bg,
    .content .component.three-section-grid.charcoal-blue-bg,
    .content .component.four-section-grid.charcoal-blue-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.warm-gray-bg,
.content .component.three-section-grid.warm-gray-bg,
.content .component.four-section-grid.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0;
  padding: 20px 0; }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.warm-gray-bg,
    .content .component.three-section-grid.warm-gray-bg,
    .content .component.four-section-grid.warm-gray-bg {
      padding: 30px 0; } }

@media only screen and (min-width: 992px) {
  .content .component.two-section-grid [class^="section-"] {
    width: calc(100%/2); }
  .content .component.two-section-grid.forty-sixty .section-one {
    width: 40%; }
  .content .component.two-section-grid.forty-sixty .section-two {
    width: 60%; }
  .content .component.two-section-grid.sixty-forty .section-one {
    width: 60%; }
  .content .component.two-section-grid.sixty-forty .section-two {
    width: 40%; } }

@media only screen and (min-width: 992px) {
  .content .component.three-section-grid [class^="section-"] {
    width: calc(100%/3); } }

@media only screen and (min-width: 992px) {
  .content .component.four-section-grid [class^="section-"] {
    width: calc(100%/4); } }

.icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.footer {
  background-color: #24405b;
  color: #ffffff;
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .footer {
      padding-top: 53px; } }
  @media only screen and (min-width: 768px) {
    .footer {
      padding-bottom: 30px; } }
  @media only screen and (min-width: 1200px) {
    .footer:after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      background-color: #24405b;
      position: absolute;
      bottom: -10px; } }
  .footer .two-section-grid {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      .footer .two-section-grid {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .footer .two-section-grid.full-width {
          width: 100%;
          max-width: 1600px; }
        .footer .two-section-grid.regular {
          width: 86%;
          max-width: 1200px; }
        .footer .two-section-grid.slim {
          width: 47.2%;
          max-width: 660px; } }
  .footer .three-section-grid > .component-content {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      .footer .three-section-grid > .component-content {
        flex-direction: row; } }
  @media only screen and (min-width: 992px) {
    .footer .three-section-grid .section-one,
    .footer .three-section-grid .section-two {
      width: 17.5%; } }
  .footer .three-section-grid .section-three {
    margin-top: 40px; }
    @media only screen and (min-width: 992px) {
      .footer .three-section-grid .section-three {
        margin-top: 0;
        width: 65%; } }

body.invert .footer {
  background-color: #dcdcd6;
  color: #24405b;
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative; }
  @media only screen and (min-width: 768px) {
    body.invert .footer {
      padding-top: 53px; } }
  @media only screen and (min-width: 768px) {
    body.invert .footer {
      padding-bottom: 30px; } }
  @media only screen and (min-width: 1200px) {
    body.invert .footer:after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      background-color: #dcdcd6;
      position: absolute;
      bottom: -10px; } }
  body.invert .footer .two-section-grid {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      body.invert .footer .two-section-grid {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        body.invert .footer .two-section-grid.full-width {
          width: 100%;
          max-width: 1600px; }
        body.invert .footer .two-section-grid.regular {
          width: 86%;
          max-width: 1200px; }
        body.invert .footer .two-section-grid.slim {
          width: 47.2%;
          max-width: 660px; } }
  body.invert .footer .three-section-grid > .component-content {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      body.invert .footer .three-section-grid > .component-content {
        flex-direction: row; } }
  @media only screen and (min-width: 992px) {
    body.invert .footer .three-section-grid .section-one,
    body.invert .footer .three-section-grid .section-two {
      width: 17.5%; } }
  body.invert .footer .three-section-grid .section-three {
    margin-top: 40px; }
    @media only screen and (min-width: 992px) {
      body.invert .footer .three-section-grid .section-three {
        margin-top: 0;
        width: 65%; } }

body#graduate-universe .footer {
  background-color: #74cecd;
  color: #24405b;
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative; }
  @media only screen and (min-width: 768px) {
    body#graduate-universe .footer {
      padding-top: 53px; } }
  @media only screen and (min-width: 768px) {
    body#graduate-universe .footer {
      padding-bottom: 30px; } }
  @media only screen and (min-width: 1200px) {
    body#graduate-universe .footer:after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      background-color: #74cecd;
      position: absolute;
      bottom: -10px; } }
  body#graduate-universe .footer .two-section-grid {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      body#graduate-universe .footer .two-section-grid {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        body#graduate-universe .footer .two-section-grid.full-width {
          width: 100%;
          max-width: 1600px; }
        body#graduate-universe .footer .two-section-grid.regular {
          width: 86%;
          max-width: 1200px; }
        body#graduate-universe .footer .two-section-grid.slim {
          width: 47.2%;
          max-width: 660px; } }
  body#graduate-universe .footer .three-section-grid > .component-content {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      body#graduate-universe .footer .three-section-grid > .component-content {
        flex-direction: row; } }
  @media only screen and (min-width: 992px) {
    body#graduate-universe .footer .three-section-grid .section-one,
    body#graduate-universe .footer .three-section-grid .section-two {
      width: 17.5%; } }
  body#graduate-universe .footer .three-section-grid .section-three {
    margin-top: 40px; }
    @media only screen and (min-width: 992px) {
      body#graduate-universe .footer .three-section-grid .section-three {
        margin-top: 0;
        width: 65%; } }

body#graduate-universe .footer .three-section-grid .section-three .link-list {
  margin-top: 33px; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list h3 {
    font-weight: normal;
    font-size: 12px; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list ul {
    margin-top: 10px; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list li + li {
    margin-left: 10px; }

body#graduate-universe .footer .three-section-grid .section-three .link-list a {
  width: 27px;
  height: 27px;
  overflow: hidden;
  display: inline-block;
  margin: 0; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a::before {
    margin-top: 0; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-twitter::before {
    background: url(asset-path("social-twitter-circle-charcoal-white.svg")) no-repeat; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-linkedin::before {
    background: url(asset-path("social-linkedin-circle-charcoal-white.svg")) no-repeat; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-facebook::before {
    background: url(asset-path("social-facebook-circle-charcoal-white.svg")) no-repeat;
    background-size: contain; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-youtube::before {
    background: url(asset-path("social-youtube-circle-charcoal-white.svg")) no-repeat;
    background-size: contain; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.header {
  position: fixed;
  z-index: 1000; }
  .header .search-container {
    position: fixed;
    top: 0;
    right: 55px;
    width: 55px;
    height: 55px; }
    @media only screen and (min-width: 992px) {
      .header .search-container {
        position: absolute;
        right: 0;
        background-color: #ffffff;
        width: 120px;
        height: 120px;
        transition: all 0.5s ease-out; } }
    .header .search-container a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 0;
      font-size: 0;
      color: transparent;
      text-align: center; }
      .header .search-container a:before {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        content: "search";
        color: #24405b;
        font-size: 30px;
        margin: 0 auto; }
        @media only screen and (min-width: 992px) {
          .header .search-container a:before {
            font-size: 45px; } }
  .header .search-field-spot {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 1; }
    .header .search-field-spot .container {
      margin: 20% auto;
      max-width: 700px;
      width: 80%; }
      @media only screen and (min-width: 992px) {
        .header .search-field-spot .container {
          width: 50%; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation: landscape) {
        .header .search-field-spot .container {
          position: fixed;
          top: 20vh;
          margin-top: 0;
          width: 100%;
          left: 50%;
          transform: translateX(-50%); } }
      .header .search-field-spot .container .search-field {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        padding: 8px;
        width: 100%;
        border-bottom: 1px solid #ffffff; }
        .header .search-field-spot .container .search-field:before {
          font-family: "Material Icons";
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          font-feature-settings: "liga";
          -webkit-font-feature-settings: "liga";
          -webkit-font-smoothing: antialiased;
          content: "search";
          color: #ffffff;
          font-size: 38px;
          margin-right: 8px; }
        .header .search-field-spot .container .search-field input[type="text"] {
          border: 0;
          font-size: 22px;
          flex-grow: 2;
          width: 100%;
          background-color: transparent;
          color: #ffffff; }
          @media only screen and (min-width: 992px) {
            .header .search-field-spot .container .search-field input[type="text"] {
              font-size: 30px; } }
        .header .search-field-spot .container .search-field input[type="text"]:focus {
          outline: none; }
        .header .search-field-spot .container .search-field input[type="text"]::placeholder {
          color: #f4f4f4; }
        .header .search-field-spot .container .search-field .search-icon {
          font-family: "Material Icons";
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          font-feature-settings: "liga";
          -webkit-font-feature-settings: "liga";
          -webkit-font-smoothing: antialiased;
          cursor: pointer; }
      .header .search-field-spot .container .search-suggestion-container {
        background-color: transparent;
        padding: 19px 8px 8px 8px;
        width: 100%; }
        .header .search-field-spot .container .search-suggestion-container .search-suggestion {
          cursor: pointer;
          color: #f4f4f4;
          font-size: 20px;
          font-weight: 700;
          padding: 5px 0; }
          .header .search-field-spot .container .search-suggestion-container .search-suggestion:hover {
            text-decoration: underline; }
          .header .search-field-spot .container .search-suggestion-container .search-suggestion:hover,
          .header .search-field-spot .container .search-suggestion-container .search-suggestion:hover p:before {
            color: #74cecd; }
          .header .search-field-spot .container .search-suggestion-container .search-suggestion p:before {
            font-family: "Material Icons";
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga";
            -webkit-font-feature-settings: "liga";
            -webkit-font-smoothing: antialiased;
            content: "keyboard_arrow_right";
            color: #ffffff;
            font-size: 32px;
            margin-right: 8px;
            vertical-align: middle; }
        .header .search-field-spot .container .search-suggestion-container > p {
          display: none; }

.header .mobile .static-logo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  height: 55px;
  transition: all 0.5s ease-out; }
  .header .mobile .static-logo img {
    padding: 15px 25px;
    height: 100%;
    width: auto; }
    @media only screen and (min-width: 768px) {
      .header .mobile .static-logo img {
        padding: 15px 25px; } }

.header .mobile .mobile-container li a:not(.language-selector__link) {
  padding-left: 3rem;
  position: relative; }
  .header .mobile .mobile-container li a:not(.language-selector__link):before {
    content: "";
    color: #74cecd;
    display: inline-block;
    border-style: none solid solid none;
    border-width: 3px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    left: 0; }

.header .mobile .mobile-container .action-menu li a {
  font-weight: normal; }

.header .mobile button {
  background: transparent;
  border: 0;
  padding: 0; }

.header .mobile .open {
  position: fixed;
  top: 0;
  right: 0;
  width: 55px;
  height: 55px;
  border-radius: 50%; }
  .header .mobile .open span {
    position: absolute;
    left: 18px;
    width: 20px;
    height: 3px;
    background-color: #24405b; }
    .header .mobile .open span:nth-child(1) {
      top: 17px; }
    .header .mobile .open span:nth-child(2) {
      top: 24px; }
    .header .mobile .open span:nth-child(3) {
      top: 31px; }
  .header .mobile .open.close span {
    background-color: #ffffff; }
    .header .mobile .open.close span:nth-child(1) {
      transform: rotate(-45deg);
      top: 29px; }
    .header .mobile .open.close span:nth-child(2) {
      opacity: 0; }
    .header .mobile .open.close span:nth-child(3) {
      transform: rotate(45deg);
      top: 29px; }

.header .mobile .mobile-container {
  background-color: #24405b;
  padding: 25px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(100%);
  transition: transform 0.3s; }
  @media only screen and (min-width: 768px) {
    .header .mobile .mobile-container {
      padding: 25px; } }
  .header .mobile .mobile-container.active {
    transform: none; }
  .header .mobile .mobile-container .logo {
    width: 150px; }
    @media only screen and (min-width: 768px) {
      .header .mobile .mobile-container .logo {
        width: 200px; } }
  .header .mobile .mobile-container .navigation-container {
    overflow: auto;
    scrollbar-width: thin;
    height: calc(100% - 130px);
    margin-top: 50px;
    transition: all 0.3s; }
    .header .mobile .mobile-container .navigation-container.animate-out {
      transform: translateX(-110%); }
  .header .mobile .mobile-container .action-menu {
    margin-top: 50px; }
  .header .mobile .mobile-container .sub-menu {
    background-color: #24405b;
    position: absolute;
    overflow: auto;
    scrollbar-width: thin;
    bottom: 120px;
    animation: animateMobileSubMenuIn 0.3s ease-out 0s 1 forwards; }
    .header .mobile .mobile-container .sub-menu.animate-out {
      animation: animateMobileSubMenuOut 0.3s ease-out 0s 1 forwards; }
  .header .mobile .mobile-container .sub-menu-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.33;
    letter-spacing: -0.6px;
    color: #74cecd;
    margin-bottom: 27px; }
    @media only screen and (min-width: 768px) {
      .header .mobile .mobile-container .sub-menu-heading {
        font-size: 30px; } }
  .header .mobile .mobile-container li + li {
    margin-top: 27px; }
  .header .mobile .mobile-container li ul {
    display: none; }
  .header .mobile .mobile-container li a {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.33;
    letter-spacing: -0.6px;
    color: #ffffff;
    text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .header .mobile .mobile-container li a {
        font-size: 30px; } }
  .header .mobile .mobile-container .actions {
    position: absolute;
    bottom: 15px;
    left: 25px;
    right: 25px;
    display: flex;
    align-items: center; }
    @media only screen and (min-width: 768px) {
      .header .mobile .mobile-container .actions {
        bottom: 50px;
        left: 50px; } }
  .header .mobile .mobile-container .search {
    flex-grow: 1;
    line-height: 0;
    font-size: 0;
    color: transparent; }
    .header .mobile .mobile-container .search:before {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "search";
      color: #ffffff;
      font-size: 33px; }
  .header .mobile .mobile-container .back {
    position: relative;
    display: none;
    align-items: center;
    opacity: 0;
    z-index: 3;
    padding-right: 0;
    transition: all 0.3s ease-out; }
    .header .mobile .mobile-container .back.animate-in {
      opacity: 1;
      padding-right: 80px; }
  .header .mobile .mobile-container .close {
    position: relative;
    z-index: 4;
    display: flex;
    margin-left: 17px; }

@keyframes animateMobileSubMenuIn {
  from {
    left: 100%;
    right: -100%; }
  to {
    left: 30px;
    right: 30px; } }

@keyframes animateMobileSubMenuOut {
  from {
    left: 30px;
    right: 30px; }
  to {
    left: 100%;
    right: -100%; } }

::selection {
  background: #edb833; }

.header.main .row {
  display: none; }

.header .oneline.desktop .search-container, .header .oneline.mobile .search-container {
  display: none; }

.header .desktop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  min-height: 120px;
  transition: all 0.5s ease-out; }
  .header .desktop ul {
    display: flex; }
  .header .desktop a {
    color: #ffffff;
    text-decoration: none; }
    .header .desktop a:hover, .header .desktop a.active {
      color: #74cecd; }
  .header .desktop li + li {
    margin-left: 45px; }
  .header .desktop .main-menu-container {
    background-color: #24405b;
    position: relative;
    min-height: 120px;
    box-shadow: 0 -9px 20px 9px #000000;
    z-index: 2;
    transition: all 0.5s ease-out; }
    .header .desktop .main-menu-container .logo-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 120px; }
      .header .desktop .main-menu-container .logo-container .logo {
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
        height: 120px;
        transition: all 0.5s ease-out; }
        @media only screen and (min-width: 768px) {
          .header .desktop .main-menu-container .logo-container .logo {
            margin-left: auto;
            margin-right: auto;
            width: 86%;
            max-width: 1200px; }
            .header .desktop .main-menu-container .logo-container .logo.full-width {
              width: 100%;
              max-width: 1600px; }
            .header .desktop .main-menu-container .logo-container .logo.regular {
              width: 86%;
              max-width: 1200px; }
            .header .desktop .main-menu-container .logo-container .logo.slim {
              width: 47.2%;
              max-width: 660px; } }
        .header .desktop .main-menu-container .logo-container .logo a {
          display: inline-block;
          position: absolute;
          width: 160px;
          height: 120px;
          left: 0;
          bottom: 0;
          z-index: 1; }
        .header .desktop .main-menu-container .logo-container .logo img {
          position: absolute;
          width: 115px;
          left: 0;
          top: 23px;
          transition: all 0.5s ease-out; }
          @media only screen and (min-width: 1200px) {
            .header .desktop .main-menu-container .logo-container .logo img {
              width: 160px;
              top: auto;
              bottom: 23px; } }
    .header .desktop .main-menu-container .main-menu,
    .header .desktop .main-menu-container .action-menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 150px;
      display: inline-block;
      transition: all 0.5s ease-out; }
      .header .desktop .main-menu-container .main-menu ul,
      .header .desktop .main-menu-container .action-menu ul {
        position: absolute;
        right: 0; }
      .header .desktop .main-menu-container .main-menu a,
      .header .desktop .main-menu-container .action-menu a {
        font-weight: 600;
        font-size: 12px;
        line-height: 1.4;
        line-height: 1;
        display: inline-block;
        font-weight: normal; }
        @media only screen and (min-width: 768px) {
          .header .desktop .main-menu-container .main-menu a,
          .header .desktop .main-menu-container .action-menu a {
            font-size: 16px; } }
    .header .desktop .main-menu-container .action-menu ul {
      top: 30px; }
      @media only screen and (min-width: 1200px) {
        .header .desktop .main-menu-container .action-menu ul a {
          font-size: 18px; } }
    .header .desktop .main-menu-container .main-menu {
      height: 120px; }
      .header .desktop .main-menu-container .main-menu ul {
        bottom: 18px; }
      .header .desktop .main-menu-container .main-menu li.level1 ul {
        display: none; }
      .header .desktop .main-menu-container .main-menu a {
        font-size: 20px; }
        @media only screen and (min-width: 992px) {
          .header .desktop .main-menu-container .main-menu a {
            font-size: 16px;
            font-weight: 700; } }
  @media only screen and (min-width: 992px) and (-ms-high-contrast: active), only screen and (min-width: 992px) and (-ms-high-contrast: none) {
    .header .desktop .main-menu-container .main-menu a {
      font-weight: normal; } }
        @media only screen and (min-width: 1200px) {
          .header .desktop .main-menu-container .main-menu a {
            font-size: 20px; } }
  .header .desktop .sub-menu {
    background-color: #24405b;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    min-height: 80px;
    display: flex;
    align-items: center;
    animation: animateSubMenuIn 0.3s ease-out 0s 1 forwards;
    box-shadow: 0 -9px 20px 9px #000000; }
    .header .desktop .sub-menu ul {
      margin: auto; }
      @media all and (-ms-high-contrast: none) {
        .header .desktop .sub-menu ul {
          margin-top: 2em; } }
    .header .desktop .sub-menu a {
      font-weight: 600;
      font-size: 12px;
      line-height: 1.4;
      font-size: 20px;
      line-height: 1;
      display: inline-block; }
      @media only screen and (min-width: 768px) {
        .header .desktop .sub-menu a {
          font-size: 16px; } }
    .header .desktop .sub-menu.animate-out {
      animation: animateSubMenuOut 0.3s ease-out 0s 1 forwards; }
  .header .desktop.oneline .main-menu-container {
    min-height: 111px; }
    .header .desktop.oneline .main-menu-container .logo-container,
    .header .desktop.oneline .main-menu-container .logo-container .logo {
      height: 111px; }
      @media only screen and (min-width: 992px) {
        .header .desktop.oneline .main-menu-container .logo-container img,
        .header .desktop.oneline .main-menu-container .logo-container .logo img {
          top: 56px; } }
      @media only screen and (min-width: 1200px) {
        .header .desktop.oneline .main-menu-container .logo-container img,
        .header .desktop.oneline .main-menu-container .logo-container .logo img {
          top: 50px;
          bottom: 38px; } }
    @media only screen and (min-width: 1400px) {
      .header .desktop.oneline .main-menu-container .logo-container .logo {
        width: 82rem;
        max-width: 80%; } }
    .header .desktop.oneline .main-menu-container .main-menu {
      height: 111px; }
    .header .desktop.oneline .main-menu-container .action-menu ul {
      top: 54px;
      right: 0; }
      @media only screen and (min-width: 1200px) {
        .header .desktop.oneline .main-menu-container .action-menu ul {
          right: 66px; } }
      @media only screen and (min-width: 1400px) {
        .header .desktop.oneline .main-menu-container .action-menu ul {
          right: calc((100% - 82rem )/2 + 66px); } }
      .header .desktop.oneline .main-menu-container .action-menu ul a {
        font-weight: 600; }

.header .scrolling-down .desktop {
  min-height: 70px; }
  .header .scrolling-down .desktop.oneline {
    min-height: 70px; }
    .header .scrolling-down .desktop.oneline .main-menu-container .main-menu {
      height: 70px; }
    .header .scrolling-down .desktop.oneline .main-menu-container .logo-container,
    .header .scrolling-down .desktop.oneline .main-menu-container .logo-container .logo {
      height: 70px; }
      .header .scrolling-down .desktop.oneline .main-menu-container .logo-container img,
      .header .scrolling-down .desktop.oneline .main-menu-container .logo-container .logo img {
        transform: translateX(-30px) translateY(19px) scale(0.625); }
    .header .scrolling-down .desktop.oneline .action-menu {
      top: -27px; }
    .header .scrolling-down .desktop.oneline .language-selector {
      top: 29px; }
  .header .scrolling-down .desktop .main-menu-container {
    min-height: 70px; }
  .header .scrolling-down .desktop .action-menu {
    top: -60px; }
  .header .scrolling-down .desktop .language-selector {
    top: -30px; }
  .header .scrolling-down .desktop .main-menu {
    height: 70px; }
  .header .scrolling-down .desktop .search-container {
    height: 70px; }
  .header .scrolling-down .desktop .logo-container,
  .header .scrolling-down .desktop .logo-container .logo {
    height: 70px; }
    .header .scrolling-down .desktop .logo-container img,
    .header .scrolling-down .desktop .logo-container .logo img {
      transform: translateX(-30px) translateY(7px) scale(0.625); }
  .header .scrolling-down .desktop .sub-menu {
    animation: animateSubMenuInScrolled 0.3s ease-out 0s 1 forwards; }
    .header .scrolling-down .desktop .sub-menu.animate-out {
      animation: animateSubMenuOutScrolled 0.3s ease-out 0s 1 forwards; }

@media only screen and (min-width: 992px) {
  .content.standard-header {
    padding-top: 120px; }
  .content.online-header {
    padding-top: 111px; } }

@keyframes animateSubMenuIn {
  from {
    padding-top: 0; }
  to {
    padding-top: 120px; } }

@keyframes animateSubMenuOut {
  from {
    padding-top: 120px; }
  to {
    padding-top: 0; } }

@keyframes animateSubMenuInScrolled {
  from {
    padding-top: 0; }
  to {
    padding-top: 70px; } }

@keyframes animateSubMenuOutScrolled {
  from {
    padding-top: 70px; }
  to {
    padding-top: 0; } }

::selection {
  background: #edb833; }

div[class*=" circle-"] svg,
div[class*=" circle-"] .svg {
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%; }
  @media only screen and (min-width: 992px) {
    div[class*=" circle-"] svg,
    div[class*=" circle-"] .svg {
      animation-name: rotate; } }
  div[class*=" circle-"] svg ellipse,
  div[class*=" circle-"] .svg ellipse {
    fill: transparent; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.circle-charcoal-blue ellipse {
  fill-rule: evenodd; }
  .circle-charcoal-blue ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-charcoal-blue ellipse:nth-child(2) {
    fill: #5b7085;
    mix-blend-mode: normal; }
  .circle-charcoal-blue ellipse:nth-child(3) {
    fill: rgba(36, 64, 91, 0.5);
    mix-blend-mode: multiply; }

.circle-charcoal-blue .svg {
  background-image: url(asset_path("circle-charcoal-blue.png"));
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-charcoal-blue .svg {
  background-image: url("/design/build/demant-szczecin/assets/circle-charcoal-blue.png"); }

.circle-electric-seafoam ellipse {
  fill-rule: evenodd; }
  .circle-electric-seafoam ellipse:nth-child(1) {
    fill: black; }
  .circle-electric-seafoam ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-electric-seafoam ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-electric-seafoam .svg {
  background-image: url(asset_path("circle-electric-seafoam.png"));
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-electric-seafoam .svg {
  background-image: url("/design/build/demant-szczecin/assets/circle-electric-seafoam.png"); }

.circle-seafoam-green ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-green ellipse:nth-child(1) {
    fill: black; }
  .circle-seafoam-green ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-seafoam-green ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-seafoam-green .svg {
  background-image: url(asset_path("circle-seafoam-green.png"));
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-green .svg {
  background-image: url("/design/build/demant-szczecin/assets/circle-seafoam-green.png"); }

.circle-seafoam-gray ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-gray ellipse:nth-child(1) {
    fill: black; }
  .circle-seafoam-gray ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-seafoam-gray ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-seafoam-gray .svg {
  background-image: url(asset_path("circle-seafoam-gray.png"));
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-gray .svg {
  background-image: url("/design/build/demant-szczecin/assets/circle-seafoam-gray.png"); }

.circle-white ellipse {
  fill-rule: evenodd; }
  .circle-white ellipse:nth-child(1) {
    fill: rgba(255, 255, 255, 0.75); }
  .circle-white ellipse:nth-child(2) {
    fill: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal; }
  .circle-white ellipse:nth-child(3) {
    fill: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal; }

.circle-white .svg {
  background-image: url(asset_path("circle-white.png"));
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-white .svg {
  background-image: url("/design/build/demant-szczecin/assets/circle-white.png"); }

.content .component.two-section-grid > .component-content [class^="section-"] .component.image-spot {
  width: auto;
  margin-left: auto;
  margin-right: auto; }
  @media all and (-ms-high-contrast: none) {
    .content .component.two-section-grid > .component-content [class^="section-"] .component.image-spot .component-content .image-spot-element {
      margin: auto; } }

.language-selector {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .desktop .language-selector {
    top: 34px;
    right: 150px;
    transition: all 0.5s ease-out;
    font-size: 12px;
    line-height: 1; }
    @media only screen and (min-width: 768px) {
      .desktop .language-selector {
        font-size: 16px; } }
    @media only screen and (min-width: 1200px) {
      .desktop .language-selector {
        top: 32px;
        font-size: 18px; } }
  .oneline.desktop .language-selector {
    right: 10%; }
    @media only screen and (min-width: 1200px) {
      .oneline.desktop .language-selector {
        top: 56px; } }
    @media only screen and (min-width: 992px) {
      .oneline.desktop .language-selector__list {
        padding-right: 0;
        flex-direction: row; }
        .oneline.desktop .language-selector__list:after {
          display: none; }
      .oneline.desktop .language-selector__item {
        display: inline-block;
        width: auto; }
        .oneline.desktop .language-selector__item.is-active a {
          color: #74cecd; }
        .oneline.desktop .language-selector__item:last-child {
          order: -1;
          position: relative;
          padding-right: 16px; } }
    @media only screen and (min-width: 992px) and (min-width: 992px) {
      .oneline.desktop .language-selector__item:last-child:before {
        display: block;
        position: absolute;
        right: 2px;
        top: -1px;
        font-size: 19px; } }
    @media only screen and (min-width: 992px) {
      .oneline.desktop .language-selector__link {
        font-weight: 600; } }
    @media only screen and (min-width: 1400px) {
      .oneline.desktop .language-selector {
        right: calc((100% - 82rem)/2); } }
  .mobile .language-selector {
    top: 34px;
    right: 55px; }
  .language-selector__list {
    display: flex;
    position: relative; }
    @media only screen and (min-width: 992px) {
      .language-selector__list {
        flex-direction: column;
        padding-right: 1.5rem; }
        .ie .language-selector__list {
          cursor: pointer; }
        .language-selector__list:after {
          content: "";
          display: inline-block;
          box-sizing: border-box;
          border-style: none solid solid none;
          width: 12px;
          height: 12px;
          transform: rotate(45deg);
          color: #ffffff;
          position: absolute;
          top: -1px;
          right: 0;
          cursor: pointer;
          transition: all 0.2s; }
          .ie .language-selector__list:after {
            pointer-events: auto; }
        .language-selector__list.is-opened:after {
          transform: rotate(-135deg);
          top: 5px; } }
  .language-selector__item {
    margin: 0 0.25rem !important; }
    @media only screen and (min-width: 992px) {
      .language-selector__item {
        margin: 0 !important;
        display: none;
        width: 23px; }
        .is-opened .language-selector__item {
          display: block;
          margin-bottom: 0.25rem !important; }
        .language-selector__item.is-active {
          display: block; } }
    .language-selector__item:last-child {
      margin: 0 !important; }
      .language-selector__item:last-child:before {
        content: "/";
        margin-right: 0.25rem;
        display: inline-block;
        color: #ffffff;
        font-size: 20px;
        line-height: 1; }
        @media only screen and (min-width: 768px) {
          .language-selector__item:last-child:before {
            font-size: 30px; } }
        @media only screen and (min-width: 992px) {
          .language-selector__item:last-child:before {
            display: none; } }
  .language-selector__link {
    font-weight: normal; }
    .language-selector__link:hover {
      color: #74cecd; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
#social-sharing .mobile-section {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    #social-sharing .mobile-section {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      #social-sharing .mobile-section.full-width {
        width: 100%;
        max-width: 1600px; }
      #social-sharing .mobile-section.regular {
        width: 86%;
        max-width: 1200px; }
      #social-sharing .mobile-section.slim {
        width: 47.2%;
        max-width: 660px; } }

#social-sharing .desktop-section {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    #social-sharing .desktop-section {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      #social-sharing .desktop-section.full-width {
        width: 100%;
        max-width: 1600px; }
      #social-sharing .desktop-section.regular {
        width: 86%;
        max-width: 1200px; }
      #social-sharing .desktop-section.slim {
        width: 47.2%;
        max-width: 660px; } }

#social-sharing .service-elements-header {
  display: block;
  font-size: 22px;
  line-height: 1.35; }
  @media only screen and (min-width: 768px) {
    #social-sharing .service-elements-header {
      font-size: 26px; } }
  @media only screen and (min-width: 1200px) {
    #social-sharing .service-elements-header {
      font-size: 30px; } }

#social-sharing .service-element a {
  display: inline-block;
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0);
  margin-top: 20px;
  margin-right: 10px; }

#social-sharing .Facebook::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("/design/build/demant-szczecin/assets/social-facebook-circle-charcoal.svg");
  background-size: contain; }

#social-sharing .Twitter::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("/design/build/demant-szczecin/assets/social-twitter-circle-charcoal.svg");
  background-size: contain; }

#social-sharing .Linkedin::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("/design/build/demant-szczecin/assets/social-linked-circle-charcoal.svg");
  background-size: contain; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

#footer {
  color: #badcdf;
  font-size: 14px;
  line-height: 20px; }
  #footer a, #footer p {
    font-weight: 300; }
  #footer a {
    color: inherit;
    text-decoration: none; }
    @media (hover: none) {
      #footer a[href^="tel:"] {
        text-decoration: underline; } }
  #footer h4, #footer .like-h4 {
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: inherit; }
    @media only screen and (min-width: 768px) {
      #footer h4, #footer .like-h4 {
        margin-bottom: 1.4rem;
        font-size: 18px; } }
  @media only screen and (min-width: 1400px) {
    #footer .component .component-content {
      max-width: 1200px; } }
  @media only screen and (min-width: 1400px) {
    #footer .component.landing-page .component-content {
      max-width: 80%; } }
  #footer .component.landing-page .component-content .lower {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      #footer .component.landing-page .component-content .lower {
        flex-direction: row; } }
    #footer .component.landing-page .component-content .lower li:first-of-type {
      flex-basis: auto; }
      @media only screen and (min-width: 992px) {
        #footer .component.landing-page .component-content .lower li:first-of-type {
          flex-basis: 160px; } }
    #footer .component.landing-page .component-content .lower li:nth-of-type(2) {
      order: 2; }
      @media only screen and (min-width: 992px) {
        #footer .component.landing-page .component-content .lower li:nth-of-type(2) {
          order: 0; } }
    @media only screen and (min-width: 992px) {
      #footer .component.landing-page .component-content .lower li:nth-of-type(3) {
        margin-left: auto; } }
  #footer .component.rich-text-list .component-content > ul {
    margin-bottom: 0; }
    @media only screen and (min-width: 768px) {
      #footer .component.rich-text-list .component-content > ul {
        margin-bottom: 2rem; } }
  #footer .component.rich-text-list .component-content > ul > li ul li {
    padding-bottom: .7rem;
    max-width: 250px;
    border-bottom: none; }
    @media only screen and (min-width: 768px) {
      #footer .component.rich-text-list .component-content > ul > li ul li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1); } }
  #footer .component:last-child ul {
    margin-top: 2rem; }
    @media only screen and (min-width: 992px) {
      #footer .component:last-child ul {
        display: flex;
        align-items: center; } }
    @media only screen and (max-width: 767px) {
      #footer .component:last-child ul li:first-child {
        display: none; } }
    @media only screen and (min-width: 768px) {
      #footer .component:last-child ul li:first-child {
        padding-bottom: 10px; } }
    @media only screen and (min-width: 992px) {
      #footer .component:last-child ul li:first-child {
        flex-basis: 33.3333%;
        padding-bottom: 0; } }
    #footer .component:last-child ul li:first-child img {
      width: 115px;
      height: 30px; }
      @media only screen and (min-width: 1200px) {
        #footer .component:last-child ul li:first-child img {
          width: 161px;
          height: 42px; } }
    #footer .component:last-child ul.lower {
      margin-top: 0; }
      #footer .component:last-child ul.lower li:first-child {
        flex-basis: 160px; }
        #footer .component:last-child ul.lower li:first-child img {
          width: 105px;
          height: 30px; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#footer .component-google-map {
  display: none;
  padding-bottom: 0;
  padding-top: 0;
  color: #24405b; }
  @media only screen and (min-width: 1200px) {
    #footer .component-google-map {
      display: block; } }
  #footer .component-google-map .component-content {
    transition: height .3s;
    height: 0;
    max-width: 100%;
    width: 100%; }
    #footer .component-google-map .component-content .gm-style {
      font-family: "Muli", sans-serif; }
  #footer .component-google-map #map-canvas {
    height: inherit; }
  #footer .component-google-map.is-opened .component-content {
    height: 250px; }
  @media only screen and (min-width: 992px) {
    #footer .component-google-map.is-opened .button-google-map {
      top: 0; } }
  #footer .component-google-map.is-opened .button-google-map:before {
    content: '\e901';
    font-size: 1.5rem;
    line-height: 1.4; }

#footer .button-google-map {
  border: none;
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 13%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 0;
  cursor: pointer; }
  #footer .button-google-map:before {
    font-family: "icomoon";
    content: '\e900';
    color: #24405b;
    font-size: 2rem;
    line-height: 1.2; }
  @media only screen and (min-width: 992px) {
    #footer .button-google-map {
      top: 40px;
      right: 0; } }
  @media only screen and (min-width: 1200px) {
    #footer .button-google-map {
      right: calc(((100% - 82rem)/2) + 65px); } }
  @media only screen and (min-width: 1400px) {
    #footer .button-google-map {
      right: calc(((100% - 1200px)/2) - 30px); } }

#footer .component.center-locator .map-nav-container {
  display: none; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.lg-autoplay-button {
  display: none; }

.lg-toolbar .lg-close:after {
  font-family: "icomoon";
  content: '\e901';
  color: #de3f19;
  font-size: 1.25rem; }

.lg-actions .lg-prev, .lg-actions .lg-next {
  margin-top: 0;
  transform: translateY(-50%); }

.lg-actions .lg-prev:after,
.lg-actions .lg-next:before {
  font-family: "icomoon";
  color: #FFFFFF;
  font-size: 2rem; }

.lg-actions .lg-prev:after {
  content: '\e904'; }

.lg-actions .lg-next:before {
  content: '\e905'; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#content .component.navigation.submenu-list-links .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto;
  padding-bottom: 1rem; }

#content .component.navigation.submenu-list-links .mobile-nav {
  text-align: right;
  font-weight: bold; }
  #content .component.navigation.submenu-list-links .mobile-nav .mobile-nav-inner {
    display: inline-block;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    padding-right: 30px; }
    #content .component.navigation.submenu-list-links .mobile-nav .mobile-nav-inner:before {
      position: absolute;
      right: 0;
      display: block;
      content: "";
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      color: #74cecd;
      font-size: 20px;
      line-height: 1; }
    #content .component.navigation.submenu-list-links .mobile-nav .mobile-nav-inner.is-open:before {
      content: "close";
      font-size: 12px;
      margin-top: 3px;
      font-weight: normal; }

#content .component.navigation.submenu-list-links ul {
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 2rem 0;
  display: none; }
  #content .component.navigation.submenu-list-links ul.is-active {
    display: flex; }
  #content .component.navigation.submenu-list-links ul li {
    margin: 0 0 15px 0;
    padding: 0 1rem;
    text-indent: 0; }
    #content .component.navigation.submenu-list-links ul li a {
      text-decoration: none;
      background-color: #f7f7f7;
      color: inherit;
      border-radius: 15px;
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 5px 15px;
      text-transform: uppercase;
      line-height: 1.4;
      font-family: "Muli", Arial, sans-serif;
      font-weight: normal;
      font-size: 14px;
      transition: all 0.3s ease-in-out; }
      @media only screen and (max-width: 767px) {
        #content .component.navigation.submenu-list-links ul li a {
          font-size: 11px; } }
      #content .component.navigation.submenu-list-links ul li a.is-active {
        color: #3344f6;
        font-weight: bold; }
      @media only screen and (min-width: 1200px) {
        #content .component.navigation.submenu-list-links ul li a:hover {
          color: #3344f6; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.coi-banner__policy {
  text-decoration: underline; }

.coi-checkboxes label {
  -webkit-tap-highlight-color: transparent; }

body .component.cookie-warning {
  background-color: #000000;
  top: 0;
  bottom: auto; }
  body .component.cookie-warning.initialized {
    animation: cookieBarTop 2s; }
  body .component.cookie-warning .component-content {
    color: #FFFFFF;
    font-family: "Muli", Arial, sans-serif;
    font-weight: normal;
    position: relative;
    width: 82rem;
    max-width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      body .component.cookie-warning .component-content {
        padding-top: 1rem; } }
    @media only screen and (max-width: 767px) {
      body .component.cookie-warning .component-content {
        padding-bottom: 1rem; } }
    body .component.cookie-warning .component-content .submit {
      top: 50%;
      margin-top: -1rem; }
      body .component.cookie-warning .component-content .submit::after {
        font-family: "icomoon";
        content: '\e901';
        color: #de3f19;
        font-size: 1.25rem; }
    body .component.cookie-warning .component-content .info {
      padding: 0 2.5rem 0 0;
      font-size: 1rem;
      width: 100%; }

@keyframes cookieBarBottom {
  from {
    bottom: -300px; }
  to {
    bottom: 0; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

#content .image-spot .image-spot-elements + .clearfix {
  clear: both; }

#content .image-spot .like-button-minimal {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  border-radius: 50%;
  height: 42px;
  line-height: 42px;
  padding: 0;
  text-align: center;
  width: 42px; }
  @media only screen and (min-width: 1200px) {
    #content .image-spot .like-button-minimal {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    #content .image-spot .like-button-minimal:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      #content .image-spot .like-button-minimal:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 1200px) {
      .ie #content .image-spot .like-button-minimal:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .ie #content .image-spot .like-button-minimal:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; } }
  @media only screen and (max-width: 767px) {
    #content .image-spot .like-button-minimal {
      height: 32px;
      width: 32px;
      line-height: 32px; } }

#content .image-spot .slick-arrow {
  cursor: pointer; }

#content .image-spot.icons-with-text .component-content .image-spot-elements {
  /* one item */
  /* two items */
  /* three items */
  /* four items */
  /* five items */
  /* six items */
  /* seven items */
  /* eight items */
  /* nine items */
  /* ten items */ }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(2),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(3),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(4),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(5),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(6),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(7),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(8),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(9),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
    width: 100%; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(10),
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.icons-with-text .component-content .image-spot-elements {
      /* one item */
      /* two items */
      /* three items */
      /* four items */
      /* five items */
      /* six items */
      /* seven items */
      /* eight items */
      /* nine items */
      /* ten items */ }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(2),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(3),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(4),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(5),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(6),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(7),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(8),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(9),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
        width: 33.333%; }
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(10),
      #content .image-spot.icons-with-text .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
        width: 33.333%; } }
  #content .image-spot.icons-with-text .component-content .image-spot-elements > li {
    float: left; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements .image-spot-element-image {
    padding: 0 3rem !important; }
  #content .image-spot.icons-with-text .component-content .image-spot-elements .image-spot-element-header {
    font-size: 1.6rem; }

#content .image-spot.hr-people-list .component-content .image-spot-elements {
  text-align: left;
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (min-width: 992px) {
    #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 4rem; } }
  @media only screen and (min-width: 480px) {
    #content .image-spot.hr-people-list .component-content .image-spot-elements > li {
      flex-basis: 50%; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.hr-people-list .component-content .image-spot-elements > li {
      flex-basis: 25%; } }
  #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-image {
    padding: 0 2rem !important; }
    @media only screen and (max-width: 767px) {
      #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-image {
        padding: 0 3rem !important; } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-image {
        text-align: center; } }
    #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-image img {
      width: 250px; }
      @media only screen and (min-width: 992px) {
        #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-image img {
          width: 178px; } }
  #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-header {
    border-bottom: 2px solid #979797;
    margin-bottom: 12px;
    padding-bottom: 6px; }
    #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-header h3 {
      font-size: 15px; }
      @media only screen and (min-width: 992px) {
        #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-header h3 {
          font-size: 17px; } }
  #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-text .contact-mail-1 {
      display: flex;
      align-items: center;
      text-decoration: none;
      margin-top: 30px; }
      #content .image-spot.hr-people-list .component-content .image-spot-elements .image-spot-element-text .contact-mail-1:before {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        content: 'email';
        padding-right: .2em; }

#content .image-spot.hr-people-list .component-content .contact-phone-icon {
  font-size: 1.2rem;
  position: relative;
  font-weight: normal;
  padding-left: 1em;
  width: auto;
  display: inline-block;
  clear: both; }
  #content .image-spot.hr-people-list .component-content .contact-phone-icon:before {
    font-family: "icomoon";
    position: absolute;
    left: 0;
    top: .2em;
    font-size: .75em;
    content: ""; }

#content .image-spot.carousel-spot-mobile .image-spot-element-text p {
  font-size: 1.2rem;
  font-family: "Muli", Arial, sans-serif;
  line-height: 1.4; }

#content .image-spot.carousel-spot-mobile .slick-dots {
  margin-bottom: 1rem; }
  #content .image-spot.carousel-spot-mobile .slick-dots li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin: 0 .5rem; }
  #content .image-spot.carousel-spot-mobile .slick-dots button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: relative; }
    #content .image-spot.carousel-spot-mobile .slick-dots button:before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      content: '';
      border-radius: 50%;
      background-color: #74cecd; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.carousel-spot-mobile .slick-dots button:before {
          width: 15px;
          height: 15px; } }
  #content .image-spot.carousel-spot-mobile .slick-dots .slick-active button:before {
    background-color: #3344f6; }

#content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element {
  position: relative; }
  #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-text, #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
    position: absolute;
    bottom: 30px;
    right: 25%; }
    @media only screen and (min-width: 992px) {
      #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-text, #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-link {
        right: 15%; } }
    @media only screen and (max-width: 767px) {
      #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-text .like-button-minimal, #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-link .like-button-minimal {
        width: 30px;
        height: 30px;
        line-height: 31px; } }
  #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
    padding: 0 20%; }
    @media only screen and (min-width: 992px) {
      #content .image-spot.carousel-spot-mobile:not(.with-btn-video):not(.element-as-background) .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
        padding: 0 10%; } }

@media only screen and (min-width: 992px) and (max-width: 1023px) {
  #content .image-spot.carousel-spot-mobile.with-btn-video .image-spot-elements .image-spot-element {
    margin-bottom: 2rem; } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #content .image-spot.carousel-spot-mobile.with-btn-video .image-spot-element-image img {
    max-width: 300px; } }

@media only screen and (min-width: 1024px) and (max-width: 1199px) and (orientation: landscape) {
  #content .image-spot.carousel-spot-mobile.with-btn-video .image-spot-element-text p {
    font-size: 1.0rem; } }

#content .image-spot.carousel-spot-mobile-tablet .image-spot-element-text p {
  font-size: 1rem;
  font-family: "Muli", Arial, sans-serif;
  line-height: 1.4; }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.carousel-spot-mobile-tablet .image-spot-element-text p {
      font-size: 1.2rem; } }

#content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements {
  /* one item */
  /* two items */
  /* three items */
  /* four items */
  /* five items */
  /* six items */
  /* seven items */
  /* eight items */
  /* nine items */
  /* ten items */ }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements .image-spot-element-image {
    padding: 0 15%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(2),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(3),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(4),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(5),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(6),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(7),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(8),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(9),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
    width: 50%; }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(10),
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
    width: 50%; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements {
      /* one item */
      /* two items */
      /* three items */
      /* four items */
      /* five items */
      /* six items */
      /* seven items */
      /* eight items */
      /* nine items */
      /* ten items */ }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(2),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(3),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(4),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(5),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(6),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(7),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(8),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(9),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
        width: 25%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(10),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
        width: 25%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements {
      /* one item */
      /* two items */
      /* three items */
      /* four items */
      /* five items */
      /* six items */
      /* seven items */
      /* eight items */
      /* nine items */
      /* ten items */ }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(1) {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(2),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(3),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(4),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(5),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(6),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(7),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(8),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(9),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
        width: 50%; }
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(10),
      #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
        width: 50%; } }

#content .image-spot.carousel-spot-mobile-tablet.with-btn-video .image-spot-element {
  position: relative;
  float: left; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .image-spot-element:last-of-type, #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .image-spot-element:nth-last-of-type(2) {
      margin-bottom: 0; } }

#content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements .image-spot-element-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5rem;
  margin-left: -1.5rem; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements .image-spot-element-text {
      margin-top: -16px;
      margin-left: -16px; } }
  #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements .image-spot-element-text .icon-play {
    font-size: 3rem;
    color: #3344f6;
    text-decoration: none; }
    #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements .image-spot-element-text .icon-play:before {
      z-index: 2;
      position: relative; }
    #content .image-spot.carousel-spot-mobile-tablet.with-btn-video .component-content .image-spot-elements .image-spot-element-text .icon-play:after {
      content: '';
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      width: 38px;
      display: block;
      height: 38px;
      position: absolute;
      top: 1px;
      left: 1px; }

#content .image-spot.carousel-spot-mobile-tablet .slick-dots {
  margin-bottom: 1rem; }
  #content .image-spot.carousel-spot-mobile-tablet .slick-dots li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin: 0 .5rem; }
  #content .image-spot.carousel-spot-mobile-tablet .slick-dots button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: relative; }
    #content .image-spot.carousel-spot-mobile-tablet .slick-dots button:before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      content: '';
      border-radius: 50%;
      background-color: #74cecd; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.carousel-spot-mobile-tablet .slick-dots button:before {
          width: 15px;
          height: 15px; } }
  #content .image-spot.carousel-spot-mobile-tablet .slick-dots .slick-active button:before {
    background-color: #3344f6; }

#content .image-spot.icons-with-hover-txt .image-spot-elements {
  /* one item */
  /* two items */
  /* three items */
  /* four items */
  /* five items */
  /* six items */
  /* seven items */
  /* eight items */
  /* nine items */
  /* ten items */
  display: flex;
  flex-wrap: wrap; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(1) {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(2),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(3),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(4),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(5),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(6),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(7),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(8),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(9),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
    width: 50%; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(10),
  #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
    width: 50%; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.icons-with-hover-txt .image-spot-elements {
      /* one item */
      /* two items */
      /* three items */
      /* four items */
      /* five items */
      /* six items */
      /* seven items */
      /* eight items */
      /* nine items */
      /* ten items */ }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(1) {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(2),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(3),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(4),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(5),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(6),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(7),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(8),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(9),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
        width: 25%; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(10),
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
        width: 25%; } }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.icons-with-hover-txt .image-spot-elements {
      padding: 0 2rem;
      justify-content: center; }
      #content .image-spot.icons-with-hover-txt .image-spot-elements > li {
        max-width: 279px; } }
  #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element {
    padding: 0 1.0rem;
    margin-bottom: 0;
    position: relative; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element {
        padding: 0 1.5rem;
        margin-bottom: 1.5rem; } }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element:before {
      visibility: hidden;
      z-index: -1;
      background-color: transparent;
      transition: all 0.3s linear;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0); }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active:before {
      background-color: #00cfcf;
      visibility: visible;
      border-radius: 25px;
      z-index: 1; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active:after {
        content: '';
        display: block;
        height: 2rem; } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active:after {
        display: none; } }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-text,
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-image {
      position: relative;
      z-index: 2; }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-header {
      display: none; }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-text {
      display: block;
      margin-bottom: 2rem;
      max-width: 100%; }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-image {
      background-color: transparent;
      cursor: default;
      border-radius: 0; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-image {
          margin-top: 0.3rem;
          padding: 4rem 0; } }
      @media only screen and (min-width: 992px) {
        #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-image {
          margin-top: 1.5rem; } }
      @media only screen and (min-width: 1200px) {
        #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-image {
          margin-top: 0;
          padding: 6rem 0; } }
      #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-image > img {
        transform: translateX(-50%) translateY(-50%) scale(0.75); }
        @media only screen and (min-width: 1200px) {
          #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element.is-active .image-spot-element-image > img {
            transform: translateX(-50%) translateY(-50%) scale(0.5); } }
  #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-image {
    background-color: #05CDCD;
    border-radius: 50%;
    height: 0;
    width: 100%;
    padding: 50% 0;
    margin: 1rem auto;
    display: block;
    overflow: hidden;
    position: relative;
    cursor: pointer; }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-image > * {
      line-height: 0; }
    #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-image > img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 110px;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out; }
      @media only screen and (min-width: 992px) {
        #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-image > img {
          width: 230px; } }
  #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-text {
    display: none; }
  #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-header {
    margin-bottom: 1.5rem;
    font-weight: 300; }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-header p {
        font-size: 18px; } }
  #content .image-spot.icons-with-hover-txt .image-spot-elements .image-spot-element-header, #content .image-spot.icons-with-hover-txt .image-spot-elements image-spot-element-text {
    height: auto !important; }

#content .image-spot.carousel-spot-whyus .component-content {
  max-width: 100%;
  width: 70rem; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.carousel-spot-whyus .component-content {
      max-width: 80%; } }

#content .image-spot.carousel-spot-whyus .image-spot-element-image {
  height: 250px;
  position: relative;
  margin: 0; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.carousel-spot-whyus .image-spot-element-image {
      height: 152px;
      margin: 0 auto;
      width: 270px;
      overflow: hidden;
      padding: 0; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.carousel-spot-whyus .image-spot-element-image {
      width: 50%; } }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.carousel-spot-whyus .image-spot-element-image {
      height: 360px; } }
  #content .image-spot.carousel-spot-whyus .image-spot-element-image img {
    position: absolute;
    top: 0;
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%); }
    @media only screen and (max-width: 767px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element-image img {
        height: auto;
        width: 100%;
        left: 0;
        transform: none; } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element-image img {
        position: absolute;
        top: 0;
        height: 100%;
        left: 50%;
        transform: translateX(-50%) translateX(43px); } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element-image img {
        width: 474px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #content .image-spot.carousel-spot-whyus .image-spot-element-image {
      height: 400px;
      border-radius: 3px; } }

#content .image-spot.carousel-spot-whyus .image-spot-element {
  position: relative; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.carousel-spot-whyus .image-spot-element {
      margin-bottom: 0.5rem; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.carousel-spot-whyus .image-spot-element {
      margin-bottom: 3rem; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-header, #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text {
      display: inline-block;
      position: absolute;
      text-align: left; } }
  #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-header {
    font-size: 16px;
    font-family: "Muli", Arial, sans-serif;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-header {
        font-size: 1.2rem; } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-header {
        top: 35%;
        right: 0;
        width: 50%;
        padding: 0 3rem; } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-header {
        font-size: 2.5rem; } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-header span {
        font-size: 1.6rem; } }
  #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text {
    font-size: 1rem;
    font-family: "Muli", Arial, sans-serif;
    padding: 1rem 0;
    position: absolute;
    top: 180px;
    right: calc(50% - 140px); }
    @media only screen and (max-width: 767px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text {
        top: 90px;
        right: calc(50% - 120px); } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text {
        top: 320px;
        right: calc(50% - 230px); } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text {
        right: calc(50% + 35px); } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text {
        top: 260px; }
        #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text .play-button-container .like-button-minimal {
          width: 54px;
          height: 54px;
          line-height: 54px; } }
    #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text .icon-play {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: "Muli", Arial, sans-serif !important;
      position: relative;
      padding-left: 2.4rem; }
      #content .image-spot.carousel-spot-whyus .image-spot-element .image-spot-element-text .icon-play:before {
        margin-right: 15px;
        font-size: 2rem;
        font-family: "icomoon";
        position: absolute;
        top: -2px;
        left: 0; }

#content .image-spot.carousel-spot-whyus .slick-dots {
  margin-bottom: 1rem; }
  #content .image-spot.carousel-spot-whyus .slick-dots li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin: 0 .5rem; }
  #content .image-spot.carousel-spot-whyus .slick-dots button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: relative; }
    #content .image-spot.carousel-spot-whyus .slick-dots button:before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      content: '';
      border-radius: 50%;
      background-color: #74cecd; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.carousel-spot-whyus .slick-dots button:before {
          width: 15px;
          height: 15px; } }
  #content .image-spot.carousel-spot-whyus .slick-dots .slick-active button:before {
    background-color: #3344f6; }

#content .image-spot.carousel-spot-whyus .slick-arrow {
  position: absolute;
  top: 105px;
  z-index: 1;
  border: 2px solid transparent;
  background-color: #74cecd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 0;
  font-size: 0;
  line-height: 0; }
  #content .image-spot.carousel-spot-whyus .slick-arrow:before {
    font-family: "icomoon";
    color: #ffffff;
    font-size: 2rem;
    display: block; }
  #content .image-spot.carousel-spot-whyus .slick-arrow:hover {
    background-color: #74cecd; }
    #content .image-spot.carousel-spot-whyus .slick-arrow:hover:before {
      color: #ffffff; }
  #content .image-spot.carousel-spot-whyus .slick-arrow.slick-next {
    right: -30px; }
    #content .image-spot.carousel-spot-whyus .slick-arrow.slick-next:before {
      content: ""; }
  #content .image-spot.carousel-spot-whyus .slick-arrow.slick-prev {
    left: -30px; }
    #content .image-spot.carousel-spot-whyus .slick-arrow.slick-prev:before {
      content: ""; }

@media only screen and (min-width: 1200px) {
  #content .image-spot.carousel-spot-whyus .slick-arrow {
    width: 50px;
    height: 50px;
    border-width: 3px;
    top: 140px; }
    #content .image-spot.carousel-spot-whyus .slick-arrow:before {
      font-size: 1.5rem; }
    #content .image-spot.carousel-spot-whyus .slick-arrow.slick-next {
      right: -60px; }
    #content .image-spot.carousel-spot-whyus .slick-arrow.slick-prev {
      left: -60px; } }
  @media only screen and (min-width: 1200px) and (hover) {
    #content .image-spot.carousel-spot-whyus .slick-arrow:hover {
      border-color: #74cecd;
      background-color: #ffffff; }
      #content .image-spot.carousel-spot-whyus .slick-arrow:hover:before {
        color: #74cecd; } }

@media only screen and (max-width: 991px) {
  #content .image-spot.carousel-spot-whyus .slick-arrow {
    display: none !important; } }

@media only screen and (min-width: 1400px) {
  #content .image-spot.with-gallery .component-content {
    max-width: 1248px; } }

@media only screen and (max-width: 767px) {
  #content .image-spot.with-gallery .image-spot-elements {
    margin: 0 .5rem; } }

#content .image-spot.with-gallery .image-spot-element {
  position: relative;
  width: 33.33%;
  float: left; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.with-gallery .image-spot-element {
      margin: 0 .5rem .75rem;
      width: calc(33.33% - 1rem); } }

#content .image-spot.with-gallery .image-spot-element-image {
  margin: 0;
  padding: 0; }

#content .image-spot.with-gallery .image-spot-element-link {
  margin: 0; }
  #content .image-spot.with-gallery .image-spot-element-link a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    color: transparent;
    font-size: 0;
    line-height: 0; }

@media only screen and (max-width: 767px) {
  #content .image-spot.accordion .component-content {
    max-width: 100%; } }

#content .image-spot.accordion .image-spot-elements {
  margin-top: 2rem; }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.accordion .image-spot-elements {
      margin-top: 5rem; } }

#content .image-spot.accordion .image-spot-element {
  position: relative;
  float: none;
  width: 100%;
  margin-bottom: 0;
  padding: 1.5rem 1.5rem 1.5rem 4rem;
  border-bottom: 1px solid #24405b;
  cursor: pointer; }
  #content .image-spot.accordion .image-spot-element:before {
    content: "";
    color: #05CDCD;
    display: inline-block;
    border-style: none solid solid none;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    position: absolute;
    top: 1.5rem;
    left: 1.5rem; }
  #content .image-spot.accordion .image-spot-element.is-active:before {
    transform: rotate(-135deg);
    top: 1.7rem; }
  #content .image-spot.accordion .image-spot-element.is-active .image-spot-element-text {
    display: block; }
  @media only screen and (min-width: 992px) {
    #content .image-spot.accordion .image-spot-element {
      padding: 1.5rem 2.5rem 1.5rem 4rem;
      margin-bottom: 1.5rem; }
      #content .image-spot.accordion .image-spot-element.is-active:before {
        top: 1.7rem; }
      #content .image-spot.accordion .image-spot-element:before {
        top: 1.5rem; } }

#content .image-spot.accordion .image-spot-element-image {
  display: none; }

#content .image-spot.accordion .image-spot-element-header,
#content .image-spot.accordion .image-spot-element-text {
  text-align: left;
  max-width: 100%; }

#content .image-spot.accordion .image-spot-element-header {
  height: auto !important;
  font-size: 0.9rem;
  font-family: "Muli", Arial, sans-serif;
  line-height: 1.2;
  font-weight: 700; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.accordion .image-spot-element-header {
      font-size: 1.1rem; } }

#content .image-spot.accordion .image-spot-element-text {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: none; }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.accordion .image-spot-element-text {
      padding-top: 2rem;
      margin-bottom: 3rem; } }
  #content .image-spot.accordion .image-spot-element-text ul {
    list-style: square;
    margin-left: 1.2rem; }
  #content .image-spot.accordion .image-spot-element-text a {
    text-decoration: underline;
    font-weight: normal; }
    #content .image-spot.accordion .image-spot-element-text a:hover {
      text-decoration: none; }
    #content .image-spot.accordion .image-spot-element-text a:active, #content .image-spot.accordion .image-spot-element-text a.nice-select.open, #content .image-spot.accordion .image-spot-element-text a:visited {
      text-decoration: underline; }

#content .image-spot.fullwidth-person {
  background-color: #24405b;
  color: #ffffff;
  padding: 20px 0; }
  @media only screen and (min-width: 992px) {
    #content .image-spot.fullwidth-person {
      padding: 30px 0; } }
  #content .image-spot.fullwidth-person.background-white {
    background-color: #ffffff;
    color: #24405b; }
  #content .image-spot.fullwidth-person .image-spot-element {
    position: relative;
    margin-bottom: 1rem; }
  #content .image-spot.fullwidth-person .image-spot-element-header {
    margin-bottom: 1.5rem; }
    #content .image-spot.fullwidth-person .image-spot-element-header p {
      font-weight: 600;
      font-size: 17px; }
  #content .image-spot.fullwidth-person .image-spot-element-text ul {
    list-style: circle; }
    #content .image-spot.fullwidth-person .image-spot-element-text ul li {
      margin-left: 1rem;
      text-indent: 0;
      padding-left: 0; }
  #content .image-spot.fullwidth-person.reverse-order .component-content .image-spot-elements .image-spot-element-text {
    max-width: 100%; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.fullwidth-person.reverse-order .image-spot-element {
      display: block; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.fullwidth-person.reverse-order .image-spot-element {
      display: flex; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.fullwidth-person.reverse-order .image-spot-element .image-spot-element-image {
      order: 2;
      padding: 0; } }
  @media only screen and (min-width: 1400px) {
    #content .image-spot.fullwidth-person.reverse-order .image-spot-element .image-spot-element-image {
      padding: 0 5rem 0 0; } }
  @media only screen and (min-width: 1400px) {
    #content .image-spot.fullwidth-person.reverse-order .image-spot-element .image-spot-element-image img {
      max-height: none; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.fullwidth-person.reverse-order.landing-page {
      padding: 0; } }

#content .image-spot.image-spot-and-rich-text-one-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }
  #content .image-spot.image-spot-and-rich-text-one-row .component-content {
    padding-top: 3rem; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.image-spot-and-rich-text-one-row .component-content {
        padding-bottom: 3rem; } }
  #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-text, #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-header {
    max-width: 90%; }
  #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-header > span:first-of-type {
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.2rem; }
    #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-header > span:first-of-type:before, #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-header > span:first-of-type:after {
      display: inline-block;
      content: '-';
      margin: 0 5px; }
  #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-text {
    margin-top: 20px; }
    #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-text > span:first-of-type {
      font-family: "Muli", Arial, sans-serif;
      font-size: 1.8rem;
      font-weight: bold;
      color: #FFFFFF; }
    #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements .image-spot-element-text * {
      color: #a4aab1; }
  #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li {
    margin-bottom: 3rem; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements {
      /* one item */
      /* two items */
      /* three items */
      /* four items */
      /* five items */
      /* six items */
      /* seven items */
      /* eight items */
      /* nine items */
      /* ten items */ }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(1) {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(2),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(3),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(4),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(5),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(6),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(7),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(8),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(9),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(10),
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
        width: 50%; }
      #content .image-spot.image-spot-and-rich-text-one-row .image-spot-elements > li {
        float: left; } }
  #content .image-spot.image-spot-and-rich-text-one-row.carousel-spot-mobile .image-spot-elements .image-spot-element-text p {
    font-size: inherit; }
  #content .image-spot.image-spot-and-rich-text-one-row.carousel-spot-mobile .slick-dots {
    margin-bottom: 1rem; }
    #content .image-spot.image-spot-and-rich-text-one-row.carousel-spot-mobile .slick-dots li {
      display: inline-block;
      margin: 0;
      padding: 0;
      margin: 0 .5rem; }
    #content .image-spot.image-spot-and-rich-text-one-row.carousel-spot-mobile .slick-dots button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      position: relative; }
      #content .image-spot.image-spot-and-rich-text-one-row.carousel-spot-mobile .slick-dots button:before {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        content: '';
        border-radius: 50%;
        background-color: #74cecd; }
        @media only screen and (min-width: 768px) {
          #content .image-spot.image-spot-and-rich-text-one-row.carousel-spot-mobile .slick-dots button:before {
            width: 15px;
            height: 15px; } }
    #content .image-spot.image-spot-and-rich-text-one-row.carousel-spot-mobile .slick-dots .slick-active button:before {
      background-color: #3344f6; }

#content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element {
  position: relative; }
  @media only screen and (min-width: 992px) {
    #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 2rem; } }
  @media screen and (min-width: 1024px) {
    #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element {
      margin-bottom: 0; } }
  #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    width: 220px;
    height: 220px; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
        width: 250px;
        height: 250px;
        margin-bottom: 2rem; } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
        width: 190px;
        height: 190px; } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
        width: 230px;
        height: 230px; } }
  #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
    width: 220px;
    height: 220px !important;
    padding: 1.5rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
    color: #ffffff; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
        width: 250px;
        height: 250px !important; } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
        width: 190px;
        height: 190px !important; } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
        width: 230px;
        height: 230px !important; } }
    #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-header span {
      font-size: 12px; }
      #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-header span ~ p {
        margin-top: .8rem; }
    #content .image-spot.element-as-background .component-content .image-spot-elements .image-spot-element .image-spot-element-header p {
      font-size: 30px;
      color: #74cecd; }

@media only screen and (min-width: 1200px) {
  #content .image-spot.element-as-background.carousel-spot-mobile .image-spot-elements .image-spot-element {
    margin-bottom: 0; } }

#content .image-spot.element-as-background.carousel-spot-mobile .image-spot-element-text p {
  font-size: inherit; }

@media only screen and (min-width: 1200px) {
  #content .image-spot.element-as-background.slim .component-content {
    max-width: 886px; } }

@media only screen and (max-width: 767px) {
  #content .image-spot.element-as-background.slim .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
    height: 160px; } }

@media only screen and (min-width: 768px) {
  #content .image-spot.element-as-background.slim .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
    width: 350px; } }

@media only screen and (min-width: 992px) {
  #content .image-spot.element-as-background.slim .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
    width: 230px;
    height: 160px; } }

@media only screen and (min-width: 1200px) {
  #content .image-spot.element-as-background.slim .component-content .image-spot-elements .image-spot-element .image-spot-element-image {
    width: 230px;
    height: 160px; } }

@media only screen and (max-width: 767px) {
  #content .image-spot.element-as-background.slim .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
    width: 160px;
    height: 160px !important; } }

@media only screen and (min-width: 768px) {
  #content .image-spot.element-as-background.slim .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
    width: 220px; } }

@media only screen and (min-width: 992px) {
  #content .image-spot.element-as-background.slim .component-content .image-spot-elements .image-spot-element .image-spot-element-header {
    width: 160px;
    height: 160px !important; } }

#content .image-spot.list-categories-icons .image-spot-elements {
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.list-categories-icons .image-spot-elements {
      max-width: 1080px;
      margin: 0 auto; } }
  #content .image-spot.list-categories-icons .image-spot-elements > li {
    width: 50%;
    margin-bottom: 1.5rem;
    padding: 0 15px; }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.list-categories-icons .image-spot-elements > li {
        margin-bottom: 2rem; } }

#content .image-spot.list-categories-icons .image-spot-element-image {
  background-color: #00cfcf;
  position: relative;
  width: 100%;
  height: 0;
  padding: 50% 0;
  margin: 0 auto 1em;
  border-radius: 50%; }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.list-categories-icons .image-spot-element-image:hover ~ .image-spot-element-link a {
      color: #3344f6; } }
  #content .image-spot.list-categories-icons .image-spot-element-image img {
    position: absolute;
    top: 0;
    left: 0; }

#content .image-spot.list-categories-icons .image-spot-element-link a {
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.4;
  color: #24405b;
  font-size: 13px; }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.list-categories-icons .image-spot-element-link a {
      font-size: 16px; }
      #content .image-spot.list-categories-icons .image-spot-element-link a:hover {
        color: #3344f6; } }

#content .image-spot.slider-with-btn-video .component-content {
  max-width: 100%;
  width: 70rem; }
  @media only screen and (min-width: 992px) {
    #content .image-spot.slider-with-btn-video .component-content {
      max-width: 850px; } }

#content .image-spot.slider-with-btn-video .image-spot-elements .image-spot-element {
  position: relative; }
  #content .image-spot.slider-with-btn-video .image-spot-elements .image-spot-element .image-spot-element-header {
    display: none; }
  #content .image-spot.slider-with-btn-video .image-spot-elements .image-spot-element .image-spot-element-text {
    font-size: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    #content .image-spot.slider-with-btn-video .image-spot-elements .image-spot-element .image-spot-element-text .icon-play {
      color: #FFFFFF;
      position: inherit;
      top: inherit;
      left: inherit;
      -webkit-transform: inherit;
      -ms-transform: inherit;
      transform: inherit; }
  #content .image-spot.slider-with-btn-video .image-spot-elements .image-spot-element .image-spot-element-header {
    margin: 0; }

#content .image-spot.slider-with-btn-video .image-spot-element-image {
  height: 240px;
  position: relative;
  margin: 0;
  overflow: hidden; }
  #content .image-spot.slider-with-btn-video .image-spot-element-image img {
    position: absolute;
    top: 0;
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 480px) {
      #content .image-spot.slider-with-btn-video .image-spot-element-image img {
        left: 75%;
        transform: translateX(-75%);
        max-width: 150%; } }
    @media only screen and (min-width: 768px) {
      #content .image-spot.slider-with-btn-video .image-spot-element-image img {
        height: auto;
        position: static;
        width: 100%;
        transform: none;
        top: auto;
        left: auto; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #content .image-spot.slider-with-btn-video .image-spot-element-image {
      height: 320px; } }
  @media only screen and (min-width: 768px) {
    #content .image-spot.slider-with-btn-video .image-spot-element-image {
      height: 400px;
      max-height: 480px;
      text-align: center;
      width: 82rem;
      max-width: 80%;
      margin: 0 auto;
      padding: 0; } }

#content .image-spot.slider-with-btn-video .image-spot-element {
  margin-bottom: 1rem; }
  #content .image-spot.slider-with-btn-video .image-spot-element .image-spot-element-header {
    font-size: 2rem;
    font-family: "Muli", Arial, sans-serif;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.slider-with-btn-video .image-spot-element .image-spot-element-header {
        font-size: 2.5rem; } }
  #content .image-spot.slider-with-btn-video .image-spot-element .image-spot-element-text {
    font-size: 1rem;
    font-family: "Muli", Arial, sans-serif; }

#content .image-spot.slider-with-btn-video .slick-dots {
  margin-bottom: 1rem; }
  #content .image-spot.slider-with-btn-video .slick-dots li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin: 0 .5rem; }
  #content .image-spot.slider-with-btn-video .slick-dots button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: relative; }
    #content .image-spot.slider-with-btn-video .slick-dots button:before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      content: '';
      border-radius: 50%;
      background-color: #74cecd; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.slider-with-btn-video .slick-dots button:before {
          width: 15px;
          height: 15px; } }
  #content .image-spot.slider-with-btn-video .slick-dots .slick-active button:before {
    background-color: #3344f6; }

#content .image-spot.slider-with-btn-video .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border: 2px solid transparent;
  background-color: #FFFFFF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 0;
  line-height: 0; }
  #content .image-spot.slider-with-btn-video .slick-arrow:before {
    font-family: "icomoon";
    color: #013e5d;
    font-size: 1.2rem;
    display: block; }
  #content .image-spot.slider-with-btn-video .slick-arrow:hover {
    border-color: #013e5d;
    background-color: transparent; }
    #content .image-spot.slider-with-btn-video .slick-arrow:hover:before {
      color: #013e5d; }
  #content .image-spot.slider-with-btn-video .slick-arrow.slick-next {
    right: -20px; }
    #content .image-spot.slider-with-btn-video .slick-arrow.slick-next:before {
      content: ""; }
  #content .image-spot.slider-with-btn-video .slick-arrow.slick-prev {
    left: -20px; }
    #content .image-spot.slider-with-btn-video .slick-arrow.slick-prev:before {
      content: ""; }

@media only screen and (min-width: 1200px) {
  #content .image-spot.slider-with-btn-video .slick-arrow {
    width: 60px;
    height: 60px;
    border-width: 3px;
    top: 140px; }
    #content .image-spot.slider-with-btn-video .slick-arrow:before {
      font-size: 1.8rem; }
    #content .image-spot.slider-with-btn-video .slick-arrow.slick-next {
      right: -30px; }
    #content .image-spot.slider-with-btn-video .slick-arrow.slick-prev {
      left: -30px; } }

@media only screen and (max-width: 1100px) {
  #content .image-spot.slider-with-btn-video .slick-arrow {
    display: none !important; } }

#content .image-spot.our-teams-people .image-spot-element-context {
  display: block; }

#content .image-spot.our-teams-people .image-spot-elements > li:first-child:nth-last-child(1) {
  float: none;
  max-width: 100%;
  margin: 0 auto;
  width: 520px; }

#content .image-spot.our-teams-people .image-spot-elements .image-spot-element {
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  overflow: visible; }
  #content .image-spot.our-teams-people .image-spot-elements .image-spot-element > * {
    max-width: 90%;
    margin: 1rem auto; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element {
      padding: 0;
      margin-bottom: 2rem; }
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element + .image-spot-element {
        padding-top: 2rem; }
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element > * {
        max-width: 100%; } }

#content .image-spot.our-teams-people .image-spot-elements .image-spot-element-image {
  order: 3; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-image {
      margin-top: 0;
      margin-bottom: 0; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-image {
      padding: 0 2rem; } }
  #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-image * {
    width: 100%;
    max-width: 382px; }

#content .image-spot.our-teams-people .image-spot-elements .image-spot-element-header {
  order: 1;
  margin-bottom: 2rem;
  margin-top: 0;
  text-align: left; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-header {
      margin-bottom: 1rem; } }
  #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-header *:not(p) {
    font-weight: bold;
    line-height: 1.2;
    font-size: 17px; }
  #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-header p {
    font-size: 13px;
    line-height: 1.5;
    padding-top: 2rem;
    margin: 0; }
    @media only screen and (max-width: 767px) {
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-header p {
        word-break: break-word; } }
    @media only screen and (min-width: 768px) {
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-header p {
        padding-top: 3rem; } }

#content .image-spot.our-teams-people .image-spot-elements .image-spot-element-text {
  order: 2;
  margin-bottom: 3rem;
  text-align: left; }
  @media only screen and (max-width: 767px) {
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-text {
      width: 100%; } }
  #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-text ul {
    list-style-type: none; }
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-text ul li {
      text-indent: inherit;
      margin-bottom: 0.1rem;
      position: relative;
      line-height: 1.4; }
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-text ul li:before {
        content: '';
        position: absolute;
        left: -12px;
        top: 6px;
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #24405b; }
        @media only screen and (min-width: 768px) {
          #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-text ul li:before {
            left: -15px;
            top: 8px;
            width: 5px;
            height: 5px; } }

#content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context {
  order: 4;
  text-align: center;
  padding: 0 1rem 0 1rem;
  margin-bottom: 0; }
  #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context span[class*="like-h"] {
    font-weight: bold;
    line-height: 1.2;
    font-size: 16px; }
  #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q {
    position: relative;
    padding-left: 4rem;
    width: 100%;
    z-index: 1;
    line-height: 1.3em;
    color: #6b6b6b;
    font-size: 14px;
    display: inline-block;
    text-align: left; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q {
        font-size: 20px;
        padding-left: 3rem; } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q {
        padding-left: 2rem; } }
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute:before, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q:before {
      position: absolute;
      top: -10px;
      left: -10px;
      display: block;
      width: 52px;
      height: 52px;
      content: '';
      background-color: transparent;
      background-image: url("/design/build/demant-szczecin/assets/quote-seafoam-green.svg");
      background-size: 52px;
      z-index: -1; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute:before, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q:before {
          left: -60px;
          width: 69px;
          height: 69px;
          background-size: 69px; } }
    #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute:empty, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q:empty {
      display: none; }
      #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute:empty:before, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context blackqoute:empty:after, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q:empty:before, #content .image-spot.our-teams-people .image-spot-elements .image-spot-element-context q:empty:after {
        display: none; }

#content .image-spot.like-as-timeline-numbered .image-spot-elements {
  counter-reset: section;
  /* one item */
  /* two items */
  /* three items */
  /* four items */
  /* five items */
  /* six items */
  /* seven items */
  /* eight items */
  /* nine items */
  /* ten items */ }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(1) {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(2),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(3),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(4),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(5),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(6),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(7),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(8),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(9),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(10),
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    #content .image-spot.like-as-timeline-numbered .image-spot-elements {
      margin-top: 100px; } }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.like-as-timeline-numbered .image-spot-elements {
      margin: 120px auto 0 auto;
      max-width: 970px; } }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.like-as-timeline-numbered .image-spot-elements:before {
      content: '';
      position: absolute;
      top: -90px;
      left: 50%;
      transform: translateX(-50%);
      height: 26px;
      width: 2px;
      background-color: #e1e1e1; } }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements > li {
    margin: 0 auto 20px auto; }
    #content .image-spot.like-as-timeline-numbered .image-spot-elements > li:last-child {
      margin-bottom: 0; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.like-as-timeline-numbered .image-spot-elements > li {
        margin-bottom: 60px; } }
    @media only screen and (min-width: 992px) {
      #content .image-spot.like-as-timeline-numbered .image-spot-elements > li {
        margin-top: 60px; } }
  @media only screen and (max-width: 767px) {
    #content .image-spot.like-as-timeline-numbered .image-spot-elements > li {
      max-width: 400px; } }
  #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element {
    display: flex;
    position: relative;
    overflow: visible; }
    @media only screen and (max-width: 767px) {
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element {
        flex-wrap: wrap; } }
    #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element:before {
      counter-increment: section;
      content: counter(section);
      display: block;
      position: absolute;
      top: 40px;
      left: -10px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #24405b;
      font-family: "Muli", Arial, sans-serif;
      font-style: normal;
      line-height: 1;
      padding-top: 16px;
      font-size: 2rem;
      width: 64px;
      height: 64px;
      background-clip: padding-box; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element:before {
          left: 50%;
          transform: translateX(-50%);
          top: -60px; } }
      @media only screen and (min-width: 992px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element:before {
          width: 168px;
          height: 168px;
          top: -125px;
          padding-top: 35px;
          font-size: 4rem;
          border: 20px #ffffff solid; } }
      @media only screen and (min-width: 1200px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element:before {
          width: 200px;
          height: 200px;
          border: 36px #ffffff solid; } }
    @media only screen and (min-width: 768px) {
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-text {
        order: 1;
        text-align: right; }
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-image {
        order: 2;
        text-align: left;
        border-left: 2px solid #e1e1e1;
        border-right: none;
        padding-left: 4rem; } }
    @media only screen and (min-width: 1200px) {
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-image {
        padding-left: 6rem; } }
    #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element > * {
      padding: 0;
      width: 100%; }
    @media only screen and (min-width: 768px) {
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element > * {
        width: 50%; } }
    #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-header {
      display: none; }
    #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image {
      padding: 0; }
      @media only screen and (max-width: 767px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image {
          text-align: right;
          max-width: 80%;
          margin: 0 auto; } }
      @media only screen and (min-width: 768px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image {
          border-right: 2px solid #e1e1e1; } }
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image img {
        max-width: 200px;
        width: 100%; }
        @media only screen and (max-width: 767px) {
          #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image img {
            height: 150px;
            width: auto;
            max-width: 200px;
            position: relative;
            right: -30px; } }
        @media only screen and (min-width: 992px) {
          #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image img {
            max-width: 100%;
            width: auto;
            height: 200px; } }
        @media only screen and (min-width: 1200px) {
          #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image img {
            height: 290px;
            padding-top: 3rem; } }
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-image:not(:empty) {
        margin-bottom: 0; }
    #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text {
      color: #404040;
      text-align: left; }
      @media only screen and (max-width: 767px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text {
          padding-bottom: 1.2rem;
          border-bottom: 2px solid #74cecd; } }
      @media only screen and (min-width: 768px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text {
          padding: 0 4rem; } }
      @media only screen and (min-width: 1200px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text {
          padding: 4rem 6rem;
          max-width: 490px; } }
      #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text h3 {
        font-weight: 300;
        font-size: 24px; }
        @media only screen and (max-width: 767px) {
          #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text h3 {
            max-width: 80%; } }
        @media only screen and (min-width: 992px) {
          #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text h3 {
            font-size: 40px;
            line-height: 1.1; } }
      @media only screen and (min-width: 1200px) {
        #content .image-spot.like-as-timeline-numbered .image-spot-elements .image-spot-element .image-spot-element-text p {
          line-height: 1.5; } }

@media only screen and (min-width: 768px) {
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text:after, #content .image-spot.like-as-timeline-numbered .component-content .image-spot-elements .image-spot-element-text:after {
    content: '';
    display: block;
    margin-top: 20px;
    height: 4px;
    background-color: #74cecd; } }

#content .image-spot.like-as-timeline-unnumbered {
  position: relative; }
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements:after {
    content: '';
    clear: both; }
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element {
    overflow: inherit;
    float: left;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element {
        margin-bottom: 4rem; } }
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:last-child {
      margin-bottom: 0; }
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text,
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header {
    height: auto !important; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text,
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header {
        text-align: center; } }
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header {
    margin-bottom: 2rem; }
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header * {
      font-weight: 300; }
      @media only screen and (min-width: 992px) {
        #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header * {
          font-size: 40px;
          line-height: 1.1; } }
  @media only screen and (max-width: 767px) {
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text {
      max-width: 100%; } }
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text * {
    font-size: 13px; }
    @media only screen and (min-width: 992px) {
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text * {
        font-size: 16px; } }
  #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image {
    padding: 0 10%;
    width: 240px;
    max-width: 100%;
    margin: 0 auto;
    z-index: 1; }
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image img {
      width: 100%; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image img {
          border: 20px solid white; } }
    @media only screen and (max-width: 767px) {
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image {
        width: 160px;
        padding: 0;
        margin-bottom: 20px; } }
  @media only screen and (min-width: 768px) {
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element {
      position: relative; }
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text,
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header {
      display: inline-block;
      width: calc( 100% - 320px);
      max-width: 30rem;
      float: left;
      padding-left: 50px; }
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text *,
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header * {
        text-align: left; }
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image {
      position: relative;
      width: 240px;
      padding: 0;
      float: left;
      display: inline-block;
      text-align: center; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      position: relative; }
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:nth-child(odd) {
        padding-right: calc(50% - 135px); }
        #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-image {
          float: right; }
        #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-text,
        #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-header {
          float: right;
          padding-left: 0;
          padding-right: 50px; }
          #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-text *,
          #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:nth-child(odd) .image-spot-element-header * {
            text-align: right; }
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:nth-child(even) {
        padding-left: calc(50% - 135px); }
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:before, #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        width: 2px;
        background-color: #e1e1e1;
        z-index: 0; }
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:before {
        top: -2rem;
        height: 2rem; }
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element:after {
        bottom: -2rem;
        height: 100%; }
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-text,
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-header {
      width: calc( 100% - 270px); }
    #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image {
      width: 270px; }
      #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image:before, #content .image-spot.like-as-timeline-unnumbered .component-content .image-spot-elements .image-spot-element-image:after {
        display: none; } }
  @media only screen and (min-width: 768px) {
    #content .image-spot.like-as-timeline-unnumbered .component-content {
      max-width: 640px;
      margin: 85px auto 75px;
      display: block; } }
  @media only screen and (min-width: 992px) {
    #content .image-spot.like-as-timeline-unnumbered .component-content {
      max-width: 100%;
      width: calc(100% - 50px); } }
  @media only screen and (min-width: 1200px) {
    #content .image-spot.like-as-timeline-unnumbered .component-content {
      width: 1100px;
      margin-top: 115px; } }

#content .image-spot.like-as-timeline-mobile-slider .image-spot-elements {
  /* one item */
  /* two items */
  /* three items */
  /* four items */
  /* five items */
  /* six items */
  /* seven items */
  /* eight items */
  /* nine items */
  /* ten items */ }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(1) {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(2),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(2) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(3),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(3) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(4),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(4) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(5),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(5) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(6),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(6) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(7),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(7) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(8),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(8) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(9),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(9) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(10),
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li:first-child:nth-last-child(10) ~ li {
    width: 100%; }
  #content .image-spot.like-as-timeline-mobile-slider .image-spot-elements > li {
    float: left; }

#content .image-spot.like-as-timeline-mobile-slider .slick-dots {
  margin-bottom: 1rem; }
  #content .image-spot.like-as-timeline-mobile-slider .slick-dots li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin: 0 .5rem; }
  #content .image-spot.like-as-timeline-mobile-slider .slick-dots button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: relative; }
    #content .image-spot.like-as-timeline-mobile-slider .slick-dots button:before {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      content: '';
      border-radius: 50%;
      background-color: #74cecd; }
      @media only screen and (min-width: 768px) {
        #content .image-spot.like-as-timeline-mobile-slider .slick-dots button:before {
          width: 15px;
          height: 15px; } }
  #content .image-spot.like-as-timeline-mobile-slider .slick-dots .slick-active button:before {
    background-color: #3344f6; }

@media only screen and (min-width: 992px) {
  #content .image-spot.like-as-timeline-mobile-slider .slick-dots {
    display: none; } }

@media only screen and (max-width: 767px) {
  #content .image-spot.with-gallery .component-content {
    max-width: 100%; }
  #content .image-spot.list-categories-icons .image-spot-elements > li {
    padding: 0 10px;
    display: flex;
    margin-bottom: 3px; }
  #content .image-spot.list-categories-icons .image-spot-element-image {
    display: none; }
  #content .image-spot.list-categories-icons .image-spot-element-link {
    margin-top: 0;
    width: 100%; }
    #content .image-spot.list-categories-icons .image-spot-element-link a {
      display: block;
      font-weight: normal;
      width: 100%;
      height: 100%;
      padding: 5px 10px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #content .image-spot.carousel-spot-mobile .image-spot-element {
    width: 50%;
    float: left; }
  #content .image-spot.carousel-spot-mobile.element-as-background .image-spot-element {
    width: 100%; } }

@media only screen and (min-width: 768px) {
  #content .image-spot.with-gallery .image-spot-element {
    padding-right: .5rem;
    padding-left: .5rem;
    margin-bottom: .7rem; }
    #content .image-spot.with-gallery .image-spot-element .image-spot-element-link a {
      right: .5rem;
      left: .5rem; }
  #content .image-spot.fullwidth-person .image-spot-element {
    display: flex; }
  #content .image-spot.fullwidth-person .image-spot-element-image {
    flex-basis: 50%;
    padding: 0 3rem 0 0;
    display: inline-block;
    text-align: right;
    margin-bottom: 0; }
    #content .image-spot.fullwidth-person .image-spot-element-image img {
      max-height: 20rem;
      width: auto; } }
    @media only screen and (min-width: 768px) and (min-width: 992px) {
      #content .image-spot.fullwidth-person .image-spot-element-image img {
        max-height: 382px; } }

@media only screen and (min-width: 768px) {
  #content .image-spot.fullwidth-person .image-spot-element-text {
    display: inline-block;
    flex-basis: 50%; }
    #content .image-spot.fullwidth-person .image-spot-element-text h3 {
      font-weight: 300; } }
    @media only screen and (min-width: 768px) and (min-width: 992px) {
      #content .image-spot.fullwidth-person .image-spot-element-text h3 {
        margin-bottom: 30px; } }

@media only screen and (min-width: 768px) {
  #content .image-spot.list-categories-icons .component-content .image-spot-elements .image-spot-element-link {
    margin-left: 0;
    margin-right: 0; }
  #content .image-spot.list-categories-icons .image-spot-elements > li {
    width: 25%; } }

@media only screen and (min-width: 992px) {
  #content .image-spot.with-gallery .image-spot-element {
    width: 33.3333%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-bottom: 2rem;
    margin-top: 0; }
    #content .image-spot.with-gallery .image-spot-element .image-spot-element-link a {
      right: 1rem;
      left: 1rem; } }

@media only screen and (min-width: 1200px) {
  #content .image-spot.with-gallery .image-spot-element {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-bottom: 3rem; }
    #content .image-spot.with-gallery .image-spot-element .image-spot-element-link a {
      right: 1.5rem;
      left: 1.5rem; }
    #content .image-spot.with-gallery .image-spot-element:after {
      font-family: "icomoon";
      content: "";
      display: inline-block;
      background-color: #FFFFFF;
      color: #818181;
      border-radius: 50%;
      position: absolute;
      right: 10%;
      top: 5%;
      font-size: 1rem;
      line-height: 1;
      padding: 1.2rem;
      opacity: 0;
      transition: opacity .3s; }
    #content .image-spot.with-gallery .image-spot-element:hover:after {
      opacity: 1; }
  #content .image-spot.list-categories-icons .image-spot-elements > li {
    width: 20%; } }

@media only screen and (max-width: 480px) {
  #content .image-spot.list-categories-icons .image-spot-elements > li {
    width: 100%; } }

.ie #content .image-spot.accordion .image-spot-element-header {
  font-weight: normal; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
body.on-page-editor .component.intro-banner {
  min-height: 60px; }

.intro-banner {
  opacity: 0; }
  @media only screen and (min-width: 768px) {
    .intro-banner {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1920px; }
      .intro-banner.full-width {
        width: 100%;
        max-width: 1600px; }
      .intro-banner.regular {
        width: 86%;
        max-width: 1200px; }
      .intro-banner.slim {
        width: 47.2%;
        max-width: 660px; } }
  .intro-banner__nudge {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border: 0; }
    .intro-banner__nudge button {
      border: 0;
      border-radius: 50%;
      width: 56px;
      height: 56px;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer; }
      .intro-banner__nudge button::after {
        position: relative;
        top: -2px;
        content: "";
        display: inline-block;
        box-sizing: border-box;
        border-style: none solid solid none;
        border-color: #ffffff;
        width: 20px;
        height: 20px;
        transform: rotate(45deg); }
      .intro-banner__nudge button:hover::after {
        border-color: #5b7085; }
  .intro-banner .banner-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.component.intro-banner:not(.hero) .banner-container {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center; }

.component.intro-banner:not(.hero) .banner-content {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px; }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-top: 60px; } }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-bottom: 60px; } }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-left: 235px; } }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-right: 235px; } }
  .component.intro-banner:not(.hero) .banner-content p + p {
    margin-top: 3em; }
  .component.intro-banner:not(.hero) .banner-content p {
    font-size: 20px;
    line-height: 1.4; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner:not(.hero) .banner-content p {
        font-size: 20px; } }
  .component.intro-banner:not(.hero) .banner-content h1, .component.intro-banner:not(.hero) .banner-content h2, .component.intro-banner:not(.hero) .banner-content h3, .component.intro-banner:not(.hero) .banner-content h4, .component.intro-banner:not(.hero) .banner-content h5 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.27; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner:not(.hero) .banner-content h1, .component.intro-banner:not(.hero) .banner-content h2, .component.intro-banner:not(.hero) .banner-content h3, .component.intro-banner:not(.hero) .banner-content h4, .component.intro-banner:not(.hero) .banner-content h5 {
        font-size: 22px; } }

.component.intro-banner:not(.hero) .banner-container {
  background-position: center; }

.component.intro-banner:not(.hero).top-left-image .banner-container {
  background-position: top left; }

.component.intro-banner:not(.hero).middle-left-image .banner-container {
  background-position: center left; }

.component.intro-banner:not(.hero).bottom-left-image .banner-container {
  background-position: bottom left; }

.component.intro-banner:not(.hero).top-center-image .banner-container {
  background-position: top center; }

.component.intro-banner:not(.hero).bottom-center-image .banner-container {
  background-position: bottom center; }

.component.intro-banner:not(.hero).top-right-image .banner-container {
  background-position: top right; }

.component.intro-banner:not(.hero).middle-right-image .banner-container {
  background-position: center right; }

.component.intro-banner:not(.hero).bottom-right-image .banner-container {
  background-position: bottom right; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.intro-banner.hero {
  position: relative;
  overflow: hidden;
  margin-top: 0; }
  .component.intro-banner.hero img {
    width: 100%;
    height: auto;
    display: block; }
  .component.intro-banner.hero .banner-container {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner.hero .banner-container {
        top: 25%; } }
  @media only screen and (min-width: 768px) {
    .component.intro-banner.hero.intro-banner-slider .banner-container {
      top: 15%; } }
  .component.intro-banner.hero .banner-container-inner {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner.hero .banner-container-inner {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .component.intro-banner.hero .banner-container-inner.full-width {
          width: 100%;
          max-width: 1600px; }
        .component.intro-banner.hero .banner-container-inner.regular {
          width: 86%;
          max-width: 1200px; }
        .component.intro-banner.hero .banner-container-inner.slim {
          width: 47.2%;
          max-width: 660px; } }
  .component.intro-banner.hero .banner-content {
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .component.intro-banner.hero .banner-content {
        text-align: inherit;
        width: 50%; }
        .component.intro-banner.hero .banner-content [class*="like-"] {
          position: relative;
          left: 45%;
          transform: translateX(-50%); } }
  @media only screen and (max-width: 767px) {
    .component.intro-banner.hero.circle-white svg, .component.intro-banner.hero.circle-white .svg {
      opacity: 1; } }
  .component.intro-banner.hero svg,
  .component.intro-banner.hero .svg {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 200vw;
    height: 200vw;
    bottom: 20px;
    opacity: 0.5; }
    @media only screen and (max-width: 767px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        bottom: 10px; } }
    .component.intro-banner.hero svg.fixed-bottom,
    .component.intro-banner.hero .svg.fixed-bottom {
      top: auto;
      bottom: 15px; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner.hero svg.fixed-bottom,
      .component.intro-banner.hero .svg.fixed-bottom {
        bottom: 40px; } }
    @media only screen and (min-width: 992px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        width: 100vw;
        height: 100vw;
        top: -55vw;
        left: -33.33333vw;
        bottom: auto; }
        .component.intro-banner.hero svg.fixed-bottom,
        .component.intro-banner.hero .svg.fixed-bottom {
          bottom: 40px; } }
    @media only screen and (min-width: 1200px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        width: 90vw;
        height: 90vw;
        top: -45.9vw;
        left: -25vw;
        bottom: auto; }
        .component.intro-banner.hero svg.fixed-bottom,
        .component.intro-banner.hero .svg.fixed-bottom {
          bottom: 60px; } }
    @media only screen and (min-width: 1400px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        width: 1120px;
        height: 1120px;
        top: -504px;
        left: -280px;
        bottom: auto; }
        .component.intro-banner.hero svg.fixed-bottom,
        .component.intro-banner.hero .svg.fixed-bottom {
          bottom: 60px; } }
    @media only screen and (min-width: 1920px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        width: 1400px;
        height: 1400px;
        top: -630px;
        left: -350px;
        bottom: auto; }
        .component.intro-banner.hero svg.fixed-bottom,
        .component.intro-banner.hero .svg.fixed-bottom {
          bottom: 60px; } }
  .component.intro-banner.hero h1 {
    font-weight: 300;
    font-size: 35px;
    line-height: 1.14;
    letter-spacing: -0.6px; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner.hero h1 {
        font-size: 60px; } }
    @media only screen and (min-width: 992px) {
      .component.intro-banner.hero h1 {
        font-size: 55px; } }
    @media only screen and (min-width: 1200px) {
      .component.intro-banner.hero h1 {
        font-size: 60px; } }
  .component.intro-banner.hero:not(.charcoal-blue-txt) h1 {
    color: #f4f4f4; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.content .intro-banner .intro-banner-link {
  display: block; }

@media only screen and (max-width: 767px) {
  .content .intro-banner.txt-on-image.circle-electric-seafoam .svg {
    width: 120vw;
    height: 120vw;
    left: 35vw;
    z-index: 1; }
    .content .intro-banner.txt-on-image.circle-electric-seafoam .svg + div {
      position: relative;
      height: 300px; } }

@media only screen and (min-width: 768px) {
  .content .intro-banner.txt-on-image.circle-electric-seafoam .svg {
    bottom: 0; } }

@media only screen and (min-width: 1400px) {
  .content .intro-banner.txt-on-image.circle-electric-seafoam .svg {
    left: calc(50% - 1200px/2 - 450px);
    width: 55vw;
    height: 55vw;
    min-width: 1100px;
    min-height: 1100px;
    opacity: 0.7;
    bottom: 40px; } }

@media only screen and (max-width: 767px) {
  .content .intro-banner.txt-on-image.circle-electric-seafoam img {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover; } }

.content .intro-banner.txt-on-image.circle-electric-seafoam .banner-container {
  top: 15%; }
  @media only screen and (max-width: 767px) {
    .content .intro-banner.txt-on-image.circle-electric-seafoam .banner-container {
      z-index: 1; } }

.content .intro-banner.txt-on-image.circle-electric-seafoam h2 {
  text-align: left;
  font-size: 22px;
  font-weight: 300; }
  @media only screen and (max-width: 767px) {
    .content .intro-banner.txt-on-image.circle-electric-seafoam h2 {
      max-width: 400px; } }
  @media only screen and (min-width: 768px) {
    .content .intro-banner.txt-on-image.circle-electric-seafoam h2 {
      font-size: 30px; } }
  @media only screen and (min-width: 1200px) {
    .content .intro-banner.txt-on-image.circle-electric-seafoam h2 {
      font-size: 40px; } }
  @media only screen and (min-width: 1400px) {
    .content .intro-banner.txt-on-image.circle-electric-seafoam h2 {
      max-width: 550px; } }

@media only screen and (min-width: 768px) {
  .content .intro-banner.txt-on-image.circle-electric-seafoam .banner-content {
    text-align: left; } }
  @media only screen and (min-width: 768px) and (min-width: 992px) {
    .content .intro-banner.txt-on-image.circle-electric-seafoam .banner-content {
      width: 60%; } }

@media only screen and (min-width: 992px) {
  .content .intro-banner.txt-on-image.circle-electric-seafoam .banner-content [class*="like-"] {
    left: auto;
    transform: none; } }

.content .intro-banner.txt-on-image.circle-electric-seafoam .like-button:not(:hover) {
  color: #24405b; }

.content .intro-banner.hp-video-banner.hero {
  margin-bottom: 0;
  max-width: 1920px; }
  .content .intro-banner.hp-video-banner.hero .component-content {
    height: 100vh;
    min-height: 360px;
    overflow: hidden; }
    @media only screen and (min-width: 768px) {
      .content .intro-banner.hp-video-banner.hero .component-content {
        min-height: 470px; } }
    @media only screen and (min-width: 992px) {
      .content .intro-banner.hp-video-banner.hero .component-content {
        height: calc(100vh - 120px);
        width: 100%; } }
    .content .intro-banner.hp-video-banner.hero .component-content .banner-video, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type {
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      overflow: hidden; }
      @media only screen and (min-width: 768px) {
        .content .intro-banner.hp-video-banner.hero .component-content .banner-video, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type {
          height: calc(100vh - 100px);
          min-height: 380px; } }
      @media only screen and (min-width: 992px) {
        .content .intro-banner.hp-video-banner.hero .component-content .banner-video, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type {
          height: calc(100vh - 220px); } }
      .content .intro-banner.hp-video-banner.hero .component-content .banner-video video, .content .intro-banner.hp-video-banner.hero .component-content .banner-video img, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type video, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type img {
        max-width: none;
        height: calc(100vh - 60px);
        min-height: 300px;
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        background-color: black; }
        @media only screen and (min-width: 768px) {
          .content .intro-banner.hp-video-banner.hero .component-content .banner-video video, .content .intro-banner.hp-video-banner.hero .component-content .banner-video img, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type video, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type img {
            height: calc(100vh - 100px);
            min-height: 400px; } }
        @media only screen and (min-width: 1200px) {
          .content .intro-banner.hp-video-banner.hero .component-content .banner-video video, .content .intro-banner.hp-video-banner.hero .component-content .banner-video img, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type video, .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type img {
            height: calc(100% - 100px);
            display: block;
            height: auto;
            min-height: 100%; } }
      .content .intro-banner.hp-video-banner.hero .component-content .banner-video video[data-cat-con], .content .intro-banner.hp-video-banner.hero .component-content > div:first-of-type video[data-cat-con] {
        display: block !important; }
  .content .intro-banner.hp-video-banner.hero .banner-link {
    position: absolute;
    width: 100%;
    bottom: 18px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .content .intro-banner.hp-video-banner.hero .banner-link {
        width: auto;
        bottom: 36px;
        left: 50%;
        transform: translateX(-50%); } }
    .content .intro-banner.hp-video-banner.hero .banner-link button.btn-goto {
      cursor: pointer;
      padding: 0;
      border: none;
      position: relative;
      font-family: "Muli", Arial, sans-serif;
      font-size: 14px;
      background-color: transparent;
      width: 100%; }
      @media only screen and (min-width: 768px) {
        .content .intro-banner.hp-video-banner.hero .banner-link button.btn-goto {
          font-size: 18px; } }
      @media only screen and (min-width: 1400px) {
        .content .intro-banner.hp-video-banner.hero .banner-link button.btn-goto {
          width: 100%;
          background-color: #ffffff; } }
      .content .intro-banner.hp-video-banner.hero .banner-link button.btn-goto:after {
        content: "";
        display: inline-block;
        border-style: none solid solid none;
        width: 12px;
        height: 12px;
        margin: 0 .5em .2em 1em;
        transform: rotate(45deg); }
      .content .intro-banner.hp-video-banner.hero .banner-link button.btn-goto strong, .content .intro-banner.hp-video-banner.hero .banner-link button.btn-goto b {
        font-weight: normal; }
  .content .intro-banner.hp-video-banner.hero .inner-pos-wrapper {
    top: 0;
    transform: none;
    height: 100%;
    width: 100%; }
  .content .intro-banner.hp-video-banner.hero a, .content .intro-banner.hp-video-banner.hero p {
    font-family: "Muli", Arial, sans-serif;
    font-weight: normal;
    color: #FFFFFF; }
  .content .intro-banner.hp-video-banner.hero h1 {
    font-family: "Muli", Arial, sans-serif;
    color: #FFFFFF;
    font-size: 2.4rem; }
    @media (max-width: 820px) and (orientation: landscape) {
      .content .intro-banner.hp-video-banner.hero h1 {
        font-size: 1.8rem; } }
    @media only screen and (min-width: 992px) {
      .content .intro-banner.hp-video-banner.hero h1 {
        font-size: 3.7rem; } }
  .content .intro-banner.hp-video-banner.hero .banner-container {
    top: 0;
    position: relative;
    width: 100%;
    padding: 0 2rem;
    height: 100%;
    line-height: 1.6; }
    @media only screen and (min-width: 992px) {
      .content .intro-banner.hp-video-banner.hero .banner-container {
        overflow: visible;
        height: calc(100vh - 220px);
        min-height: 360px; } }
    @media only screen and (min-width: 992px) {
      .content .intro-banner.hp-video-banner.hero .banner-container .banner-content {
        width: auto;
        text-align: center; } }
    @media only screen and (min-width: 992px) {
      .content .intro-banner.hp-video-banner.hero .banner-container .banner-content [class*="like-"] {
        left: auto;
        transform: none; } }
    .content .intro-banner.hp-video-banner.hero .banner-container p {
      font-size: 1rem;
      margin-top: 1.9rem; }
      @media only screen and (min-width: 1200px) {
        .content .intro-banner.hp-video-banner.hero .banner-container p {
          font-size: 18px;
          line-height: 30px;
          margin-top: 38px; } }
  .content .intro-banner.hp-video-banner.hero .banner-container-inner {
    top: 50%;
    position: relative;
    transform: translateY(-50%); }
    @media only screen and (max-width: 568px) {
      .content .intro-banner.hp-video-banner.hero .banner-container-inner {
        margin: 0; } }
    .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container {
      margin-top: 2.5rem; }
      @media only screen and (min-width: 992px) {
        .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container {
          margin-top: 50px; } }
      @media only screen and (min-width: 1200px) {
        .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container {
          margin-top: 65px; } }
      .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container .like-button-minimal {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        border-radius: 50%;
        height: 84px;
        line-height: 84px;
        padding: 0;
        text-align: center;
        width: 84px;
        font-size: 50px; }
        @media only screen and (min-width: 1200px) {
          .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container .like-button-minimal {
            animation: out 0.7s -0.1s ease-in-out forwards;
            background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
            background-size: 420% 100%;
            background-position: right bottom;
            background-color: #3344f6;
            color: #ffffff;
            transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container .like-button-minimal:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container .like-button-minimal:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
        @media only screen and (min-width: 1200px) {
            .ie .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container .like-button-minimal:hover {
              animation: in 0.7s -0.1s ease-in-out forwards;
              background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
              background-size: 420% 100%;
              background-position: right bottom;
              background-color: #3344f6;
              box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
              color: #3344f6;
              transition: all 0.8s ease-in-out; }
              .ie .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container .like-button-minimal:hover a {
                color: #3344f6;
                transition: all 0.8s ease-in-out; } }
        @media (max-width: 820px) and (orientation: landscape) {
          .content .intro-banner.hp-video-banner.hero .banner-container-inner .play-button-container .like-button-minimal {
            height: 52px;
            line-height: 52px;
            width: 52px;
            font-size: 36px; } }
  @media only screen and (min-width: 1200px) {
    .content .intro-banner.hp-video-banner.hero .inner-pos-wrapper {
      width: 100%; }
    .content .intro-banner.hp-video-banner.hero .intro-banner-content {
      position: absolute;
      bottom: auto; }
      .content .intro-banner.hp-video-banner.hero .intro-banner-content .icon-play {
        font-size: 6rem;
        margin-top: .2em; } }

.content .intro-banner.page-title-banner {
  height: 150px;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .content .intro-banner.page-title-banner {
      height: 220px; } }
  @media only screen and (min-width: 1200px) {
    .content .intro-banner.page-title-banner {
      height: 300px; } }
  .content .intro-banner.page-title-banner.content-overflow .intro-banner-content {
    position: absolute; }
  @media only screen and (max-width: 767px) {
    .content .intro-banner.page-title-banner h1, .content .intro-banner.page-title-banner .like-h2 {
      font-size: 1.6rem; }
    .content .intro-banner.page-title-banner h2, .content .intro-banner.page-title-banner .like-h1 {
      font-size: 1.8rem; } }
  .content .intro-banner.page-title-banner .component-content {
    height: inherit; }
  .content .intro-banner.page-title-banner .outer-pos-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: inherit; }
  .content .intro-banner.page-title-banner .inner-pos-wrapper {
    height: 100%;
    display: flex;
    align-items: center; }
  .content .intro-banner.page-title-banner .intro-banner-image {
    position: relative; }
    .content .intro-banner.page-title-banner .intro-banner-image img {
      max-width: none;
      width: auto;
      height: 150px;
      right: 0;
      position: absolute; }
  @media only screen and (min-width: 768px) {
    .content .intro-banner.page-title-banner .intro-banner-image img {
      height: auto; } }
  @media only screen and (min-width: 1680px) {
    .content .intro-banner.page-title-banner .intro-banner-image img {
      width: 100%; } }

.content .intro-banner.intro-banner-slider {
  display: block;
  margin-bottom: 0; }
  @media only screen and (min-width: 768px) {
    .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-container-inner {
      width: 70%; } }
  @media only screen and (min-width: 1200px) {
    .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content {
      width: 43%; } }
  .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content img {
    width: auto; }
    @media only screen and (min-width: 768px) {
      .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content img {
        display: none; } }
    @media only screen and (min-width: 992px) {
      .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content img {
        display: block;
        height: 80px; } }
    @media only screen and (min-width: 1200px) {
      .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content img {
        height: 110px; } }
  @media only screen and (min-width: 992px) {
    .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content [class*="like-"] {
      left: auto;
      transform: none; } }
  @media only screen and (min-width: 1200px) {
    .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content h2, .content .intro-banner.intro-banner-slider.intro-banner.hero.component .banner-content h3 {
      font-size: 40px;
      margin-bottom: 30px; } }
  .content .intro-banner.intro-banner-slider .intro-banner-content {
    color: #FFFFFF; }
  .content .intro-banner.intro-banner-slider.intro-banner-text-left .intro-banner-content {
    text-align: right; }
  .content .intro-banner.intro-banner-slider.intro-banner-text-right .intro-banner-content {
    text-align: left; }
  .content .intro-banner.intro-banner-slider.intro-banner-text-left .intro-banner-content, .content .intro-banner.intro-banner-slider.intro-banner-text-right .intro-banner-content {
    top: 0;
    bottom: 0; }
  .content .intro-banner.intro-banner-slider.content-overflow .intro-banner-content {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .content .intro-banner.intro-banner-slider.content-overflow.intro-banner-text-left .intro-banner-content, .content .intro-banner.intro-banner-slider.content-overflow.intro-banner-text-right .intro-banner-content {
    width: 100%; }
  @media only screen and (min-width: 768px) {
    .content .intro-banner.intro-banner-slider.intro-banner-text-left .intro-banner-content {
      right: auto; }
    .content .intro-banner.intro-banner-slider.intro-banner-text-right .intro-banner-content {
      left: auto; }
    .content .intro-banner.intro-banner-slider.intro-banner-text-left .intro-banner-content, .content .intro-banner.intro-banner-slider.intro-banner-text-right .intro-banner-content {
      background-color: #1d080f;
      width: 50%; } }
  @media only screen and (min-width: 1200px) {
    .content .intro-banner.intro-banner-slider.intro-banner-text-left .intro-banner-content, .content .intro-banner.intro-banner-slider.intro-banner-text-right .intro-banner-content {
      background: linear-gradient(to right, rgba(29, 8, 15, 0) 0%, rgba(29, 8, 15, 0.25) 25%, rgba(29, 8, 15, 0.5) 50%, rgba(29, 8, 15, 0.75) 75%, #1d080f 100%); } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .content .intro-banner.intro-banner-slider .intro-banner-text {
      padding-right: 4rem; } }
  @media only screen and (max-width: 767px) {
    .content .intro-banner.intro-banner-slider .component-content .svg {
      display: none; }
    .content .intro-banner.intro-banner-slider .component-content .banner-container {
      position: relative;
      top: 0; }
      .content .intro-banner.intro-banner-slider .component-content .banner-container .banner-content {
        text-align: left;
        padding-top: 1.5rem; }
        .content .intro-banner.intro-banner-slider .component-content .banner-container .banner-content img {
          max-width: 100%;
          max-height: 50px; }
    .content .intro-banner.intro-banner-slider .intro-banner-content {
      padding-top: 3rem;
      padding-bottom: 3rem;
      position: static; }
    .content .intro-banner.intro-banner-slider.intro-banner-text-left .intro-banner-content, .content .intro-banner.intro-banner-slider.intro-banner-text-right .intro-banner-content {
      width: 100%; }
    .content .intro-banner.intro-banner-slider.intro-banner-text-right .intro-banner-content {
      text-align: left; } }

.content .intro-banner.no-image .intro-banner-content {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.content .intro-banner.no-image .inner-pos-wrapper {
  width: 100%; }

.content .intro-banner.no-image.content-overflow .intro-banner-content br {
  display: inline-block; }

.content .intro-banner.btn-video-banner .banner-container {
  top: 0; }
  .content .intro-banner.btn-video-banner .banner-container .banner-container-inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (min-width: 992px) {
      .content .intro-banner.btn-video-banner .banner-container .banner-container-inner .banner-content {
        width: auto; } }
    .content .intro-banner.btn-video-banner .banner-container .banner-container-inner .banner-content .like-button-minimal {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      border-radius: 50%;
      height: 42px;
      line-height: 42px;
      padding: 0;
      text-align: center;
      width: 42px; }
      @media only screen and (min-width: 1200px) {
        .content .intro-banner.btn-video-banner .banner-container .banner-container-inner .banner-content .like-button-minimal {
          animation: out 0.7s -0.1s ease-in-out forwards;
          background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
          background-size: 420% 100%;
          background-position: right bottom;
          background-color: #3344f6;
          color: #ffffff;
          transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .content .intro-banner.btn-video-banner .banner-container .banner-container-inner .banner-content .like-button-minimal:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .content .intro-banner.btn-video-banner .banner-container .banner-container-inner .banner-content .like-button-minimal:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
      @media only screen and (min-width: 1200px) {
          .ie .content .intro-banner.btn-video-banner .banner-container .banner-container-inner .banner-content .like-button-minimal:hover {
            animation: in 0.7s -0.1s ease-in-out forwards;
            background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
            background-size: 420% 100%;
            background-position: right bottom;
            background-color: #3344f6;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
            color: #3344f6;
            transition: all 0.8s ease-in-out; }
            .ie .content .intro-banner.btn-video-banner .banner-container .banner-container-inner .banner-content .like-button-minimal:hover a {
              color: #3344f6;
              transition: all 0.8s ease-in-out; } }

.content .intro-banner.btn-video-banner .intro-banner-content {
  position: absolute; }
  .content .intro-banner.btn-video-banner .intro-banner-content p {
    font-size: 1rem; }
  .content .intro-banner.btn-video-banner .intro-banner-content .icon-play {
    display: inline-block;
    font-size: 5rem;
    top: 50%;
    left: 50%;
    position: absolute;
    margin-top: -35px;
    margin-left: -35px; }
    @media only screen and (max-width: 767px) {
      .content .intro-banner.btn-video-banner .intro-banner-content .icon-play {
        font-size: 2.5rem;
        margin-top: -17.5px;
        margin-left: -17.5px; } }

.content .intro-banner.btn-video-banner .intro-banner-text {
  text-align: center;
  width: 100%;
  position: absolute;
  height: inherit; }

@media only screen and (min-width: 992px) {
  .content .intro-banner.intro-banner-text-right .banner-container-inner .banner-content {
    margin-left: auto; } }

@media only screen and (min-width: 1400px) {
  .content .intro-banner.intro-banner-text-right .banner-container-inner .banner-content {
    width: 40%; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right .banner-container-inner {
    max-width: unset; } }

@media only screen and (min-width: 992px) {
  .content .intro-banner.intro-banner-text-right.hero .svg {
    opacity: 0.8;
    left: auto;
    right: -10%; } }

@media only screen and (min-width: 1400px) {
  .content .intro-banner.intro-banner-text-right.hero .svg {
    width: 1200px;
    height: 1200px; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero .svg {
    width: 1400px;
    height: 1400px;
    right: -5%; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero h1 {
    font-size: 71px; } }

.content .intro-banner.intro-banner-text-right.hero.landing-page .svg {
  z-index: 1;
  bottom: -15px;
  opacity: 0.8; }
  @media only screen and (min-width: 992px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .svg {
      left: auto;
      right: -25%;
      bottom: -45%; } }
  @media only screen and (min-width: 1400px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .svg {
      width: 1400px;
      height: 1400px;
      left: 32%; } }
  @media only screen and (min-width: 1920px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .svg {
      left: 35%; } }
  .content .intro-banner.intro-banner-text-right.hero.landing-page .svg + div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    @media only screen and (min-width: 1920px) {
      .content .intro-banner.intro-banner-text-right.hero.landing-page .svg + div {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        right: auto; } }
    .content .intro-banner.intro-banner-text-right.hero.landing-page .svg + div img {
      object-fit: cover;
      min-width: 100%;
      height: 100%; }
      @media only screen and (min-width: 768px) {
        .content .intro-banner.intro-banner-text-right.hero.landing-page .svg + div img {
          width: 100%; } }
      @media only screen and (min-width: 1920px) {
        .content .intro-banner.intro-banner-text-right.hero.landing-page .svg + div img {
          object-fit: none;
          height: auto; } }

.content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container {
  overflow: auto;
  position: relative;
  z-index: 2;
  top: auto;
  padding: 3rem 0;
  min-height: 200px; }
  @media only screen and (min-width: 768px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container {
      min-height: 300px; } }
  @media only screen and (min-width: 992px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container {
      min-height: 380px; } }
  @media only screen and (min-width: 1920px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container {
      position: absolute;
      top: 0;
      height: 100%;
      overflow: hidden; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media only screen and (min-width: 1400px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner {
    width: 82rem;
    max-width: 80%; } }

@media only screen and (min-width: 1400px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content {
    width: 50%; } }

.content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content h1 {
  margin-top: 20px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content h1 {
      font-size: 52px; } }
  @media only screen and (min-width: 992px) {
    .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content h1 {
      margin-top: 0; } }

.content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content h2 {
  color: #74cecd;
  font-weight: 600; }

@media only screen and (min-width: 1400px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content p {
    font-size: 18px;
    line-height: 28px; } }

@media only screen and (min-width: 1400px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content p + p {
    margin-top: 30px; } }

@media only screen and (min-width: 992px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page .banner-container-inner .banner-content [class*="like-"] {
    left: auto;
    transform: none; } }

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page.demant-worldwide .svg {
    top: -20vw; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page.demant-worldwide .svg {
    width: 1500px;
    height: 1500px; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page:not(.demant-worldwide) .svg {
    right: -5%;
    left: auto;
    bottom: 60px; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page:not(.demant-worldwide) + div img {
    width: 100%;
    height: auto;
    display: block; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page:not(.demant-worldwide) .banner-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    padding: 0;
    min-height: unset;
    height: auto; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page:not(.demant-worldwide) .banner-content {
    width: 40%; } }

@media only screen and (min-width: 1920px) {
  .content .intro-banner.intro-banner-text-right.hero.landing-page:not(.demant-worldwide) .banner-container-inner {
    top: 30%; } }

.content .js-intro-banner-slider {
  position: relative;
  margin-bottom: 3rem; }
  @media only screen and (min-width: 768px) {
    .content .js-intro-banner-slider {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1600px; }
      .content .js-intro-banner-slider.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .js-intro-banner-slider.regular {
        width: 86%;
        max-width: 1200px; }
      .content .js-intro-banner-slider.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .content .js-intro-banner-slider .component.intro-banner.hero .svg {
      bottom: -4rem; } }
  .content .js-intro-banner-slider .slick-arrow {
    position: absolute;
    top: 22%;
    z-index: 1;
    border: 2px solid transparent;
    background-color: #FFFFFF;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 0;
    cursor: pointer; }
    @media only screen and (max-width: 479px) {
      .content .js-intro-banner-slider .slick-arrow {
        top: 18%; } }
    @media only screen and (min-width: 768px) {
      .content .js-intro-banner-slider .slick-arrow {
        top: 50%;
        transform: translateY(-50%);
        background-color: #74cecd; } }
    .content .js-intro-banner-slider .slick-arrow:before {
      font-family: "icomoon";
      color: #24405b;
      font-size: 1rem;
      display: block; }
      @media only screen and (min-width: 768px) {
        .content .js-intro-banner-slider .slick-arrow:before {
          color: #FFFFFF; } }
    .content .js-intro-banner-slider .slick-arrow.slick-next {
      right: 1.5rem; }
      .content .js-intro-banner-slider .slick-arrow.slick-next:before {
        content: ""; }
    .content .js-intro-banner-slider .slick-arrow.slick-prev {
      left: 1.5rem; }
      .content .js-intro-banner-slider .slick-arrow.slick-prev:before {
        content: ""; }
  @media only screen and (min-width: 768px) {
    .content .js-intro-banner-slider .slick-arrow {
      width: 50px;
      height: 50px;
      border-width: 3px; }
      .content .js-intro-banner-slider .slick-arrow:before {
        font-size: 1.5rem; }
      .content .js-intro-banner-slider .slick-arrow.slick-next {
        right: 3rem; }
        .content .js-intro-banner-slider .slick-arrow.slick-next:before {
          content: ""; }
      .content .js-intro-banner-slider .slick-arrow.slick-prev {
        left: 3rem; }
        .content .js-intro-banner-slider .slick-arrow.slick-prev:before {
          content: ""; } }

@media only screen and (max-width: 767px) {
  #content .intro-banner.content-overflow .intro-banner-content {
    padding-bottom: 3rem;
    padding-top: 3rem; }
    #content .intro-banner.content-overflow .intro-banner-content br {
      display: none; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
@media only screen and (min-width: 1200px) {
  .content .joblist {
    margin-bottom: 0; } }

.joblist .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  @media only screen and (min-width: 768px) {
    .joblist .component-content:after {
      content: "";
      display: table;
      clear: both; } }
  .joblist .component-content button {
    font-family: "Muli", Arial, sans-serif;
    background: transparent;
    border: 0;
    cursor: pointer;
    transition: background 0.4s, color 0.4s; }

.joblist__header {
  text-align: center;
  margin-bottom: 2rem; }
  @media only screen and (max-width: 767px) {
    .joblist__header {
      letter-spacing: -1.35px; } }
  @media only screen and (min-width: 1200px) {
    .joblist__header {
      margin-bottom: 4rem; } }
  .joblist__header h2 {
    font-weight: 300;
    margin-bottom: 0; }
    @media only screen and (min-width: 768px) {
      .joblist__header h2 {
        font-size: 20px; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    .joblist__header h2 {
      font-size: 30px; } }
    .joblist__header h2 + p {
      margin-top: 0;
      font-size: 22px;
      font-weight: 300; }
      @media only screen and (min-width: 768px) {
        .joblist__header h2 + p {
          font-size: 26px; } }
      @media only screen and (min-width: 1200px) {
        .joblist__header h2 + p {
          font-size: 30px; } }

.joblist ul {
  list-style: none; }

.joblist li {
  text-indent: inherit;
  padding-left: 0;
  margin: 0;
  padding: 0; }

.joblist__tabs {
  margin: 3rem 0; }
  @media only screen and (max-width: 767px) {
    .joblist__tabs {
      display: none; } }
  @media only screen and (min-width: 1200px) {
    .joblist__tabs {
      margin-bottom: 5rem; } }
  .joblist__tabs ul {
    width: auto;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .joblist__tabs ul li {
      text-align: center;
      margin: .5rem 0; }
      .joblist__tabs ul li button {
        color: #5b7085;
        font-weight: 700; }
        @media only screen and (max-width: 767px) {
          .joblist__tabs ul li button {
            padding-left: 15px;
            padding-right: 15px; } }
        @media only screen and (min-width: 768px) {
          .joblist__tabs ul li button {
            font-size: 16px;
            height: auto;
            line-height: normal;
            padding: 15px 30px;
            border-radius: 30px;
            background: transparent; } }
        @media only screen and (min-width: 1200px) {
          .joblist__tabs ul li button {
            text-transform: uppercase;
            margin-left: .5rem;
            margin-right: .5rem; }
            .joblist__tabs ul li button:hover {
              background: #3344f6;
              color: #ffffff; } }
        .joblist__tabs ul li button.is-active {
          background: #3344f6;
          color: #ffffff; }

.joblist__menu {
  padding-bottom: 1rem; }
  .joblist__menu-header {
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 0; }
  .joblist__menu li {
    display: block;
    margin: 1px;
    width: 100%; }
  .joblist__menu button {
    border-radius: 0;
    text-transform: uppercase;
    font-size: .8rem;
    padding: .5rem 2.5rem .5rem 0;
    width: 100%;
    text-align: left; }
    @media only screen and (min-width: 1200px) {
      .joblist__menu button:hover {
        color: #3344f6; } }
    .joblist__menu button.is-active {
      position: relative;
      color: #3344f6;
      font-weight: 600; }
      .joblist__menu button.is-active:after {
        font-family: "icomoon";
        content: "";
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%); }
  @media only screen and (max-width: 767px) {
    .joblist__menu.is-open .joblist__menu-header:after {
      transform: rotate(-135deg);
      margin-bottom: -.2em; }
    .joblist__menu.is-open .joblist__menu-list {
      display: block; }
    .joblist__menu-header {
      cursor: pointer;
      text-align: center; }
      .joblist__menu-header:after {
        content: "";
        color: #05CDCD;
        display: inline-block;
        border-style: none solid solid none;
        width: 12px;
        height: 12px;
        margin-left: 1em;
        margin-bottom: .2em;
        transform: rotate(45deg); }
    .joblist__menu-list {
      display: none; } }
  @media only screen and (min-width: 768px) {
    .joblist__menu {
      width: 15rem;
      float: left; } }
  @media only screen and (min-width: 992px) {
    .joblist__menu {
      width: 18rem; } }

@media only screen and (min-width: 768px) {
  .joblist__list {
    width: calc(100% - 17rem);
    margin-left: 17rem; } }

@media only screen and (min-width: 992px) {
  .joblist__list {
    width: calc(100% - 22rem);
    margin-left: 22rem; } }

.joblist__offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; }
  .joblist__list .joblist__offer {
    padding-left: 70px;
    margin: 1rem 0 1.5rem;
    min-height: 60px; }
  .joblist__offer.is-hidden {
    display: none; }
  .joblist__offer-image {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding: .25rem; }
    .joblist__offer-image img {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .joblist__offer-title {
    font-size: .8rem;
    text-transform: uppercase; }
  .joblist__offer-name a {
    color: inherit;
    font-weight: 700;
    font-size: 17px;
    text-decoration: none; }
    @media only screen and (min-width: 1200px) {
      .joblist__offer-name a:hover {
        color: #3344f6; } }
  .joblist__offer-localisation {
    font-size: 1rem;
    position: relative; }
  @media only screen and (min-width: 992px) {
    .joblist__list .joblist__offer {
      padding-left: 100px;
      min-height: 80px; }
    .joblist__offer-name {
      font-size: 1.4rem; }
    .joblist__offer-image {
      width: 80px;
      height: 80px; } }
  @media only screen and (min-width: 1200px) {
    .joblist__list .joblist__offer {
      padding-left: 120px;
      min-height: 100px; }
    .joblist__offer-image {
      width: 100px;
      height: 100px; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.joboffer-select {
  background-color: #ededea; }
  .joboffer-select * {
    font-family: "Muli", Arial, sans-serif; }
  .joboffer-select h2 {
    font-weight: normal;
    font-size: 20px; }
    @media only screen and (min-width: 768px) {
      .joboffer-select h2 {
        font-size: 30px; } }
  .joboffer-select .component-content {
    margin-left: 30px;
    margin-right: 30px;
    padding: 3rem 0; }
    @media only screen and (min-width: 768px) {
      .joboffer-select .component-content {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .joboffer-select .component-content.full-width {
          width: 100%;
          max-width: 1600px; }
        .joboffer-select .component-content.regular {
          width: 86%;
          max-width: 1200px; }
        .joboffer-select .component-content.slim {
          width: 47.2%;
          max-width: 660px; } }
    @media only screen and (min-width: 1200px) {
      .joboffer-select .component-content {
        padding: 5rem 0; } }
  .joboffer-select .joboffer-select__controls {
    margin: 1rem 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    @media only screen and (min-width: 768px) {
      .joboffer-select .joboffer-select__controls {
        margin-top: 30px; } }
    .joboffer-select .joboffer-select__controls .nice-select, .joboffer-select .joboffer-select__controls button, .joboffer-select .joboffer-select__controls select {
      max-width: 22rem;
      min-width: 230px;
      margin: .5rem .25rem; }
      @media only screen and (min-width: 768px) {
        .joboffer-select .joboffer-select__controls .nice-select, .joboffer-select .joboffer-select__controls button, .joboffer-select .joboffer-select__controls select {
          margin: .5rem 1rem;
          max-width: unset; } }
      @media only screen and (min-width: 992px) {
        .joboffer-select .joboffer-select__controls .nice-select, .joboffer-select .joboffer-select__controls button, .joboffer-select .joboffer-select__controls select {
          min-width: 280px; } }
      @media only screen and (min-width: 1200px) {
        .joboffer-select .joboffer-select__controls .nice-select, .joboffer-select .joboffer-select__controls button, .joboffer-select .joboffer-select__controls select {
          min-width: 311px; } }
    .joboffer-select .joboffer-select__controls button {
      font-size: 12px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: 700;
      font-family: "Muli", sans-serif;
      border-radius: 19.5px;
      padding: 11px 26px 12px; }
      @media only screen and (min-width: 1200px) {
        .joboffer-select .joboffer-select__controls button {
          animation: out 0.7s -0.1s ease-in-out forwards;
          background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
          background-size: 420% 100%;
          background-position: right bottom;
          background-color: #3344f6;
          color: #ffffff;
          transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .joboffer-select .joboffer-select__controls button:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .joboffer-select .joboffer-select__controls button:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
      @media only screen and (min-width: 1200px) {
          .ie .joboffer-select .joboffer-select__controls button:hover {
            animation: in 0.7s -0.1s ease-in-out forwards;
            background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
            background-size: 420% 100%;
            background-position: right bottom;
            background-color: #3344f6;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
            color: #3344f6;
            transition: all 0.8s ease-in-out; }
            .ie .joboffer-select .joboffer-select__controls button:hover a {
              color: #3344f6;
              transition: all 0.8s ease-in-out; } }
      @media only screen and (min-width: 768px) {
        .joboffer-select .joboffer-select__controls button {
          font-size: 16px; } }
      .joboffer-select .joboffer-select__controls button a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .joboffer-select .joboffer-select__controls button {
          border-radius: 25px;
          padding: 12px 35px 13px; } }
      @media only screen and (min-width: 1200px) {
        .joboffer-select .joboffer-select__controls button {
          font-size: 18px; } }
      @media only screen and (min-width: 1200px) {
        .joboffer-select .joboffer-select__controls button {
          padding-left: 29px;
          padding-right: 29px; } }
    .joboffer-select .joboffer-select__controls select {
      font-weight: 700;
      line-height: 1.1;
      padding: 9px 40px 9px 31px;
      width: 230px;
      height: 45px; }
      @media only screen and (max-width: 767px) {
        .joboffer-select .joboffer-select__controls select {
          font-size: 12px;
          height: 38px;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          padding: 11px 40px 9px 22px; } }
      @media only screen and (min-width: 1200px) {
        .joboffer-select .joboffer-select__controls select {
          height: 48px;
          width: 311px; } }
  .joboffer-select .nice-select, .joboffer-select select {
    max-width: 23rem;
    border-color: #24405b;
    color: #24405b;
    border-width: 1px;
    border-radius: 19.5px;
    margin: 1rem .25rem;
    height: auto;
    padding: 10px 40px 10px 26px; }
    @media only screen and (max-width: 767px) {
      .joboffer-select .nice-select, .joboffer-select select {
        white-space: normal; } }
    @media only screen and (min-width: 768px) {
      .joboffer-select .nice-select, .joboffer-select select {
        font-size: 15px;
        border-radius: 25px;
        padding: 14px 55px 9px 35px; } }
    @media only screen and (min-width: 1200px) {
      .joboffer-select .nice-select, .joboffer-select select {
        font-size: 18px; } }
    .joboffer-select .nice-select:after, .joboffer-select select:after {
      border-color: #3344f6;
      border-style: none solid solid none;
      border-width: 3px;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      right: 23px; }
    .joboffer-select .nice-select.open, .joboffer-select select.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .joboffer-select .nice-select > .list, .joboffer-select select > .list {
      max-height: 50vh;
      overflow-y: auto;
      box-shadow: 0 0 0 1px #A9A9A9; }
      .joboffer-select .nice-select > .list .option, .joboffer-select select > .list .option {
        white-space: normal;
        line-height: 1.2;
        padding-top: 0.6rem;
        padding-bottom: 0.8rem; }
    .joboffer-select .nice-select > .current, .joboffer-select select > .current {
      font-weight: 700;
      line-height: 1.1; }
      @media only screen and (max-width: 767px) {
        .joboffer-select .nice-select > .current, .joboffer-select select > .current {
          font-size: 12px; } }

.joboffer {
  overflow: hidden; }
  .joboffer * {
    font-family: "Muli", Arial, sans-serif; }
  .joboffer p + p {
    margin: 0; }
  .joboffer img {
    max-width: 100%; }
  .joboffer a {
    cursor: pointer; }
  .joboffer .component-content {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      .joboffer .component-content {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .joboffer .component-content.full-width {
          width: 100%;
          max-width: 1600px; }
        .joboffer .component-content.regular {
          width: 86%;
          max-width: 1200px; }
        .joboffer .component-content.slim {
          width: 47.2%;
          max-width: 660px; } }
    @media only screen and (max-width: 992px) {
      .joboffer .component-content {
        display: flex;
        flex-direction: column; } }
    @media only screen and (min-width: 992px) {
      .joboffer .component-content {
        position: relative; } }
  @media only screen and (max-width: 992px) {
    .joboffer-content {
      margin-bottom: 4rem; } }
  .joboffer-content small {
    font-size: 10px;
    line-height: 1.1; }
    @media only screen and (min-width: 768px) {
      .joboffer-content small {
        font-size: 12px; } }
  .joboffer-content__header h2 {
    font-weight: 700;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 1200px) {
      .joboffer-content__header h2 {
        font-size: 40px;
        margin-bottom: 2rem; } }
  .joboffer-content__text {
    padding-top: 2rem; }
    .joboffer-content__text ul {
      list-style-type: disc; }
      .joboffer-content__text ul + ul {
        margin-top: 0; }
    @media only screen and (min-width: 768px) {
      .joboffer-content__text {
        padding-left: 1rem; } }
    @media only screen and (min-width: 1200px) {
      .joboffer-content__text {
        padding-top: 3rem; } }
    .joboffer-content__textHeading, .joboffer-content__text h2, .joboffer-content__text h3, .joboffer-content__text h4 {
      font-size: 12px;
      font-weight: 700;
      color: #0084a9; }
      @media only screen and (min-width: 768px) {
        .joboffer-content__textHeading, .joboffer-content__text h2, .joboffer-content__text h3, .joboffer-content__text h4 {
          font-size: 16px; } }
  .joboffer-content__media {
    position: relative;
    display: block; }
    @media only screen and (max-width: 767px) {
      .joboffer-content__media {
        margin-left: -1rem;
        margin-right: -1rem; } }
    .joboffer-content__media.icon-play:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: 'play_arrow';
      font-size: 12px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: 700;
      font-family: "Muli", sans-serif;
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      font-size: 24px;
      border-radius: 50%;
      height: 42px;
      line-height: 42px;
      padding: 0;
      text-align: center;
      width: 42px; }
      @media only screen and (min-width: 768px) {
        .joboffer-content__media.icon-play:before {
          font-size: 16px; } }
      .joboffer-content__media.icon-play:before a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .joboffer-content__media.icon-play:before {
          font-size: 24px; } }

@media only screen and (hover) and (min-width: 1200px) {
  .joboffer-content__media.icon-play:hover.icon-play:before {
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    color: #3344f6;
    transition: all 0.4s ease-in-out; } }
  @media only screen and (hover) and (min-width: 1200px) and (hover) {
    .joboffer-content__media.icon-play:hover.icon-play:before:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      .joboffer-content__media.icon-play:hover.icon-play:before:hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; } }

@media only screen and (hover) and (min-width: 1200px) {
    .ie .joboffer-content__media.icon-play:hover.icon-play:before:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      .ie .joboffer-content__media.icon-play:hover.icon-play:before:hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 992px) {
    .joboffer-content {
      padding-right: 23rem; } }
  @media only screen and (max-width: 992px) {
    .joboffer-benefits {
      order: 1; } }
  @media only screen and (min-width: 992px) {
    .joboffer-benefits {
      padding-right: 23rem;
      margin-top: 5rem; } }
  .joboffer-benefits__container {
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .joboffer-benefits__container:nth-of-type(2) .joboffer-benefits__element {
        width: 50%; }
      .joboffer-benefits__container:nth-of-type(2) .joboffer-benefits__el-image {
        width: 66%;
        height: auto;
        padding-bottom: 0; } }
  .joboffer-benefits__header {
    line-height: 1.2;
    margin-top: 4rem; }
  .joboffer-benefits__element {
    text-indent: 0;
    padding-left: 0;
    margin-top: 0;
    width: 33%;
    padding: .5rem;
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .joboffer-benefits__element {
        width: 25%;
        padding: 1rem; } }
  .joboffer-benefits__el-image {
    overflow: hidden;
    position: relative;
    margin: 1em auto; }
    .joboffer-benefits__el-image--icon {
      text-align: center; }
      .joboffer-benefits__el-image--icon img {
        display: inline-block; }
    .joboffer-benefits__el-image--fill img {
      border-radius: 50%;
      width: 100%; }
  .joboffer-benefits__el-title {
    margin-top: 1rem;
    font-weight: 600; }
  .joboffer-benefits__el-link {
    display: block;
    text-decoration: none;
    color: inherit; }
    @media only screen and (max-width: 767px) {
      .joboffer-benefits__el-link {
        font-size: 13px; } }
  .joboffer-benefits .joboffer-benefits__show-more {
    color: #3344f6;
    font-weight: bold;
    border: 0;
    padding: 0;
    background-color: transparent;
    display: block; }
  .joboffer-aside {
    position: relative;
    padding: 1rem 0; }
    @media only screen and (max-width: 767px) {
      .joboffer-aside {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
    @media only screen and (min-width: 768px) {
      .joboffer-aside {
        background-color: #ededea;
        border-radius: 15px; } }
    @media only screen and (min-width: 992px) {
      .joboffer-aside {
        background-color: transparent;
        padding: 1rem 1.5rem;
        position: absolute;
        top: 0;
        right: 0;
        width: 21rem; } }
    @media only screen and (min-width: 1200px) {
      .joboffer-aside {
        padding: 0; } }
    .joboffer-aside__block-1, .joboffer-aside__block-2 {
      background-color: #ededea;
      border-radius: 15px;
      max-width: 20rem;
      margin: 0 auto;
      padding: 2rem;
      position: relative;
      text-align: center; }
      @media only screen and (max-width: 767px) {
        .joboffer-aside__block-1, .joboffer-aside__block-2 {
          padding-left: 1.5rem;
          padding-right: 1.5rem; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .joboffer-aside__block-1, .joboffer-aside__block-2 {
          width: 33.33%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .joboffer-aside__block-1 {
        float: left; } }
    @media only screen and (min-width: 992px) {
      .joboffer-aside__block-1 {
        padding: 2rem; } }
    .joboffer-aside__block-1 > div {
      text-align: left; }
    .joboffer-aside__block-2 {
      margin-top: 5px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .joboffer-aside__block-2 {
          float: right; } }
      @media only screen and (min-width: 992px) {
        .joboffer-aside__block-2 {
          padding: 1rem 1rem 2rem; } }
    .joboffer-aside__title {
      font-size: .8rem;
      text-transform: uppercase;
      color: #013e5d; }
      @media only screen and (min-width: 992px) {
        .joboffer-aside__title {
          font-size: 1rem; } }
    .joboffer-aside__name {
      font-size: 1.4rem;
      margin-bottom: 1rem; }
      @media only screen and (min-width: 1200px) {
        .joboffer-aside__name {
          font-size: 1.8rem; } }
    .joboffer-aside__location-title {
      font-size: 1rem; }
    .joboffer-aside__location {
      font-weight: bold;
      position: relative;
      font-size: 1.2rem; }
      @media only screen and (max-width: 767px) {
        .joboffer-aside__location {
          font-size: 1.0rem; } }
    .joboffer-aside__buttons {
      display: flex;
      align-items: center;
      column-gap: 1rem; }
      @media only screen and (max-width: 767px) {
        .joboffer-aside__buttons {
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 1; } }
    .joboffer-aside__cta {
      font-size: 12px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: 700;
      font-family: "Muli", sans-serif;
      border-radius: 19.5px;
      padding: 11px 26px 12px; }
      @media only screen and (min-width: 1200px) {
        .joboffer-aside__cta {
          animation: out 0.7s -0.1s ease-in-out forwards;
          background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
          background-size: 420% 100%;
          background-position: right bottom;
          background-color: #3344f6;
          color: #ffffff;
          transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .joboffer-aside__cta:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .joboffer-aside__cta:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; } }
      @media only screen and (min-width: 1200px) {
          .ie .joboffer-aside__cta:hover {
            animation: in 0.7s -0.1s ease-in-out forwards;
            background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
            background-size: 420% 100%;
            background-position: right bottom;
            background-color: #3344f6;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
            color: #3344f6;
            transition: all 0.8s ease-in-out; }
            .ie .joboffer-aside__cta:hover a {
              color: #3344f6;
              transition: all 0.8s ease-in-out; } }
      @media only screen and (min-width: 768px) {
        .joboffer-aside__cta {
          font-size: 16px; } }
      .joboffer-aside__cta a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .joboffer-aside__cta {
          border-radius: 25px;
          padding: 12px 35px 13px; } }
      @media only screen and (min-width: 1200px) {
        .joboffer-aside__cta {
          font-size: 18px; } }
      .joboffer-aside__cta--phone {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center; }
        .joboffer-aside__cta--phone:before {
          content: 'phone';
          font-family: "Material Icons";
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          font-feature-settings: "liga";
          -webkit-font-feature-settings: "liga";
          -webkit-font-smoothing: antialiased; }
        @media only screen and (min-width: 768px) {
          .joboffer-aside__cta--phone {
            display: none; } }
    .joboffer-aside__cta-2 {
      font-size: 12px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: 700;
      font-family: "Muli", sans-serif;
      border-radius: 19.5px;
      padding: 11px 26px 12px;
      color: #3344f6;
      background-color: #ffffff; }
      @media only screen and (min-width: 1200px) {
        .joboffer-aside__cta-2 {
          animation: out 0.7s -0.1s ease-in-out forwards;
          background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
          background-size: 420% 100%;
          background-position: right bottom;
          background-color: #ffffff;
          color: #3344f6;
          transition: all 0.4s ease-in-out; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .joboffer-aside__cta-2:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      .joboffer-aside__cta-2:hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; } }
      @media only screen and (min-width: 1200px) {
          .ie .joboffer-aside__cta-2:hover {
            animation: in 0.7s -0.1s ease-in-out forwards;
            background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
            background-size: 420% 100%;
            background-position: right bottom;
            background-color: #ffffff;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
            color: #ffffff;
            transition: all 0.8s ease-in-out; }
            .ie .joboffer-aside__cta-2:hover a {
              color: #ffffff;
              transition: all 0.8s ease-in-out; } }
      @media only screen and (min-width: 768px) {
        .joboffer-aside__cta-2 {
          font-size: 16px; } }
      .joboffer-aside__cta-2 a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .joboffer-aside__cta-2 {
          border-radius: 25px;
          padding: 12px 35px 13px; } }
      @media only screen and (min-width: 1200px) {
        .joboffer-aside__cta-2 {
          font-size: 18px; } }
      .joboffer-aside__cta-2 a {
        color: #3344f6; }
    .joboffer-aside__cta, .joboffer-aside__cta-2 {
      margin-top: 1.5rem; }
      @media only screen and (max-width: 767px) {
        .joboffer-aside__cta, .joboffer-aside__cta-2 {
          font-size: 1.0rem; } }
    .joboffer-aside__title-2 {
      color: #013e5d;
      font-size: 1.4rem;
      line-height: 1.4;
      margin-bottom: 2rem; }
      @media only screen and (min-width: 1200px) {
        .joboffer-aside__title-2 {
          font-size: 1.8rem;
          margin-bottom: 2rem; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .joboffer-aside__recruiter {
        position: absolute;
        width: 100%;
        left: -100%;
        top: 0; } }
    .joboffer-aside__recruiter-name {
      font-weight: bold;
      font-size: 1.2rem; }
      @media only screen and (max-width: 767px) {
        .joboffer-aside__recruiter-name {
          font-size: 1.0rem; } }
    .joboffer-aside__recruiter .media-container {
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      padding-bottom: 80%;
      width: 80%;
      margin: 1rem auto; }
      @media only screen and (max-width: 767px) {
        .joboffer-aside__recruiter .media-container {
          padding-bottom: 65%;
          width: 65%; } }
      .joboffer-aside__recruiter .media-container img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        max-width: 100%;
        height: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .joboffer-aside:after {
        content: "";
        display: table;
        clear: both; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
@media only screen and (min-width: 1200px) {
  #content .component.rich-text .component-content {
    padding-bottom: 1rem;
    padding-top: 3rem; }
    #content .component.rich-text .component-content.like-section-title {
      padding-top: 0; } }

@media only screen and (min-width: 1400px) {
  #content .component.rich-text .component-content {
    max-width: 1200px; } }

#content .component.rich-text .component-content h2, #content .component.rich-text .component-content h3 {
  font-weight: 300; }

#content .component.rich-text .component-content ul, #content .component.rich-text .component-content ol {
  padding-left: 2rem; }

#content .component.rich-text .component-content ul {
  list-style-type: disc; }

#content .component.rich-text .component-content ol {
  list-style-type: decimal; }

@media only screen and (min-width: 1200px) {
  #content .component.rich-text.bg-color-2 .component-content {
    padding: 0; } }

@media only screen and (max-width: 767px) {
  #content .component.rich-text.warm-gray-bg.text-charcoal-blue.white-buttons {
    margin-bottom: 0; } }

#content .component.rich-text.warm-gray-bg.text-charcoal-blue.white-buttons .component-content {
  padding-top: 1.5rem; }
  @media only screen and (min-width: 768px) {
    #content .component.rich-text.warm-gray-bg.text-charcoal-blue.white-buttons .component-content {
      padding-top: 2rem; } }
  @media only screen and (min-width: 1200px) {
    #content .component.rich-text.warm-gray-bg.text-charcoal-blue.white-buttons .component-content {
      padding-top: 3rem; } }

#content .component.rich-text.green-blue-gradient-bg.white-txt.empty.extra-padding-lg {
  margin-bottom: 40px; }
  #content .component.rich-text.green-blue-gradient-bg.white-txt.empty.extra-padding-lg .component-content {
    padding: 5.4rem 0 2rem; }
    @media only screen and (min-width: 992px) {
      #content .component.rich-text.green-blue-gradient-bg.white-txt.empty.extra-padding-lg .component-content {
        padding-bottom: 5.4rem; } }
    @media only screen and (min-width: 1200px) {
      #content .component.rich-text.green-blue-gradient-bg.white-txt.empty.extra-padding-lg .component-content {
        padding: 6.4rem 0; } }

@media only screen and (min-width: 1200px) {
  #content .component.rich-text.extra-padding-lg .component-content {
    padding-bottom: 6rem;
    padding-top: 6rem; } }

#content .component.rich-text.center.middle .component-content {
  text-align: center; }
  #content .component.rich-text.center.middle .component-content * {
    text-align: center; }
  #content .component.rich-text.center.middle .component-content .like-button {
    display: inline-block;
    margin: 0 auto; }

#content .component.rich-text.iright .component-content * {
  text-align: right; }

@media only screen and (min-width: 768px) {
  #content .component.rich-text.like-section-title .component-content {
    padding-bottom: 1rem;
    padding-top: 1rem; } }

#content .component.rich-text.like-section-title.empty .component-content {
  padding-bottom: 2rem; }

@media only screen and (min-width: 1400px) {
  #content .component.rich-text.slim .component-content {
    width: 58rem; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.ui-helper-hidden-accessible > div {
  display: none; }

body .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content {
  list-style: none;
  border-top: none;
  background-color: white;
  box-shadow: #bababa 0 1px 0px;
  z-index: 999; }
  body .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content li {
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    padding: 0.5rem 1rem;
    text-indent: 0;
    background-color: transparent;
    transition: background-color 0.3s ease-out; }
    body .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content li:last-child {
      border-bottom: none; }
    body .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content li:hover {
      background-color: #eaeaea; }
    body .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content li + li {
      margin-top: 0; }

#content .search-result-spot * {
  font-family: "Muli", Arial, sans-serif; }

#content .search-result-spot .component-content {
  overflow: hidden;
  max-width: 100%;
  width: 100%; }
  #content .search-result-spot .component-content:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 156px;
    background-image: linear-gradient(to right, #74cbca, #3344f6); }
    @media only screen and (min-width: 768px) {
      #content .search-result-spot .component-content:before {
        height: 206px; } }
    @media only screen and (min-width: 992px) {
      #content .search-result-spot .component-content:before {
        height: 246px; } }

#content .search-result-spot .search-field,
#content .search-result-spot .search-result {
  max-width: 80%;
  width: 56rem;
  margin: 0 auto; }

#content .search-result-spot .search-result {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media only screen and (min-width: 768px) {
    #content .search-result-spot .search-result {
      padding-top: 3rem;
      padding-bottom: 3rem; } }

#content .search-result-spot .search-field {
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative; }
  @media only screen and (min-width: 768px) {
    #content .search-result-spot .search-field {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  @media only screen and (min-width: 992px) {
    #content .search-result-spot .search-field {
      padding-top: 5rem; } }
  #content .search-result-spot .search-field * {
    color: #FFFFFF; }
  #content .search-result-spot .search-field input {
    border: 0;
    border-bottom: 1px solid #FFFFFF;
    border-radius: 0;
    position: relative;
    width: 100%;
    padding: .5rem 0;
    line-height: 1.4;
    background-color: transparent; }
    @media only screen and (min-width: 992px) {
      #content .search-result-spot .search-field input {
        font-size: 26px;
        font-weight: 600;
        padding-left: 20px; } }
  #content .search-result-spot .search-field .search-result-count {
    position: relative;
    margin-top: .5rem; }
    @media only screen and (min-width: 992px) {
      #content .search-result-spot .search-field .search-result-count {
        margin-top: 1.5rem; } }

#content .search-result-spot .search-result * {
  color: inherit; }

#content .search-result-spot .search-result ul {
  list-style: none; }

#content .search-result-spot .search-result li {
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-indent: 0;
  padding-left: 0; }
  @media only screen and (min-width: 992px) {
    #content .search-result-spot .search-result li {
      margin-top: 2rem;
      margin-bottom: 3rem; } }

#content .search-result-spot .search-result h3 {
  font-size: 1.2rem;
  position: relative; }
  @media only screen and (min-width: 992px) {
    #content .search-result-spot .search-result h3 {
      font-size: 1.6rem;
      margin-bottom: .5rem; } }

#content .search-result-spot .search-result a {
  text-decoration: none;
  font-weight: 300; }

#content .search-result-spot .search-result li > a {
  font-size: .8rem; }

#content .search-result-spot .search-result .search-summary {
  margin-top: 1rem; }

@media only screen and (min-width: 1200px) {
  #content .search-result-spot .search-result h3 {
    font-size: 1.8rem; }
  #content .search-result-spot .search-result li {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  #content .search-result-spot .search-result li > a {
    color: #016484;
    font-size: 1rem; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.component.text-image-spot .text-image-spot-image-container {
  background-size: 0px; }

.component.text-image-spot .text-image-spot-image img {
  max-width: 100%;
  height: auto; }

.component.text-image-spot .text-image-spot-text-container {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }

.component.text-image-spot.inoborder {
  padding-top: 0;
  padding-bottom: 0; }
  .component.text-image-spot.inoborder .text-image-spot-text-container {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-top: 1rem; } }
    .component.text-image-spot.inoborder .text-image-spot-text-container.repeating-background {
      padding-top: 0; }
    @media only screen and (max-width: 767px) {
      .component.text-image-spot.inoborder .text-image-spot-text-container {
        padding-bottom: 1rem; } }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.ialigncenter .text-image-spot-image-container {
    text-align: center; } }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.taligncenter .text-image-spot-text-container {
    text-align: center; } }

.component.text-image-spot.multiple-images {
  margin-top: 2rem;
  position: relative; }
  .component.text-image-spot.multiple-images.iright .text-image-spot-image-elements {
    margin-top: 1rem; }
  .component.text-image-spot.multiple-images .text-image-spot-image-container:after {
    content: "";
    display: table;
    clear: both; }

.component.text-image-spot .text-image-spot-image-elements {
  max-width: 80%;
  margin: 0 auto; }
  .component.text-image-spot .text-image-spot-image-elements:after {
    content: "";
    display: table;
    clear: both; }
  .component.text-image-spot .text-image-spot-image-elements.slick-slider .text-image-spot-element {
    max-width: none; }

.component.text-image-spot .text-image-spot-element {
  display: block;
  float: right;
  padding: 0;
  max-width: 25%; }
  .component.text-image-spot .text-image-spot-element:before {
    display: none; }

.component.text-image-spot .text-image-spot-image-element {
  padding-left: 1rem; }
  .component.text-image-spot .text-image-spot-image-element img {
    max-width: 100%;
    height: auto;
    border-bottom: 1px solid transparent; }
  .component.text-image-spot .text-image-spot-image-element img:hover, .component.text-image-spot .text-image-spot-image-element img.selected {
    border-bottom: 1px solid #A9A9A9; }

.component.text-image-spot .slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  outline: none;
  background: transparent;
  border: solid 1px #A9A9A9;
  border-radius: 20px; }
  .component.text-image-spot .slick-arrow:before {
    position: relative;
    top: 1px;
    font-family: "DGS-WebProgram";
    font-size: 20px;
    line-height: 1;
    color: #A9A9A9; }
  .component.text-image-spot .slick-arrow.slick-next {
    right: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-next:before {
      content: "";
      left: 1px; }
  .component.text-image-spot .slick-arrow.slick-prev {
    left: -10%;
    z-index: 100; }
    .component.text-image-spot .slick-arrow.slick-prev:before {
      content: "";
      right: 1px; }
  .component.text-image-spot .slick-arrow.slick-disabled {
    cursor: default;
    opacity: 0.5; }

@media only screen and (min-width: 992px) {
  .component.text-image-spot {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .component.text-image-spot .component-content {
      display: table-row;
      width: 100%; }
    .component.text-image-spot .text-image-spot-image-container,
    .component.text-image-spot .text-image-spot-text-container {
      display: table-cell;
      width: 50%; }
    .component.text-image-spot .text-image-spot-text {
      position: relative; }
    .component.text-image-spot:not(.iright) .text-image-spot-text {
      float: left;
      padding-left: 2rem; } }
    @media only screen and (min-width: 992px) and (max-width: 767px) {
      .component.text-image-spot:not(.iright) .text-image-spot-text {
        padding-left: 1rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot.iright {
      direction: rtl; }
      .component.text-image-spot.iright .text-image-spot-image-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text-container {
        direction: ltr; }
      .component.text-image-spot.iright .text-image-spot-text {
        float: right;
        padding-right: 2rem; } }
      @media only screen and (min-width: 992px) and (max-width: 767px) {
        .component.text-image-spot.iright .text-image-spot-text {
          padding-right: 1rem; } }

@media only screen and (min-width: 992px) {
    .component.text-image-spot .text-image-spot-text {
      box-sizing: border-box;
      width: 41rem;
      max-width: 80%; }
    .component.text-image-spot.tslim .text-image-spot-text {
      width: 38.5rem; }
    .component.text-image-spot.tw40 .text-image-spot-text {
      position: relative;
      width: 32.8rem;
      max-width: 64%;
      left: 8.2rem; }
    .component.text-image-spot.tw40.iright .text-image-spot-text {
      left: -8.2rem; }
    .component.text-image-spot.tw40.tslim .text-image-spot-text {
      width: 30.8rem;
      left: 7.7rem; }
    .component.text-image-spot.tw60 .text-image-spot-text {
      position: relative;
      width: 49.2rem;
      max-width: 96%;
      left: -8.2rem; }
    .component.text-image-spot.tw60.tslim .text-image-spot-text {
      width: 46.2rem;
      left: -7.7rem; }
    .component.text-image-spot .text-image-spot-text-container {
      vertical-align: top; }
    .component.text-image-spot.tposmiddle .text-image-spot-text-container {
      vertical-align: middle; }
    .component.text-image-spot.tposbottom .text-image-spot-text-container {
      vertical-align: bottom; }
    .component.text-image-spot .text-image-spot-image {
      box-sizing: border-box;
      width: 100%;
      float: left; }
      .component.text-image-spot .text-image-spot-image img {
        float: right;
        max-width: 100%;
        height: auto; }
    .component.text-image-spot.inormal .text-image-spot-image img {
      width: 41rem;
      max-width: 80%; }
    .component.text-image-spot.islim .text-image-spot-image img {
      width: 38.5rem;
      max-width: 80%; }
    .component.text-image-spot.iright .text-image-spot-image img {
      float: left; }
    .component.text-image-spot.ialignborder:not(.iright) .text-image-spot-image img {
      float: right; }
    .component.text-image-spot.iw40 .text-image-spot-image {
      width: 80%; }
    .component.text-image-spot.iw40.iright .text-image-spot-image {
      margin-left: 20%; }
    .component.text-image-spot.iw40.inormal .text-image-spot-image {
      width: 32.8rem; }
    .component.text-image-spot.iw40.inormal.iright .text-image-spot-image {
      margin-left: 8.2rem; }
    .component.text-image-spot.iw40.islim .text-image-spot-image {
      width: 30.8rem; }
    .component.text-image-spot.iw40.islim.iright .text-image-spot-image {
      margin-left: 7.7rem; }
    .component.text-image-spot.iw60 .text-image-spot-image {
      width: 120%; }
    .component.text-image-spot.iw60.iright .text-image-spot-image {
      margin-left: -20%; }
    .component.text-image-spot.iw60.inormal .text-image-spot-image {
      width: 49.2rem; }
    .component.text-image-spot.iw60.inormal.iright .text-image-spot-image {
      margin-left: -8.2rem; }
    .component.text-image-spot.iw60.islim .text-image-spot-image {
      width: 46.2rem; }
    .component.text-image-spot.iw60.islim.iright .text-image-spot-image {
      margin-left: -7.7rem; }
    .component.text-image-spot .text-image-spot-image-container {
      vertical-align: top; }
    .component.text-image-spot.iposmiddle .text-image-spot-image-container {
      vertical-align: middle; }
    .component.text-image-spot.iposbottom .text-image-spot-image-container {
      vertical-align: bottom; }
    .component.text-image-spot.tforceheight .text-image-spot-image-container {
      background-size: cover;
      background-position: center; }
    .component.text-image-spot.tforceheight .text-image-spot-image img {
      display: none; }
    .component.text-image-spot.tforceheight:not(.iright).inormal .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw60 .text-image-spot-image {
      right: -8.2rem; }
    .component.text-image-spot.tforceheight:not(.iright).inormal.iw40 .text-image-spot-image {
      right: 8.2rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim .text-image-spot-image {
      left: auto;
      right: 0; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw60 .text-image-spot-image {
      right: -7.7rem; }
    .component.text-image-spot.tforceheight:not(.iright).islim.iw40 .text-image-spot-image {
      right: 7.7rem; }
  .multiple-images.iright .text-image-spot-image-elements {
    float: left; }
  .multiple-images .text-image-spot-image-elements {
    max-width: 90%;
    float: right; }
  .multiple-images .slick-arrow.slick-next {
    right: -40px; }
  .multiple-images .slick-arrow.slick-prev {
    left: -40px; } }

.component.text-image-spot.reset-margins-paddings {
  padding: 0 !important;
  margin-top: 0; }
  .component.text-image-spot.reset-margins-paddings .text-image-spot-text {
    padding: 3rem 0; }

.component.text-image-spot.center.middle .text-image-spot-text {
  text-align: center; }

.component.text-image-spot.hp-txt-img-spot .text-image-spot-text-container {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.component.text-image-spot.hp-txt-img-spot h2 {
  color: #013e5d; }

.component.text-image-spot.hp-txt-img-spot p {
  font-size: .9rem;
  line-height: 1.4;
  font-family: "Muli", Arial, sans-serif; }

.component.text-image-spot.hp-txt-img-spot .like-h4 {
  line-height: 1.6;
  font-weight: bold;
  margin-bottom: 1rem; }

.component.text-image-spot.hp-txt-img-spot .like-button {
  margin-top: 2rem; }

.component.text-image-spot.hp-txt-img-spot.landing-page .component-content {
  position: relative;
  overflow: hidden; }

@media only screen and (min-width: 1400px) {
  .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-image-container, .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container {
    max-width: 1300px;
    margin: 0 auto; } }

.component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  padding: 0; }
  .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container .text-image-spot-text {
    height: 100%; }
  .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a {
    position: absolute;
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #24405b 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(36, 64, 91, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    transition: all 0.8s ease-in-out; }
    .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a a {
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 1200px) {
      .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #24405b 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #24405b;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #24405b;
        transition: all 0.8s ease-in-out;
        animation: out 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at right, #24405b 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #ffffff;
        color: #24405b;
        transition: all 0.4s ease-in-out; }
        .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:hover a {
          color: #24405b;
          transition: all 0.8s ease-in-out; } }
    .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:first-of-type {
      top: 0;
      right: 0; }
      @media (max-width: 767px) and (orientation: landscape) {
        .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:first-of-type {
          top: 10%;
          right: 22%; } }
      @media only screen and (min-width: 768px) {
        .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:first-of-type {
          top: 12%;
          left: 53%;
          right: auto; } }
      @media only screen and (min-width: 992px) {
        .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:first-of-type {
          top: 15%; } }
    .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:last-of-type {
      top: 40%;
      right: 0; }
      @media (max-width: 767px) and (orientation: landscape) {
        .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:last-of-type {
          top: 35%;
          right: 11%; } }
      @media only screen and (min-width: 768px) {
        .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:last-of-type {
          top: 25%;
          left: 58%;
          right: auto; } }
      @media only screen and (min-width: 992px) {
        .component.text-image-spot.hp-txt-img-spot.landing-page .text-image-spot-text-container a:last-of-type {
          top: 28%; } }

.component.text-image-spot.hp-txt-img-spot.landing-page .like-button {
  margin-top: 0; }

.component.text-image-spot.text-with-readmore .text-image-spot-image {
  background-color: #ffffff;
  padding-bottom: 2rem; }
  @media only screen and (min-width: 1200px) {
    .component.text-image-spot.text-with-readmore .text-image-spot-image {
      padding-bottom: 5rem; } }

.component.text-image-spot.text-with-readmore .text-image-spot-text {
  display: flex;
  flex-flow: column; }
  @media only screen and (min-width: 1200px) {
    .component.text-image-spot.text-with-readmore .text-image-spot-text {
      padding-top: 4.5rem; } }
  .component.text-image-spot.text-with-readmore .text-image-spot-text div.is-active {
    order: 1; }
  .component.text-image-spot.text-with-readmore .text-image-spot-text > * {
    margin-bottom: 1.6rem; }

.component.text-image-spot.page-404.hp-txt-img-spot .component-content {
  display: flex;
  flex-direction: column; }
  .component.text-image-spot.page-404.hp-txt-img-spot .component-content .text-image-spot-text-container {
    max-width: 90%;
    order: -1; }
    @media only screen and (min-width: 1200px) {
      .component.text-image-spot.page-404.hp-txt-img-spot .component-content .text-image-spot-text-container {
        padding-top: 6rem;
        padding-bottom: 5rem; } }
  .component.text-image-spot.page-404.hp-txt-img-spot .component-content .text-image-spot-image-container img {
    max-height: 695px;
    width: 100%; }
    @media only screen and (min-width: 1200px) {
      .component.text-image-spot.page-404.hp-txt-img-spot .component-content .text-image-spot-image-container img {
        max-height: unset; } }

.component.text-image-spot.page-404 .text-image-spot-text {
  text-align: center;
  line-height: 1.2;
  width: 100%; }
  @media only screen and (min-width: 1200px) {
    .component.text-image-spot.page-404 .text-image-spot-text {
      max-width: 100%; } }
  .component.text-image-spot.page-404 .text-image-spot-text h2 {
    font-size: 20px;
    font-weight: 300; }
    @media only screen and (min-width: 768px) {
      .component.text-image-spot.page-404 .text-image-spot-text h2 {
        font-size: 30px; } }
  .component.text-image-spot.page-404 .text-image-spot-text p {
    line-height: 1.2; }

@media only screen and (max-width: 767px) {
  .component.text-image-spot.page-404 .text-image-spot-image-container {
    position: relative;
    width: 82rem;
    max-width: calc(100% - 2rem);
    margin: 0 auto; }
  .component.text-image-spot.page-404 .text-image-spot-image {
    text-align: center; }
    .component.text-image-spot.page-404 .text-image-spot-image img {
      max-width: none;
      max-height: 200px;
      width: auto; } }

@media only screen and (min-width: 768px) {
  .component.text-image-spot.page-404 .component-content {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0; }
  .component.text-image-spot.page-404 .text-image-spot-image-container {
    vertical-align: top; }
  .component.text-image-spot.page-404 .text-image-spot-text h1 {
    font-size: 8rem; } }

@media only screen and (min-width: 992px) {
  .component.text-image-spot.hp-txt-img-spot .component-content {
    max-width: 1920px;
    display: block;
    margin: 0 auto; }
  .component.text-image-spot.hp-txt-img-spot .text-image-spot-text-container, .component.text-image-spot.hp-txt-img-spot .text-image-spot-image-container {
    width: 100%;
    display: block; }
  .component.text-image-spot.hp-txt-img-spot .text-image-spot-text-container {
    padding-top: 3rem;
    padding-bottom: 3rem; }
    .component.text-image-spot.hp-txt-img-spot .text-image-spot-text-container .text-image-spot-text {
      width: auto;
      float: none;
      padding-left: 0;
      margin: 0 auto; }
  .component.text-image-spot.hp-txt-img-spot .text-image-spot-image-container {
    position: relative; }
    .component.text-image-spot.hp-txt-img-spot .text-image-spot-image-container .text-image-spot-image {
      float: none; }
      .component.text-image-spot.hp-txt-img-spot .text-image-spot-image-container .text-image-spot-image a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .component.text-image-spot.hp-txt-img-spot .text-image-spot-image-container .text-image-spot-image img {
        float: none;
        position: relative;
        width: 100%;
        height: auto; }
  .component.text-image-spot.hp-txt-img-spot.page-404 .text-image-spot-text-container .text-image-spot-text {
    width: 100%; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .component.text-image-spot.page-404 .text-image-spot-image {
    text-align: right; }
    .component.text-image-spot.page-404 .text-image-spot-image img {
      max-width: none;
      max-height: 300px;
      width: auto; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .component.text-image-spot.reset-margins-paddings .text-image-spot-image-container, .component.text-image-spot.reset-margins-paddings .text-image-spot-text-container {
    width: 100%;
    display: block; }
  .component.text-image-spot.reset-margins-paddings .text-image-spot-text {
    width: 100%;
    max-width: none; }
  .component.text-image-spot.page-404 .text-image-spot-image {
    text-align: center; }
    .component.text-image-spot.page-404 .text-image-spot-image img {
      max-width: none;
      max-height: 300px;
      width: auto; } }

@media only screen and (min-width: 1200px) {
  .component.text-image-spot.reset-margins-paddings .text-image-spot-image-container {
    width: 100%;
    display: block; }
  .component.text-image-spot.reset-margins-paddings .text-image-spot-text-container {
    width: 82rem;
    max-width: 80%;
    margin: 0 auto;
    display: block; }
  .component.text-image-spot.reset-margins-paddings .text-image-spot-image {
    text-align: center; }
    .component.text-image-spot.reset-margins-paddings .text-image-spot-image img {
      float: none; }
  .component.text-image-spot.reset-margins-paddings .text-image-spot-text {
    width: 100%;
    max-width: none; }
  .component.text-image-spot.hp-txt-img-spot p {
    font-size: 1.2rem; }
  .component.text-image-spot.hp-txt-img-spot .like-button {
    margin-top: 1.5rem;
    width: auto; } }

@media only screen and (max-width: 991px) {
  .component.text-image-spot.hp-txt-img-spot .like-button {
    display: table;
    text-align: center;
    margin-left: auto;
    margin-right: auto; } }

#wrapper .two-column-spot {
  text-align: center; }
  #wrapper .two-column-spot .component-content {
    position: relative;
    width: 82rem;
    max-width: 80%;
    margin: 0 auto;
    overflow: hidden;
    text-align: left; }
    #wrapper .two-column-spot .component-content .spot-text-right {
      float: left;
      width: 48%; }
      #wrapper .two-column-spot .component-content .spot-text-right > *:first-child {
        margin-top: 0;
        padding-top: 0; }
    #wrapper .two-column-spot .component-content .spot-text-left {
      float: right;
      width: 48%; }
      #wrapper .two-column-spot .component-content .spot-text-left > *:first-child {
        margin-top: 0;
        padding-top: 0; }
    #wrapper .two-column-spot .component-content .spot-text-header {
      width: 100%; }
  #wrapper .two-column-spot ul {
    list-style: disc; }

@media only screen and (max-width: 800px) {
  #wrapper .w10.two-column-spot > .component-content, #wrapper .w20.two-column-spot > .component-content,
  #wrapper .w30.two-column-spot > .component-content, #wrapper .w40.two-column-spot > .component-content,
  #wrapper .w50.two-column-spot > .component-content, #wrapper .w60.two-column-spot > .component-content,
  #wrapper .w70.two-column-spot > .component-content, #wrapper .w80.two-column-spot > .component-content,
  #wrapper .w90.two-column-spot > .component-content {
    max-width: 90% !important; } }

@media only screen and (max-width: 600px) {
  #wrapper .two-column-spot .component-content .spot-text-right {
    float: none;
    width: 100%;
    padding-bottom: 2rem; }
  #wrapper .two-column-spot .component-content .spot-text-left {
    float: none;
    width: auto;
    margin: 0 auto; }
  #wrapper .w10.two-column-spot > .component-content, #wrapper .w20.two-column-spot > .component-content,
  #wrapper .w30.two-column-spot > .component-content, #wrapper .w40.two-column-spot > .component-content,
  #wrapper .w50.two-column-spot > .component-content, #wrapper .w60.two-column-spot > .component-content,
  #wrapper .w70.two-column-spot > .component-content, #wrapper .w80.two-column-spot > .component-content,
  #wrapper .w90.two-column-spot > .component-content {
    max-width: 90% !important; } }

#content .two-column-spot.center * {
  text-align: center; }

@media only screen and (max-width: 992px) {
  #content .two-column-spot.center-text-mobile-tablet .spot-text-left *, #content .two-column-spot.center-text-mobile-tablet .spot-text-right * {
    text-align: center !important; }
  #content .two-column-spot.center-text-mobile-tablet .spot-text-left a, #content .two-column-spot.center-text-mobile-tablet .spot-text-right a {
    display: table;
    margin-left: auto;
    margin-right: auto; } }

#content .two-column-spot.bgcolor-doubleblue * {
  color: #ffffff; }

#content .two-column-spot.bgcolor-doubleblue .component-content {
  padding: 0;
  max-width: 820px;
  width: 100%; }
  @media only screen and (min-width: 768px) {
    #content .two-column-spot.bgcolor-doubleblue .component-content {
      display: flex;
      justify-content: space-around; } }
  @media only screen and (min-width: 992px) {
    #content .two-column-spot.bgcolor-doubleblue .component-content {
      justify-content: space-between; } }

#content .two-column-spot.bgcolor-doubleblue .spot-text-header {
  display: none; }

#content .two-column-spot.bgcolor-doubleblue .spot-text-left, #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
  background-image: url(/design/build/demant-szczecin/assets/circle-seafoam-gray.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 265px;
  height: 253px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  float: none; }
  @media only screen and (max-width: 767px) {
    #content .two-column-spot.bgcolor-doubleblue .spot-text-left, #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
      margin: 0 auto; } }
  @media only screen and (min-width: 768px) {
    #content .two-column-spot.bgcolor-doubleblue .spot-text-left, #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
      width: 331px;
      height: 316px; } }
  #content .two-column-spot.bgcolor-doubleblue .spot-text-left p, #content .two-column-spot.bgcolor-doubleblue .spot-text-right p {
    line-height: 1.2; }
    #content .two-column-spot.bgcolor-doubleblue .spot-text-left p a + a, #content .two-column-spot.bgcolor-doubleblue .spot-text-right p a + a {
      margin-top: 1.1rem; }

@media only screen and (max-width: 767px) {
  #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
    margin-bottom: 30px; } }

@media only screen and (min-width: 768px) {
  #content .two-column-spot.bgcolor-doublewhite .component-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; } }

#content .two-column-spot.bgcolor-doublewhite .spot-text-header:not(:empty) {
  flex-basis: 100%;
  font-weight: 300;
  margin-bottom: 40px; }
  @media only screen and (min-width: 992px) {
    #content .two-column-spot.bgcolor-doublewhite .spot-text-header:not(:empty) {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 1200px) {
    #content .two-column-spot.bgcolor-doublewhite .spot-text-header:not(:empty) {
      padding-top: 3rem; } }

@media only screen and (min-width: 768px) {
  #content .two-column-spot.bgcolor-doublewhite .spot-text-left {
    padding: 0 50px; } }

#content .two-column-spot.contact .contact-header {
  font-size: 20px; }
  #content .two-column-spot.contact .contact-header strong {
    font-weight: normal; }

#content .two-column-spot.contact .contact-phone-1 {
  font-weight: normal;
  text-decoration: none; }

#content .two-column-spot.contact .contact-phone-1,
#content .two-column-spot.contact .contact-mail-1 {
  display: inline-block;
  margin: .5rem 0; }

#content .two-column-spot.contact .contact-facebook,
#content .two-column-spot.contact .contact-linkedin,
#content .two-column-spot.contact .contact-youtube {
  font-size: 1.6rem;
  line-height: 2;
  margin-right: 10%;
  text-decoration: none;
  color: inherit; }
  #content .two-column-spot.contact .contact-facebook:before,
  #content .two-column-spot.contact .contact-linkedin:before,
  #content .two-column-spot.contact .contact-youtube:before {
    font-family: "icomoon"; }

#content .two-column-spot.contact .contact-facebook:before {
  content: ""; }

#content .two-column-spot.contact .contact-linkedin:before {
  content: ""; }

#content .two-column-spot.contact .contact-youtube:before {
  content: ""; }

#content .two-column-spot.contact .contact-instagram:before {
  content: '';
  width: 22px;
  height: 22px;
  display: inline-block;
  background-image: url(/design/build/demant-szczecin/assets/instagram.svg);
  background-repeat: no-repeat;
  background-size: 100%; }

#content .two-column-spot.text-align-middle-line .spot-text-header {
  text-align: center; }

#content .two-column-spot.text-align-middle-line .spot-text-left {
  text-align: left; }

#content .two-column-spot.text-align-middle-line .spot-text-right {
  text-align: right; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
#wrapper .video-spot-overlay {
  background-color: rgba(0, 0, 0, 0.7); }
  #wrapper .video-spot-overlay .video-spot-overlay-close {
    top: 1rem;
    right: 1rem;
    z-index: 1; }
    #wrapper .video-spot-overlay .video-spot-overlay-close::after {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "close";
      color: #FFFFFF;
      cursor: pointer;
      font-size: 2rem; }

#wrapper .component.video-spot.hide-btn .video-spot-play-btn {
  display: none; }

.component.link-list .component-content {
  position: relative;
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }

@media only screen and (min-width: 1200px) {
  .component.link-list.tabs {
    margin-bottom: 60px; } }

.component.link-list.tabs.wrap .component-content > ul {
  flex-wrap: wrap; }

@media only screen and (max-width: 767px) {
  .component.link-list.tabs .component-content {
    max-width: 90%; } }

.component.link-list.tabs .component-content > ul {
  width: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none; }
  .component.link-list.tabs .component-content > ul li + li {
    padding: 0; }
  .component.link-list.tabs .component-content > ul > li {
    text-indent: inherit;
    padding-left: 0;
    text-align: center;
    margin: .25rem 0; }
    @media only screen and (min-width: 1200px) {
      .component.link-list.tabs .component-content > ul > li {
        margin-left: 1rem;
        margin-right: 1rem; } }
    .component.link-list.tabs .component-content > ul > li a {
      display: block;
      color: #5b7085;
      text-decoration: none;
      font-weight: 700;
      height: auto;
      line-height: normal;
      padding: 10px 20px;
      border-radius: 20px;
      background: transparent; }
      @media only screen and (min-width: 768px) {
        .component.link-list.tabs .component-content > ul > li a {
          font-size: 16px;
          padding: 14px 30px 15px;
          border-radius: 30px; } }
      @media only screen and (min-width: 1200px) {
        .component.link-list.tabs .component-content > ul > li a {
          text-transform: uppercase; }
          .component.link-list.tabs .component-content > ul > li a:hover {
            background: #3344f6;
            color: #ffffff; } }
      .component.link-list.tabs .component-content > ul > li a.is-active {
        background: #3344f6;
        color: #ffffff; }

.component.link-list.submenu-list-links ul {
  display: flex;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0; }
  .component.link-list.submenu-list-links ul li {
    margin: 0;
    padding: 0 1rem;
    text-indent: 0; }
    .component.link-list.submenu-list-links ul li a {
      background-color: #f7f7f7;
      border-radius: 7px;
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 5px 15px;
      text-transform: uppercase;
      line-height: 1.4;
      font-family: "Muli", Arial, sans-serif;
      font-weight: normal; }
      .component.link-list.submenu-list-links ul li a.is-active, .component.link-list.submenu-list-links ul li a:hover {
        background-color: #016484;
        color: #FFFFFF; }

#wrapper .two-column-spot {
  text-align: center; }
  #wrapper .two-column-spot .component-content {
    position: relative;
    width: 82rem;
    max-width: 80%;
    margin: 0 auto;
    overflow: hidden;
    text-align: left; }
    #wrapper .two-column-spot .component-content .spot-text-right {
      float: left;
      width: 48%; }
      #wrapper .two-column-spot .component-content .spot-text-right > *:first-child {
        margin-top: 0;
        padding-top: 0; }
    #wrapper .two-column-spot .component-content .spot-text-left {
      float: right;
      width: 48%; }
      #wrapper .two-column-spot .component-content .spot-text-left > *:first-child {
        margin-top: 0;
        padding-top: 0; }
    #wrapper .two-column-spot .component-content .spot-text-header {
      width: 100%; }
  #wrapper .two-column-spot ul {
    list-style: disc; }

@media only screen and (max-width: 800px) {
  #wrapper .w10.two-column-spot > .component-content, #wrapper .w20.two-column-spot > .component-content,
  #wrapper .w30.two-column-spot > .component-content, #wrapper .w40.two-column-spot > .component-content,
  #wrapper .w50.two-column-spot > .component-content, #wrapper .w60.two-column-spot > .component-content,
  #wrapper .w70.two-column-spot > .component-content, #wrapper .w80.two-column-spot > .component-content,
  #wrapper .w90.two-column-spot > .component-content {
    max-width: 90% !important; } }

@media only screen and (max-width: 600px) {
  #wrapper .two-column-spot .component-content .spot-text-right {
    float: none;
    width: 100%;
    padding-bottom: 2rem; }
  #wrapper .two-column-spot .component-content .spot-text-left {
    float: none;
    width: auto;
    margin: 0 auto; }
  #wrapper .w10.two-column-spot > .component-content, #wrapper .w20.two-column-spot > .component-content,
  #wrapper .w30.two-column-spot > .component-content, #wrapper .w40.two-column-spot > .component-content,
  #wrapper .w50.two-column-spot > .component-content, #wrapper .w60.two-column-spot > .component-content,
  #wrapper .w70.two-column-spot > .component-content, #wrapper .w80.two-column-spot > .component-content,
  #wrapper .w90.two-column-spot > .component-content {
    max-width: 90% !important; } }

#content .two-column-spot.center * {
  text-align: center; }

@media only screen and (max-width: 992px) {
  #content .two-column-spot.center-text-mobile-tablet .spot-text-left *, #content .two-column-spot.center-text-mobile-tablet .spot-text-right * {
    text-align: center !important; }
  #content .two-column-spot.center-text-mobile-tablet .spot-text-left a, #content .two-column-spot.center-text-mobile-tablet .spot-text-right a {
    display: table;
    margin-left: auto;
    margin-right: auto; } }

#content .two-column-spot.bgcolor-doubleblue * {
  color: #ffffff; }

#content .two-column-spot.bgcolor-doubleblue .component-content {
  padding: 0;
  max-width: 820px;
  width: 100%; }
  @media only screen and (min-width: 768px) {
    #content .two-column-spot.bgcolor-doubleblue .component-content {
      display: flex;
      justify-content: space-around; } }
  @media only screen and (min-width: 992px) {
    #content .two-column-spot.bgcolor-doubleblue .component-content {
      justify-content: space-between; } }

#content .two-column-spot.bgcolor-doubleblue .spot-text-header {
  display: none; }

#content .two-column-spot.bgcolor-doubleblue .spot-text-left, #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
  background-image: url(/design/build/demant-szczecin/assets/circle-seafoam-gray.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 265px;
  height: 253px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  float: none; }
  @media only screen and (max-width: 767px) {
    #content .two-column-spot.bgcolor-doubleblue .spot-text-left, #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
      margin: 0 auto; } }
  @media only screen and (min-width: 768px) {
    #content .two-column-spot.bgcolor-doubleblue .spot-text-left, #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
      width: 331px;
      height: 316px; } }
  #content .two-column-spot.bgcolor-doubleblue .spot-text-left p, #content .two-column-spot.bgcolor-doubleblue .spot-text-right p {
    line-height: 1.2; }
    #content .two-column-spot.bgcolor-doubleblue .spot-text-left p a + a, #content .two-column-spot.bgcolor-doubleblue .spot-text-right p a + a {
      margin-top: 1.1rem; }

@media only screen and (max-width: 767px) {
  #content .two-column-spot.bgcolor-doubleblue .spot-text-right {
    margin-bottom: 30px; } }

@media only screen and (min-width: 768px) {
  #content .two-column-spot.bgcolor-doublewhite .component-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; } }

#content .two-column-spot.bgcolor-doublewhite .spot-text-header:not(:empty) {
  flex-basis: 100%;
  font-weight: 300;
  margin-bottom: 40px; }
  @media only screen and (min-width: 992px) {
    #content .two-column-spot.bgcolor-doublewhite .spot-text-header:not(:empty) {
      margin-bottom: 60px; } }
  @media only screen and (min-width: 1200px) {
    #content .two-column-spot.bgcolor-doublewhite .spot-text-header:not(:empty) {
      padding-top: 3rem; } }

@media only screen and (min-width: 768px) {
  #content .two-column-spot.bgcolor-doublewhite .spot-text-left {
    padding: 0 50px; } }

#content .two-column-spot.contact .contact-header {
  font-size: 20px; }
  #content .two-column-spot.contact .contact-header strong {
    font-weight: normal; }

#content .two-column-spot.contact .contact-phone-1 {
  font-weight: normal;
  text-decoration: none; }

#content .two-column-spot.contact .contact-phone-1,
#content .two-column-spot.contact .contact-mail-1 {
  display: inline-block;
  margin: .5rem 0; }

#content .two-column-spot.contact .contact-facebook,
#content .two-column-spot.contact .contact-linkedin,
#content .two-column-spot.contact .contact-youtube {
  font-size: 1.6rem;
  line-height: 2;
  margin-right: 10%;
  text-decoration: none;
  color: inherit; }
  #content .two-column-spot.contact .contact-facebook:before,
  #content .two-column-spot.contact .contact-linkedin:before,
  #content .two-column-spot.contact .contact-youtube:before {
    font-family: "icomoon"; }

#content .two-column-spot.contact .contact-facebook:before {
  content: ""; }

#content .two-column-spot.contact .contact-linkedin:before {
  content: ""; }

#content .two-column-spot.contact .contact-youtube:before {
  content: ""; }

#content .two-column-spot.contact .contact-instagram:before {
  content: '';
  width: 22px;
  height: 22px;
  display: inline-block;
  background-image: url(/design/build/demant-szczecin/assets/instagram.svg);
  background-repeat: no-repeat;
  background-size: 100%; }

#content .two-column-spot.text-align-middle-line .spot-text-header {
  text-align: center; }

#content .two-column-spot.text-align-middle-line .spot-text-left {
  text-align: left; }

#content .two-column-spot.text-align-middle-line .spot-text-right {
  text-align: right; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.blog-author .component-content {
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  @media only screen and (min-width: 768px) {
    .component.blog-author .component-content {
      position: relative;
      min-height: 122px;
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  @media only screen and (min-width: 1200px) {
    .component.blog-author .component-content {
      width: 54rem;
      max-width: 90%;
      min-height: 244px;
      margin-bottom: 6rem; } }
  .component.blog-author .component-content:after {
    clear: both;
    content: "";
    display: block; }
  @media only screen and (min-width: 768px) {
    .component.blog-author .component-content .blog-author-name, .component.blog-author .component-content .blog-author-about, .component.blog-author .component-content .blog-author-title, .component.blog-author .component-content .blog-author-intro {
      padding-left: 160px; } }
  @media only screen and (min-width: 1200px) {
    .component.blog-author .component-content .blog-author-name, .component.blog-author .component-content .blog-author-about, .component.blog-author .component-content .blog-author-title, .component.blog-author .component-content .blog-author-intro {
      padding-left: 276px;
      max-width: 660px; } }
  .component.blog-author .component-content .blog-author-name {
    display: none; }
  .component.blog-author .component-content .blog-author-about {
    font-size: inherit;
    font-weight: 300;
    margin-bottom: .3rem; }
  .component.blog-author .component-content .blog-author-title {
    font-weight: 600;
    margin-bottom: 1rem; }
  .component.blog-author .component-content .blog-author-image {
    margin-bottom: 1rem; }
    @media only screen and (min-width: 768px) {
      .component.blog-author .component-content .blog-author-image {
        position: absolute;
        left: 0;
        top: 0; } }
    .component.blog-author .component-content .blog-author-image img {
      max-height: 122px; }
      @media only screen and (min-width: 1200px) {
        .component.blog-author .component-content .blog-author-image img {
          max-height: 244px;
          height: 244px; } }
  .component.blog-author .component-content .blog-author-intro {
    padding-bottom: 2rem; }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.blog-categories {
  position: relative;
  padding-top: 0;
  padding-bottom: 0; }
  @media only screen and (min-width: 1200px) {
    .component.blog-categories {
      margin-bottom: 7rem; } }
  .component.blog-categories .component-content {
    width: 82rem;
    max-width: 80%;
    margin: 0 auto; }
    @media only screen and (min-width: 1200px) {
      .component.blog-categories .component-content {
        width: 100%;
        max-width: 1200px; } }
  .component.blog-categories .blog-categories-content {
    background: none;
    background-color: transparent; }
  .component.blog-categories .blog-categories-content-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 1rem;
    text-align: center; }
    @media only screen and (min-width: 1200px) {
      .component.blog-categories .blog-categories-content-wrapper {
        padding: 0;
        width: 100%;
        max-width: 1200px; } }
  .component.blog-categories .blog-category {
    border-right: none;
    display: inline-block; }
    .component.blog-categories .blog-category:hover a {
      transform: none; }
    .component.blog-categories .blog-category a {
      margin: 5px;
      display: block;
      color: #ffffff;
      text-decoration: none;
      font-weight: 700;
      height: auto;
      line-height: normal;
      padding: 7px 20px;
      border-radius: 20px;
      background: #05CDCD; }
      @media only screen and (min-width: 768px) {
        .component.blog-categories .blog-category a {
          padding: 10px 20px;
          background: transparent;
          color: #5b7085; } }
      @media only screen and (min-width: 1200px) {
        .component.blog-categories .blog-category a {
          background: none;
          text-transform: uppercase;
          padding: 10px 12px;
          margin: 0; } }
  @media only screen and (min-width: 1200px) and (hover) {
    .component.blog-categories .blog-category a:hover {
      background: #3344f6;
      color: #ffffff; } }
      @media only screen and (min-width: 1200px) {
          .ie .component.blog-categories .blog-category a:hover {
            background: #3344f6;
            color: #ffffff; } }
      .component.blog-categories .blog-category a:after {
        display: none; }
      .component.blog-categories .blog-category a.active {
        background: #3344f6;
        color: #ffffff; }
        @media only screen and (min-width: 1200px) {
          .component.blog-categories .blog-category a.active {
            padding-left: 17px;
            padding-right: 17px; } }

.component > .component-content > .blog-image {
  text-align: center; }
  .component > .component-content > .blog-image > img {
    width: 100%;
    height: auto; }
    @media only screen and (min-width: 1200px) {
      .component > .component-content > .blog-image > img {
        max-width: 1920px; } }

/*
$retail-icons: $icons !default;

$corporate: $color-1 !default;
$zoom-in: "\eb1c" !default;
$zoom-out: "\eb1b" !default;
$marker: '\e902' !default;
$phone: '\e901' !default;
$icons-path: '/design/build/retail/assets/' !default;
$partners-color: $color-2 !default;
$cl_info_window_font_color: $color-3 !default;
$link-hover-opacity: 0.7 !default;
$calendar: '\e900' !default;
$cross: '\eae3' !default;
*/
/*
@mixin MQ($canvas) {
    @if $canvas == S {
        @include col-xs() {
            @content;
        }
    }
    @else if $canvas == M {
        @include col-md() {
            @content;
        }
    }
    @else if $canvas == L {
        @include col-lg() {
            @content;
        }
    }
}
}*/
/*
@mixin semi-bold-font {
    @include medium-font();
}
@mixin heavy-font {
    @include bold-font();
}
@mixin icon-circled ($color, $content, $font-size) {

}
@mixin link-transition() {
    opacity: 1;
    transition: opacity 0.2s;
}
@mixin link-hover(){
    opacity: $link-hover-opacity;
}
@mixin like-button-icon ($icon){
    @include like-button();
    //@include button-icon($icon, $white);
}
@mixin link-hover-important(){
    opacity: $link-hover-opacity !important;
}*/
.component.blog-list .component-content, .component.related-blogs .component-content {
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }

@media only screen and (min-width: 768px) {
  .component.blog-list ul, .component.related-blogs ul {
    display: flex;
    flex-wrap: wrap; } }

.component.blog-list ul li, .component.related-blogs ul li {
  text-indent: 0;
  margin: 2rem 1rem; }
  @media only screen and (min-width: 768px) {
    .component.blog-list ul li, .component.related-blogs ul li {
      margin: 0 0 3rem 0;
      width: 50%;
      padding: 0 2%; } }
  @media only screen and (min-width: 992px) {
    .component.blog-list ul li, .component.related-blogs ul li {
      width: 33%;
      margin-bottom: 3rem; } }
  .component.blog-list ul li > a, .component.related-blogs ul li > a {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    color: inherit;
    font-weight: inherit; }
  .component.blog-list ul li .news-image, .component.related-blogs ul li .news-image {
    height: auto;
    position: relative;
    padding-bottom: 50%;
    margin-bottom: 10px; }
    .component.blog-list ul li .news-image img, .component.related-blogs ul li .news-image img {
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  .component.blog-list ul li .news-title, .component.related-blogs ul li .news-title {
    display: none; }
  .component.blog-list ul li .news-text, .component.related-blogs ul li .news-text {
    text-align: center;
    flex-grow: 1;
    height: auto;
    max-height: unset;
    margin: .5rem 0 1rem;
    line-height: 21px;
    font-size: 13px; }
  .component.blog-list ul li .news-date, .component.related-blogs ul li .news-date {
    text-align: center;
    color: inherit; }
  .component.blog-list ul li .news-link, .component.related-blogs ul li .news-link {
    text-align: center;
    margin-top: .5rem;
    font-size: 13px; }
    .component.blog-list ul li .news-link > .link, .component.related-blogs ul li .news-link > .link {
      color: #3344f6;
      font-weight: 600;
      text-decoration: none; }

.component.related-blogs .component-content .related-title {
  display: none; }

.component.related-blogs .component-content ul li div .news-date {
  order: 2; }

.component.related-blogs .component-content ul li div .news-link {
  order: 3; }

.component.blog .component-content {
  width: 82rem;
  max-width: 80%;
  margin: 0 auto; }
  @media only screen and (min-width: 1200px) {
    .component.blog .component-content {
      width: 53rem;
      max-width: 90%; } }
  .component.blog .component-content .blog-title {
    font-weight: 300;
    text-align: center;
    font-size: 22px;
    margin-bottom: 2rem; }
    @media only screen and (min-width: 1200px) {
      .component.blog .component-content .blog-title {
        font-size: 30px;
        margin-bottom: 2.5rem; } }
  .component.blog .component-content .blog-author {
    font-weight: 600;
    margin-bottom: 2rem; }
    @media only screen and (min-width: 992px) {
      .component.blog .component-content .blog-author {
        margin-bottom: 1.3rem; } }
  .component.blog .component-content .blog-date {
    margin-bottom: 2rem; }
    @media only screen and (min-width: 992px) {
      .component.blog .component-content .blog-date {
        margin-bottom: 3rem; } }
  .component.blog .component-content .blog-content img {
    height: auto !important;
    padding-top: 1rem; }
    @media only screen and (max-width: 767px) {
      .component.blog .component-content .blog-content img {
        width: calc(100% + 2rem) !important;
        margin: 0 -1rem; } }
    @media only screen and (min-width: 768px) {
      .component.blog .component-content .blog-content img {
        max-width: 100% !important; } }
  .component.blog .component-content .blog-content h2, .component.blog .component-content .blog-content h3, .component.blog .component-content .blog-content h4, .component.blog .component-content .blog-content h5, .component.blog .component-content .blog-content h6 {
    font-weight: 300; }
  .component.blog .component-content .blog-content h2 {
    font-size: 22px; }
  .component.blog .component-content .blog-content h3, .component.blog .component-content .blog-content h4, .component.blog .component-content .blog-content h5, .component.blog .component-content .blog-content h6 {
    font-size: 20px; }
  .component.blog .component-content .blog-content .like-p {
    font-weight: 300;
    font-size: 11px;
    display: inline-block;
    margin-bottom: 0.5rem; }
    @media only screen and (min-width: 768px) {
      .component.blog .component-content .blog-content .like-p {
        font-size: 13px;
        margin-bottom: 1rem; } }
  .component.blog .component-content .blog-content .video-container {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 30px;
    height: 0;
    overflow: hidden; }
    .component.blog .component-content .blog-content .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

body.mode-normal #wrapper .site-notification-spot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 64, 91, 0.89);
  z-index: 10000;
  display: none; }
  body.mode-normal #wrapper .site-notification-spot .component-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    width: 600px;
    max-width: 80%;
    box-sizing: border-box;
    background-color: transparent;
    pointer-events: none;
    cursor: pointer; }
    @media only screen and (min-width: 992px) {
      body.mode-normal #wrapper .site-notification-spot .component-content {
        width: 1100px; } }
    body.mode-normal #wrapper .site-notification-spot .component-content:after {
      background-color: #3344f6;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "close";
      position: absolute;
      top: -25px;
      right: -25px;
      cursor: pointer;
      pointer-events: auto;
      font-size: 1em;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media only screen and (min-width: 992px) {
        body.mode-normal #wrapper .site-notification-spot .component-content:after {
          width: 100px;
          height: 100px;
          top: -50px;
          right: -50px;
          font-size: 1.5em; } }
    body.mode-normal #wrapper .site-notification-spot .component-content .like-button {
      position: absolute;
      left: 7.3%;
      bottom: 15px;
      pointer-events: auto; }
      @media only screen and (max-width: 767px) {
        body.mode-normal #wrapper .site-notification-spot .component-content .like-button {
          padding-top: 6px;
          padding-bottom: 7px; } }
      @media only screen and (min-width: 640px) {
        body.mode-normal #wrapper .site-notification-spot .component-content .like-button {
          bottom: 20%; } }
      @media only screen and (min-width: 768px) {
        body.mode-normal #wrapper .site-notification-spot .component-content .like-button {
          bottom: 15%; } }
      @media only screen and (min-width: 1200px) {
        body.mode-normal #wrapper .site-notification-spot .component-content .like-button {
          bottom: 20%; } }
    body.mode-normal #wrapper .site-notification-spot .component-content h3 {
      display: none; }
    body.mode-normal #wrapper .site-notification-spot .component-content img {
      width: 100%; }

::selection {
  background: #edb833; }

.component.rich-content-spot .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.rich-content-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.rich-content-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.rich-content-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.rich-content-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

/*# sourceMappingURL=main.[hash].css.map */